@import '../../../../variables.scss';

.tableWrapper {
  p,
  td {
    font-family: $primary-text;
    font-size: $standard-font-size;
    padding-left: 5px;
  }
  table,
  th,
  td {
    border: 1px solid black;
    border-bottom: none;
    border-collapse: collapse;
  }
  table {
    width: 100%;
    empty-cells: show;
  }
  .manuName {
    width: 100%;
    color: $primary-text-color;
    text-align: center;
    background-color: $primary-color;
    margin: 0;
    font-family: $primary-text;
    font-weight: 400;
    font-size: $smallheader-font-size;
    padding-left: 0;
    &:before {
      background: white;
      content: '';
      display: block;
      height: 55px;
    }
  }
  .col {
    width: 25%;
  }
}
.notesWrapper {
  border: 1px solid black;
}
.backTop {
  width: 100%;
  text-align: right;
  margin: 0;
  padding: 0;
}
