@import '../../variables.scss';

.mainWindow {
  padding: 0;
  margin: 0;
  padding-top: 55px;
  box-sizing: border-box;
}

.sectionWrapper {
  background-color: transparent;
}
