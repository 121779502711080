@import '../../../../variables.scss';

::placeholder {
  color: $text-grey-color;
  font-style: italic;
}

input[name='part_1']::placeholder,
input[name='part_1_qty']::placeholder,
input[name='part_2']::placeholder,
input[name='part_2_qty']::placeholder,
input[name='part_3']::placeholder,
input[name='part_3_qty']::placeholder,
input[name='part_4']::placeholder,
input[name='part_4_qty']::placeholder,
input[name='part_5']::placeholder,
input[name='part_5_qty']::placeholder {
  opacity: 0;
  @media (max-width: $break-extrasmall) {
    opacity: 0.5;
  }
}

.orderformcontainer {
  padding: 100px 0 100px 0;
  position: relative;
  h1 {
    color: $primary-color;
    font-family: $primary-heading;
    font-size: $header-font-size;
    font-weight: 250;
    letter-spacing: 0.2vw;
    margin: 0 auto;
    padding-bottom: 20px;
    text-align: center;
  }
}

.orderformwrapper {
  background-color: $secondary-color;
  color: $text-grey-color;
  min-height: 200px;
  padding-bottom: 2%;
  font-size: $form-font-size;
  label {
    display: inline-block;
  }
  .columnone {
    padding: 20px 40px 20px 20px;
    display: grid;
    grid-column-start: 1;
    grid-template-columns: 25% 1fr 15% 1fr;
    // grid-template-rows: repeat(18, 1fr);
    align-items: center;
    text-align: right;
    @media (max-width: $break-large) {
      grid-template-rows: auto;
    }
    .col1 {
      grid-column-start: 1;
      text-align: right;
    }
    .col2 {
      grid-column-start: 2;
    }
    .col3 {
      grid-column-start: 3;
      justify-self: right;
    }
    .col4 {
      grid-column-start: 4;
    }
    .long {
      grid-column: span 3;
    }
    .bottompara {
      grid-column: span 4;
    }
    label {
      margin-right: 16px;
    }
    @media (max-width: $break-extrasmall) {
      .selectorlong {
        margin: 0 0 15px 0;
      }
      display: block;
      text-align: left;
      padding: 20px;
    }
  }
  .columntwo {
    display: grid;
    height: 50%;
    // grid-template-rows: repeat(18, 1fr);
    grid-column-start: 2;
    padding: 20px 20px 20px 40px;
    text-align: center;
    .partform {
      display: grid;
      grid-template-columns: 40% 100px 1fr;
      grid-row: 1 / span 11;
      grid-gap: 8px;
      input[type='text'] {
        @media (max-width: $break-extrasmall) {
          width: 48%;
          margin-right: 10px;
        }
      }
      input[type='number'] {
        @media (max-width: $break-extrasmall) {
          width: 48%;
        }
      }
      input[type='date'] {
        text-align: center;
        border: 1px solid #9b9b9b;
        @media (max-width: $break-extrasmall) {
          border: 1px solid #9b9b9b;
          width: 100%;
          box-sizing: border-box;
          text-align: left;
          margin-bottom: 30px;
        }
      }
      @media (max-width: $break-extrasmall) {
        display: block;
        text-align: left;
        label {
          display: none;
        }
      }
    }
    .commentfield {
      // grid-column-start: 1;
      // grid-column-end: end;
      // grid-row: span 4;
      height: 200px;
      font-size: $form-font-size;
      border: 1px solid #9b9b9b;
    }
    @media (max-width: $break-large) {
      grid-column-start: 1;
    }
    @media (max-width: $break-extrasmall) {
      padding: 0 20px 20px 20px;
    }
  }
}

.orderform {
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media (max-width: $break-large) {
    grid-template-columns: 1fr;
  }
  width: 80%;
  min-width: 300px;
  margin: 0 auto;
  button,
  input {
    margin: 10px 0;
  }

  input[type='text'],
  input[type='email'],
  input[type='tel'],
  input[type='number'] {
    background: none;
    color: $text-grey-color;
    border: 1px solid #9b9b9b;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 0;
    border-radius: 0;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
    font-size: $form-font-size;
    min-height: 27px;
    padding: 8px;
    text-align: left;
  }
  input[type='date'] {
    padding: 8px;
  }

  .short {
    grid-column-start: 2;
    grid-column-end: 3;
  }

  .long {
    grid-column-start: 2;
    grid-column-end: end;
  }
  .selectorlong {
    display: flex;
  }
  .buttondiv {
  }

  input[type='submit'] {
    margin: 10px;
    display: inline-block;
    background: #d8d8d8;
    color: $primary-color;
    width: 40%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    font-size: $standard-font-size;
    font-weight: 800;
    text-transform: uppercase;
    min-height: 27px;
    padding: 12px 25px 12px 25px;
    text-align: center;
    cursor: pointer;
    border: 0;
  }
}

.clearbutton {
  margin: 10px;
  display: inline-block;
  background: #d8d8d8;
  color: $primary-color;
  width: 40%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-size: $standard-font-size;
  font-weight: 800;
  text-transform: uppercase;
  min-height: 27px;
  padding: 12px 25px 12px 25px;
  text-align: center;
  cursor: pointer;
  border: 0;
}

.errorfield {
  background: purple !important;
}

.validfield {
}

.formmodal {
  display: none;
  position: absolute;
  width: 90%;
  height: 100%;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 12px;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.8);
  h3 {
    position: absolute;
    text-align: center;
    width: 100%;
    top: 20%;
    color: $green-color;
    font-size: $smallheader-font-size;
  }
}

.show {
  display: inline !important;
}
