@import '../../../../variables.scss';

.countWrapper {
  min-height: 35vh;
  display: grid;
  grid-template-columns: 25% 1fr;
  align-items: center;
  justify-items: center;
  color: $primary-text-color;
  background-color: $primary-color;

  @media (max-width: $break-small) {
    display: block;
  }
}

.leftImage {
  display: grid;
  align-items: center;
  justify-items: center;
  height: 100%;
  h1 {
    font-family: $primary-heading;
    font-size: $header-font-size;
    font-weight: 250;
    letter-spacing: 0.2vw;
    margin: 0;
    margin-left: 20px;
  }

  @media (max-width: $break-small) {
    height: 10vh;
  }
}

.rightList {
  width: 80%;
  margin: 0 auto;
  ul {
    align-self: top;
    justify-self: center;
    columns: 2;

    @media (max-width: $break-small) {
      columns: 2;
      padding: 10%;
      margin: 0;
    }
  }
  li {
    font-family: $primary-text;
    font-weight: 400;
    font-size: $standard-font-size;
    list-style: none;
    position: relative;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding-right: 20px;

    &:before {
      position: absolute;
      top: 0em;
      left: -20px;
      color: $green-color;
      content: '\2022';
      font-size: $standard-font-size;
      padding-right: 0.5em;
    }

    a {
      color: white;
      &:visited {
        color: white;
      }
      &:hover {
        color: $green-color;
      }
    }

    @media (max-width: $break-small) {
      float: none;
      width: 100%;
    }
  }

  @media (max-width: $break-small) {
    width: 90%;
  }
}
