@import '../../../../../variables.scss';

.toolMenu {
  background-color: $primary-color;
  display: grid;
  justify-items: center;

  ul {
    height: 15vh;
    width: 100%;
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-items: center;
    align-items: center;
    justify-content: space-evenly;
    li {
      box-sizing: border-box;
      font-family: $primary-text;
      font-size: $standard-font-size;
      font-weight: bold;
      text-align: center;
      padding: 0 1%;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      width: fit-content;
      a {
        text-decoration: none;
        color: $primary-text-color;
        &:hover {
          color: $green-color;
          transition: ease 0s;
        }
      }
      @media screen and (max-width: $break-large) {
        width: 33%;
        display: inline-block;
        vertical-align: top;
        padding: 1%;
      }
      @media screen and (max-width: $break-small) {
        width: 50%;
        padding: 2%;
      }
    }
    @media screen and (max-width: $break-large) {
      display: block;
      height: auto;
      padding: 5%;
    }
  }
}
