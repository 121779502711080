@import '../../../variables.scss';

.headCell {
  padding: 5px;
  border-style: solid;
  border-width: 5px;
  border-color: rgba(255, 255, 255, 0);
  transition: 500ms ease-out;
  &:hover {
    border-style: solid;
    border-color: $iechi-green;
    transition: 500ms ease-in;
    cursor: pointer;
    .pTag {
      border-bottom: 1px solid rgba(255, 255, 255, 0);
    }
  }
  display: grid;
  align-items: center;
  justify-items: left;
  @media (max-width: $break-small) {
    align-items: start;
  }
  img {
    width: 95%;
    justify-self: center;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
  .pTag {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    width: 100%;
    p {
      font-family: $primary-text;
      font-size: 16px;
      text-align: left;
      font-weight: 400;
      width: 70%;
      margin-top: 4px;
      margin-bottom: 9px;
      padding-left: 5px;
      color: $primary-color;
    }
    @media (max-width: $break-small) {
      align-self: end;
    }
  }
}
