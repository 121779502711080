@import '../../../../../variables.scss';

.collapseData {
  padding: 0;
  height: fit-content;
  display: grid;
  grid-template-rows: 35px 1fr;
  align-items: center;
  justify-items: center;
  border-top: 1px solid grey;
  &:first-of-type {
    margin-top: 0;
  }
  h3 {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    &:hover {
      color: white;
      background-color: $iechi-green;
    }
  }
}

.dataDiv {
  width: 90%;
  display: grid;
  justify-items: center;
  padding: 15px;
  table,
  ul {
    width: 90%;
    margin: 20px;
  }
  p,
  ul li,
  td {
    font-family: $primary-text;
    font-weight: 400;
    font-size: $standard-font-size;
    margin: 0;
    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }
  th {
    font-family: $primary-text;
    font-weight: bold;
    font-size: $standard-font-size;
    padding: 0;
    margin: 0;
    text-align: left;
  }
  .contentImages {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    height: fit-content;
    width: 100%;
    margin-top: 20px;
    align-items: center;
    justify-items: center;
    img {
      width: 150px;
    }
    p {
      text-align: center;
    }
  }
}

.arrowOpen {
  &:after {
    content: '\25b2';
    font-size: 12px;
    padding-left: 20px;
  }
}

.arrowClose {
  &:after {
    content: '\25bc';
    font-size: 12px;
    padding-left: 20px;
  }
}

.locMap {
  width: 300px;
}
