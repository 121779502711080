@import '../../../variables.scss';

.sectionCard {
  display: grid;
  grid-template-columns: 38% 62%;
  background-color: transparent;
  min-height: 35vh;

  @media (max-width: $break-small) {
    display: block;
  }
}

.leftField {
  display: grid;
  background-color: rgba(0, 0, 0, 0.35);
  color: white;
  grid-column-start: 1;
  grid-column-end: 2;
  align-content: center;
  justify-content: left;
  padding-left: 40px;
  padding-right: 40px;

  h1 {
    font-family: $primary-heading;
    font-size: $header-font-size;
    font-weight: 250;
    letter-spacing: 0.2vw;
    width: 90%;

    @media (max-width: $break-small) {
      font-size: $header-font-size;
    }
  }
  p {
    font-family: $secondary-text;
    font-weight: 400;
    width: 80%;
    font-size: $standard-font-size;
  }

  @media (max-width: $break-small) {
    display: block;
    padding-top: 5%;
    padding-bottom: 5%;
  }
}

.rightField {
  grid-column-start: 2;
  grid-column-end: 3;
  display: flex;
  background-color: whitesmoke;
}
