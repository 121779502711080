// Colors:

$primary-color: #112047;
$secondary-color: #ffffff;

$primary-text-color: #ffffff;

$grey-color: #9b9b9b;
$text-grey-color: #4a4a4a;
$green-color: rgba(126, 211, 33, 1);
$iechi-green: #349800;

// Fonts

@import url('https://fonts.googleapis.com/css?family=Alfa+Slab+One');
@import url('https://use.typekit.net/xrx4egm.css');

$primary-heading: 'Alfa Slab One', cursive;
$primary-text: futura-pt, sans-serif;
$secondary-text: futura-pt, sans-serif;

$standard-font-size: calc(16px + 0.3vw);
$form-font-size: calc(12px + 0.2vw);
$header-font-size: calc(16px + 2vw);
$smallheader-font-size: calc(16px + 1vw);

// Styles

$primary-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);

// Responsive

$break-extrasmall: 667px; // @media screen and (max-width: $break-extrasmall) {}
$break-small: 768px; // @media screen and (max-width: $break-small) {}
$break-large: 1200px; // @media screen and (max-width: $break-large) {
$break-extralarge: 1600px; // @media screen and (max-width: $break-extralarge) {
$break-ultralarge: 2000px;
