@import '../../../../../variables.scss';

.selectionButton {
  width: 80%;
  margin: 0.5vw auto;
  height: 15em;
  color: $primary-text-color;
  display: grid;
  grid-template-columns: 20% 25% 1fr;
  align-items: center;
  justify-items: center;
  box-shadow: none;
  transition: 0.75s ease;
  background-size: cover;

  &:hover {
    box-shadow: inset 0 0 0 1000px rgba(126, 211, 33, 0.8);
    transition: 0.75s ease;
    cursor: pointer;
  }
  img {
    max-width: 10vw;
  }
  h1 {
    font-family: $primary-text;
    font-weight: bold;
    font-size: $smallheader-font-size;
    text-align: right;
    width: 100%;
    @media (max-width: $break-small) {
      justify-self: left;
      text-align: center;
    }
  }

  p {
    font-family: $secondary-text;
    font-size: $smallheader-font-size;
    text-align: left;
    padding: 0 5vw;
    justify-self: left;

    @media (max-width: $break-small) {
      display: none;
    }
  }

  @media (max-width: $break-large) {
    grid-template-columns: 10% 35% 1fr;
  }

  @media (max-width: $break-small) {
    width: 90%;
    height: 80px;
    grid-template-columns: 25% 1fr;
  }
}

.buttonLogo {
  padding-left: 20px;

  @media (max-width: $break-small) {
    padding-left: 0;
  }
}
