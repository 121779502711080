@import '../../variables.scss';

@keyframes elevatorLeft {
  to {
    transform: translateX(-54%);
  }
}

@keyframes elevatorLeftMobile {
  to {
    transform: translateX(-75%);
  }
}

@keyframes elevatorRight {
  to {
    transform: translateX(54%);
  }
}
@keyframes elevatorRightMobile {
  to {
    transform: translateX(75%);
  }
}

@keyframes textZoom {
  to {
  }
}

.selected {
  color: $green-color;
  letter-spacing: 1px;
}

.mainWindow {
  ul li {
    font-family: $primary-text;
    font-weight: 400;
    color: $text-grey-color;
    font-size: $standard-font-size;
    padding: 3px 0px;
  }
}
.fullElevator {
  margin-top: 110px;
  width: 100%;
  height: 150px;
  overflow: hidden;
  background-color: white;

  .menuWrapper {
    width: 100%;
    height: 100%;
  }

  @media (max-width: $break-small) {
    height: 8vh;
    padding-bottom: 30px;
  }
}
.leftDoor,
.rightDoor {
  width: 50%;
  height: 8vh;
  float: left;
  position: relative;
  z-index: 2;
}

.leftDoor {
  background-color: white;
  animation-name: elevatorLeft;
  animation-duration: 3s;
  animation-timing-function: ease;
  animation-delay: 1.5s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-play-state: running;
  &:after {
    content: ' ';
    background-color: transparent;
    width: 5vw;
    height: 98%;
    border-top: 1px solid black;
    border-left: 1px solid black;
    border-bottom: 1px solid black;
    z-index: 4;
    position: absolute;
    right: 0;
    top: 0;
  }

  @media (max-width: $break-small) {
    animation-name: elevatorLeftMobile;
  }
}
.rightDoor {
  background-color: white;
  animation-name: elevatorRight;
  animation-duration: 3s;
  animation-timing-function: ease;
  animation-delay: 1.5s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-play-state: running;
  &:before {
    content: ' ';
    background-color: transparent;
    width: 5vw;
    height: 98%;
    border-top: 1px solid black;
    border-right: 1px solid black;
    border-bottom: 1px solid black;
    z-index: 4;
    position: absolute;
    left: 0;
    top: 0;
  }

  @media (max-width: $break-small) {
    animation-name: elevatorRightMobile;
  }
}

.menuBar {
  position: absolute;
  z-index: 1;
  width: 60%;
  height: 100%;
  left: 50%;
  transform: translateX(-50%);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-rows: 8vh;
  align-items: center;
  justify-items: center;
  animation-name: textZoom;
  animation-duration: 2s;
  animation-timing-function: ease;
  animation-delay: 5.5s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-play-state: running;
  .menuItem {
    font-family: $primary-heading;
    color: $primary-color;
    font-size: $smallheader-font-size;
    transition: ease 0.25s;
    &:hover {
      transition: ease 0.25s;
      color: $green-color;
      letter-spacing: 1px;
    }

    @media (max-width: $break-small) {
      font-size: 0.9em;
    }
  }

  @media (max-width: $break-small) {
    width: 80%;
  }
}
