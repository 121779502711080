@import '../../../../variables.scss';
@import '../section.module.scss';

.phoneContainer {
  background: transparent;
  position: relative;

  width: 100%;
  padding-top: 200px;
  padding-bottom: 200px;
  overflow-x: hidden;
  @media (max-width: $break-small) {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.phoneDetail {
  width: 100%;
  padding-left: 10%;
  margin: 0 auto;
  position: relative;
  display: grid;
  grid-template-columns: 70% 30%;
  grid-template-rows: 200px fit-content;

  @media (max-width: $break-small) {
    padding-left: 0;
  }

  .phoneTopper {
    width: 100%;
    grid-column: 1 / 2;
    @media (max-width: $break-small) {
      grid-column: 1 / span 2;
    }
  }
  .phoneText {
    grid-column-start: 1;
    grid-row-start: 2;
    padding-top: 50px;
    margin-left: -50px;
    @media (max-width: $break-small) {
      grid-column: 1 / span 2;
      margin-left: 0;
    }
  }
  p {
    grid-column-start: 1;
    grid-row-start: 1;
    width: 80%;
    margin: 10px auto 20px 5vw;
    font-family: $secondary-text;
    font-weight: 400;
    font-size: $smallheader-font-size;
    text-align: center;
    @media (max-width: $break-small) {
      margin: 0 auto 20px auto;
    }

    // @media (max-width: $break-large) {
    //   margin: 10px auto 20px 2vw;
    // }
  }

  // @media (max-width: $break-small) {
  //   width: auto;
  //   height: auto;
  //   padding: 5%;
  // }
}

.phoneImage {
  grid-column-start: 2;
  grid-row: 1 / span 2;
  height: 110%;
  z-index: 10;
  position: relative;
  top: -5%;
  left: -25px;

  @media (max-width: $break-small) {
    display: none;
  }
}

.socialIcons {
  width: 80%;
  justify-self: start;
  display: grid;
  grid-template-columns: 70px 1fr 1fr;
  .social1 {
    width: 50px;
  }
  a {
    display: grid;
    img {
      width: 90%;
    }
  }
  // width: 80%;
  // display: grid;
  // grid-template-columns: 33% 33% 33%;
  // align-items: center;
  // justify-items: center;
  // img {
  //   width: 90%;
  //   &:first-of-type {
  //     width: 30%;
  //   }
  //   &:not(:first-of-type) {
  //     margin-left: -4vw;
  //   }
  // }
}
