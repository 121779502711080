@import '../../../../variables.scss';

.contentWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 80%;
  margin: 25px auto 100px auto;
  align-content: center;
  * {
    margin: 0 10px;

    @media (max-width: $break-small) {
      margin: 0;
    }
  }
  p {
    font-family: $primary-text;
    font-weight: 400;
    font-size: $standard-font-size;
    margin-top: 25px;
  }
  img {
    justify-self: middle;
    align-self: middle;
    max-width: 100%;
    height: auto;
    display: block;

    @media (max-width: $break-small) {
      margin: auto;
    }
  }
  h1 {
    font-size: $smallheader-font-size;
    margin-top: 35px;
    margin-bottom: 25px;
    font-family: $primary-text;
    font-weight: bold;
    color: $primary-color;
  }

  @media (max-width: $break-small) {
    display: block;
    width: 85%;
    margin: 0 auto 10% auto;
  }
}
