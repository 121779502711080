@import '../../../../../variables.scss';

.cardWrapper {
  width: 100%;
  height: 30vh;
  display: grid;
  align-items: center;
  justify-items: center;
  border: 1px solid white;
  &:hover {
    box-shadow: inset 0 0 0 1000px rgba(126, 211, 33, 0.8);
    transition: 0.75s ease;
    cursor: pointer;
  }
  p {
    font-family: $primary-text;
    width: 75%;
    color: $primary-text-color;
    text-align: center;
    font-size: calc(16px + 1.5vh);
    font-weight: bold;
    line-height: 95%;
  }

  @media (max-width: $break-small) {
    height: 15vh;
  }
}
