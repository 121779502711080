@import '../../../../../variables.scss';

.scrollerBar {
  margin: 100px 0 0 0;
  position: relative;
  .button {
    position: absolute;
    background: transparent;
    z-index: 20;
    color: $primary-text-color;
    height: 100%;
    width: 10%;
    border: none;
    font-size: $standard-font-size;

    transform: translateY(-50%);
    &:first-child {
      top: 50%;
      left: 0;
      img {
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translatey(-50%);
        @media (max-width: $break-small) {
          display: none;
        }
      }
    }
    &:last-child {
      top: 50%;
      right: 0;
      img {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translatey(-50%);
        @media (max-width: $break-small) {
          display: none;
        }
      }
    }

    @media (max-width: $break-small) {
      background-color: rgba(255, 255, 255, 0.3);
      display: flex;
      justify-content: center;
      align-items: center;
      width: 6%;

      &:after {
        text-align: center;
        color: #fff;
        display: block;
        font-size: 1.5em;
      }

      &:first-child:after {
        content: '<';
      }
      &:last-child:after {
        content: '>';
      }
    }
  }
  @media (max-width: $break-small) {
    margin: 10% 0;
  }
}
