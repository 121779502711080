@import '../../variables.scss';

.pageHeader {
  background-color: $primary-color;
  color: $primary-text-color;
  position: fixed;
  top: 0;
  left: 0;
  height: 55px;
  width: 100%;
  display: grid;
  grid-template-columns: 40% 1fr;
  align-items: center;
  z-index: 499;
  .contactLink {
    text-align: center;
    font-size: $standard-font-size;
    font-family: $primary-text;
    color: $primary-text-color;
    font-weight: bold;
    padding-left: 20px;
    @media (max-width: $break-small) {
      display: none;
      grid-row-start: 1;
    }
  }
  .orderLink {
    text-align: center;
    font-size: $standard-font-size;
    font-family: $primary-text;
    color: $primary-text-color;
    font-weight: bold;
    padding-left: 20px;
    @media (max-width: $break-small) {
      font-size: 14px;
      text-align: left;
      padding-top: 4px;
      grid-row-start: 1;
    }
    @media (max-width: $break-extrasmall) {
      font-size: 12px;
      grid-row-start: 1;
    }
  }
  .logo {
    max-width: 100%;
    height: 55px;
    padding-left: 23px;
    @media (max-width: $break-small) {
      height: auto;
      max-height: 55px;
      padding-left: 10px;
    }
  }
  h1 {
    text-align: center;
    margin: 0;
    font-size: $standard-font-size;
    font-family: $primary-text;
  }
  .phoneLink {
    text-decoration: none;
    padding-left: 20px;
    color: $secondary-color;
    font-size: $standard-font-size;
    font-family: $primary-text;
    transition: ease 0.55s;
    @media (max-width: $break-extrasmall) {
      font-size: 14px;
    }
    &:hover {
      color: $green-color;
      transition: ease 0.55s;
    }
  }
  padding: 0;
  margin: 0;
}

.contactBlock {
  padding-right: 10px;
  @media (max-width: $break-small) {
    display: grid;
    height: 55px;
    grid-template-columns: 75% 25%;
    grid-template-rows: 50% 50%;
    align-items: center;
    justify-items: center;
    h1 {
      grid-column-start: 1;
      grid-row-start: 1;
      width: 80%;
    }
    a {
      grid-column-start: 1;
      grid-row-start: 2;
      width: 80%;
    }
  }

  justify-self: right;
  > * {
    display: inline-block;
    vertical-align: middle;
  }
}
