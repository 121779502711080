@import '../../../variables.scss';

.screenOverlay {
  z-index: 98;
  position: absolute;
  top: 55px;
  left: 0;
  height: 200vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.65);
}

.modalWrapper {
  position: absolute;
  background-color: white;
  left: 0;
  width: 100%;
  height: 300vh;
  z-index: 99;
  @media (max-width: $break-small) {
    position: fixed;
    width: 100%;
  }
}

.menuContainer {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin: 10vh auto;
  align-items: center;
  a,
  p {
    margin: 1vh 0px;
    text-decoration: none;
    font-family: $primary-heading;
    font-size: $header-font-size;
    color: $primary-color;
    transition: 0.25s ease;
    &:hover {
      transition: 0.25s ease;
      color: $green-color;
      letter-spacing: 1px;
    }
    @media (max-width: $break-small) {
      font-size: $smallheader-font-size;
      text-align: center;
      padding: 20px 0;
      margin: 0;
    }
  }
  @media (max-width: $break-small) {
    width: 100%;
  }
}
