@import '../../../../variables.scss';
@import '../section.module.scss';

.sealsContainer {
  display: grid;
  position: relative;
  width: 100%;
  color: white;
  height: 40vh;
  padding-top: 100px;
  padding-bottom: 100px;

  @media (max-width: $break-small) {
    padding-top: 0;
    padding-bottom: 0;
    margin-top: -10%;
  }

  align-content: center;
  p {
    width: 80%;
    margin: 0 auto;
    font-family: $secondary-text;
    font-weight: bold;
    font-size: $header-font-size;
    text-align: center;
  }

  .sectionCard {
    min-height: 30vh;
  }
}
