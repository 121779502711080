@import '../../../variables.scss';
.filterSection {
  border-style: solid;
  border-color: $grey-color;
  border-width: 0px 1px;
  h1 {
    margin: 0;
    margin-left: 25px;
    padding: 10px;
    font-family: $primary-text;
    font-size: 16px;
    text-align: left;
  }
  label {
    font-family: $primary-text;
    font-size: 1em;
    margin-left: 25px;
  }
  &:not(:last-child):after {
    content: '';
    display: block;
    height: 1px;
    width: 82%;
    margin: 0 auto;
    border-bottom: 1px solid $grey-color;
  }
}

.filterBoxes {
  height: 0;
  // transition: all ease-out 0.5s;
  overflow: hidden;
  transform: scaleY(0);
  display: grid;
  grid-template-rows: repeat(3, 50px);
  grid-auto-flow: column;
  align-items: center;
}

.open {
  padding: 10px;
  height: fit-content;
  // transition: all ease-in 0.5s;
  transform-origin: top;
  transform: scaleY(1);
}

.arrowOpen {
  &:after {
    content: '\25b2';
    font-size: 12px;
    padding-left: 20px;
  }
}

.arrowClose {
  &:after {
    content: '\25bc';
    font-size: 12px;
    padding-left: 20px;
  }
}
