@import '../../../variables.scss';

.tmWrapper {
  margin-top: 55px;
  padding: 50px;
  font-family: $primary-text;

  @media (max-width: $break-small) {
    padding: 10%;
  }
}

.selectionList {
  a {
    display: block;
    text-decoration: none;
    color: black;
    padding: 5px;
    font-size: $standard-font-size;
    &:hover {
      color: $green-color;
      transition: ease 0s;
    }
  }
}

.topWrapper {
  grid-column-start: 2;
  display: grid;
  grid-template-columns: 50% 50%;
  padding: 0 25px;

  @media (max-width: $break-small) {
    display: block;
    padding: 0;
  }
}

.tablesWrapper {
  grid-column-start: 2;
  margin-top: 55px;

  @media (max-width: $break-small) {
    margin-top: 0;
  }
}

.linkList {
  grid-column: 1;
  margin: 10px 10px 0 10px;
  a {
    display: block;
    text-decoration: none;
    color: black;
    padding: 5px 0;
    font-size: $standard-font-size;
    border-bottom: 1px solid black;
    &:first-of-type {
      border-top: 1px solid black;
    }
    &:hover {
      color: $green-color;
      transition: ease 0s;
    }
  }
}

.descriptionImages {
  height: 85%;
  width: 90%;
  align-items: center;
  justify-items: center;
  .item {
    margin-bottom: 20px;
    display: grid;
    grid-template-columns: 25% 1fr;
    img {
      max-width: 100%;
      height: auto;
      margin-right: 5%;

      @media (max-width: $break-small) {
        margin-right: 0;
      }
    }
    .col {
      padding-left: 5%;

      @media (max-width: $break-small) {
        padding-left: 0;
      }
    }

    @media (max-width: $break-small) {
      display: block;
    }
  }
  p {
    font-size: $standard-font-size;
    justify-self: right;
    padding-right: 10px;
  }
  img {
    width: 100%;
  }

  @media (max-width: $break-small) {
    width: auto;
  }
}
