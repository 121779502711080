@import '../../../variables.scss';

.imageWrapper {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  .innerWrapper {
    position: relative;
    background-color: white;
    max-width: 80vw;
    max-height: 80vh;
    overflow: hidden;
    padding: 20px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    .modalSelect {
      display: flex;
      padding-top: 20px;
      img {
        max-width: 200px;
        min-height: auto;
        height: auto;
        display: block;
        filter: none;
        &:hover {
          opacity: 0.8;
        }
      }
      div {
        padding-right: 20px;
        &:last-child {
          padding-right: 0;
        }
      }
    }
    .modalImg {
      height: auto;
      max-height: 50vh;
      width: auto;
      max-width: 100%;
      margin: auto;
      display: flex;
      justify-self: center;
      align-self: center;
      filter: none;
    }
    .selectImage {
      z-index: 500;
    }
  }
}

.backButton {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 25px;
  height: 25px;
  color: $primary-color;
  background: #fff;
  img {
    width: 100%;
    height: auto !important;
    min-height: auto !important;
  }

  @media screen and (max-width: $break-small) {
    display: none;
  }
}
