@import '../../../../../variables.scss';

.postWrapper {
  display: grid;
  width: 80%;
  margin-top: 25px;
  margin-left: 10%;
  border-bottom: 3px solid $green-color;
  margin-bottom: 35px;

  h1 {
    font-family: $primary-heading;
    font-weight: 400;
    color: $primary-color;
    font-size: $smallheader-font-size;
    letter-spacing: 0.05em;
  }
  p {
    font-family: $primary-text;
    color: $text-grey-color;
    font-size: $standard-font-size;
  }
  span {
    font-weight: bold;
  }
  a {
    text-decoration: none;
    color: $text-grey-color;
    font-family: $primary-text;
    font-size: $standard-font-size;
    font-weight: bold;
    transition: 0.1s ease;
    &:hover {
      color: $green-color;
      transition: 0.1s ease;
    }
  }
  &:last-child {
    border-bottom: 0;
  }

  @media (max-width: $break-small) {
    width: 85%;
    margin-top: 0;
    margin-left: auto;
    margin-right: auto;
  }
}

.postDate {
  font-family: $primary-text;
  font-size: $standard-font-size;
  font-weight: 400;
  justify-self: right;
  p:nth-of-type(2) {
    color: $primary-color;
    margin-top: -16px;
  }
}
.newsInnerDiv {
  display: grid;

  h1 {
    width: 90%;
    margin-top: 0;

    @media (max-width: $break-small) {
      width: 100%;
      margin-top: 25px;
    }
  }
  p {
    margin-top: 25px;
  }
  ul li {
    font-family: $primary-text;
    font-size: $standard-font-size;
    font-weight: 400;
    color: $text-grey-color;
  }
  img {
    max-width: 100%;
    height: auto;
    display: block;

    @media (max-width: $break-small) {
      margin: auto;
    }
  }

  div:first-of-type {
    grid-column: 1 / span 2;
    margin-top: 30px;
  }

  &.imageRight {
    grid-template-columns: 1fr 40%;
    img {
      grid-column: 2 / end;
      justify-self: right;
    }
    h1 {
      grid-column: 1 / 2;
      grid-row: 1;
    }

    @media (max-width: $break-small) {
      display: block;
    }
  }

  &.imageLeft {
    grid-template-columns: 40% 1fr;
    img {
      grid-column: 1 / 2;
    }
    h1 {
      justify-self: right;
    }

    @media (max-width: $break-small) {
      display: block;
    }
  }
}
