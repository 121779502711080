@import '../../variables.scss';

.contactWrapper {
  display: grid;
  grid-template-columns: 45vw 55vw;
  height: 100vh;
  padding-top: 55px;
  h1 {
    color: $primary-text-color;
    font-family: $primary-heading;
    font-size: $header-font-size;
    font-weight: 250;
    letter-spacing: 0.05em;
    margin-left: 100px;
    margin-top: 125px;
  }
  @media (max-width: $break-small) {
    display: block;
    position: relative;
    height: auto;
    margin: 0;
  }
}

.textBlock {
  display: grid;
  margin-top: 50px;
  grid-template-columns: 100px 1fr;
  align-items: center;
  grid-row-start: 2;
  @media (max-width: $break-small) {
    margin-top: 10%;
  }
}

.rightSide {
  position: relative;
  display: flex;
  @media (max-width: $break-small) {
    width: 100%;
  }
}
.leftSide {
  @media (max-width: $break-small) {
    display: none;
  }
}

.rightSection {
  width: 80%;
  margin: auto;
  @media (max-width: $break-small) {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
    margin: 50px auto;
    width: 85%;
  }
  img {
    width: 50%;
    max-width: 100%;
    min-width: 100%;
    justify-self: center;
    align-self: center;
    margin-top: 10px;
  }
  div:first-child p {
    font-weight: bold;
  }
  p,
  a {
    font-family: $primary-text;
    font-weight: 250;
    font-size: $standard-font-size;
    padding: 2px 25px;
    margin: 0;
    color: #4a4a4a;
  }
  a {
    text-decoration: none;
  }
  button {
    background-color: #509500;
    box-shadow: 3px 3px 6px #509500;
    font-family: $primary-heading;
    font-size: $standard-font-size;
    color: $primary-text-color;
    letter-spacing: 0.1vw;
    font-weight: 250;
    margin: 10px 0px 10px 25px;
    width: 40%;
    min-width: fit-content;
    text-align: left;
    outline: none;
    border: 0;

    @media (max-width: $break-small) {
      width: 90%;
      margin: 0 auto;
    }
    span {
      width: 100%;
      text-align: center;
      display: inline-block;
    }
  }
}

.innerInfo {
  a {
    display: block;
    width: fit-content;
  }
}
