@import '../../../variables.scss';

.titleName {
  grid-column: 1 / span 2;
  justify-self: center;
  border-bottom: 1px solid grey;
  font-family: $primary-text;
  font-weight: bold;
  font-size: $smallheader-font-size;
}

.filterBack {
  grid-column: 1 / span 2;
  justify-self: left;
  background-color: $iechi-green;
  margin-left: 20px;
  color: white;
  font-family: $primary-text;
  font-size: $standard-font-size;
  font-weight: bold;
  width: 10%;
  min-width: 150px;
  text-align: center;
}

.headImageWrapper {
  grid-column-start: 1;
  margin: 20px;
  @media (max-width: $break-small) {
    grid-column: 1 / span 2;
    width: 80%;
    overflow-x: hidden;
    justify-self: center;
  }
  .images {
    display: grid;
    grid-template-rows: 45px 1fr fit-content;
    align-items: center;
    justify-items: center;
    border: 1px solid grey;
    .heroImage {
      width: 70%;
      margin: 20px;
    }
    h3 {
      margin: 0;
      height: 100%;
      text-align: center;
      width: 100%;
      background-color: $iechi-green;
      color: white;
      font-family: $primary-text;
      font-size: $smallheader-font-size;
    }
    .caption {
      height: 30px;
      p {
        font-family: $primary-text;
        font-weight: 400;
        font-size: $standard-font-size;
        padding: 0;
        margin: 0;
      }
    }
    .otherImages {
      margin: 20px;
      display: grid;
      width: 90%;
      grid-template-columns: repeat(4, 1fr);
      grid-column-gap: 20px;
      grid-row-gap: 20px;
      .smallImg {
        width: 80%;
        border: 1px solid grey;
        cursor: pointer;
        &:hover {
          border: 3px solid $iechi-green;
        }
      }
    }
  }
}
.headDataWrapper {
  grid-column-start: 2;
  margin: 20px;
  display: grid;
  grid-auto-rows: minmax(min-content, max-content);
  width: 95%;
  @media (max-width: $break-small) {
    grid-column: 1 / span 2;
    width: 95%;
    overflow-x: hidden;
    justify-self: center;
  }
  h1 {
    display: flex;
    justify-content: center;
    font-family: $primary-text;
    font-size: $smallheader-font-size;
    border-bottom: 1px solid grey;
    text-align: center;
    width: 20%;
    justify-self: center;
  }
  .wrapperTitle {
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
    justify-self: center;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: $iechi-green;
    text-align: center;
    padding: 5px 0 5px 0;
  }
}

.unLoggedDataWrapper {
  grid-column: 1 / span end;
  margin: 20px;
  display: grid;
  width: 100%;
  justify-items: center;
  @media (max-width: $break-small) {
    grid-column: 1 / span 2;
    width: 95%;
    overflow-x: hidden;
    justify-self: center;
  }
  h1 {
    display: flex;
    justify-content: center;
    font-family: $primary-text;
    font-size: $smallheader-font-size;
    border-bottom: 1px solid grey;
    text-align: center;
    width: 20%;
    justify-self: center;
  }
  h3,
  p {
    text-align: center;
  }
  .unLogged {
    grid-column: 1 / span end;
    width: 100%;
    h1 {
      width: 100%;
    }
    a {
      color: $iechi-green;
    }
    .filterBack {
      justify-self: left;
    }
    width: 80%;
  }
}

.backButton {
  margin: 5px auto;
  cursor: pointer;
  &::before {
    position: relative;
    /* top: 3pt; Uncomment this to lower the icons as requested in comments*/
    content: '';
    display: inline-block;
    /* By using an em scale, the arrows will size with the font */
    width: 0.4em;
    height: 0.4em;
    border-right: 0.2em solid white;
    border-top: 0.2em solid white;
    transform: rotate(-135deg);
    margin-right: 0.5em;
  }
}
