@import '../../../../variables.scss';
@import '../section.module.scss';

.moldingContainer {
  display: grid;
  background: transparent;
  position: relative;
  width: 100%;
  color: white;
  padding-top: 150px;
  padding-bottom: 150px;
  align-content: center;
  @media (max-width: $break-small) {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  p {
    width: 80%;
    margin: 20px 0 20px 10%;
    font-family: $secondary-text;
    font-weight: 400;
    font-size: $smallheader-font-size;
  }
}
