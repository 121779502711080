@import '../../../../../../variables.scss';

.cardLayout {
  overflow-x: hidden;
  position: relative;
  display: grid;
  align-items: center;
  height: 100%;
  h1 {
    color: $primary-text-color;
    font-family: $primary-heading;
    font-size: $header-font-size;
    font-weight: 250;
    letter-spacing: 0.2vw;
    z-index: 10;
    width: 80%;
    margin: 0;
    padding: 5%;
    word-wrap: break-word;
    @media screen and (max-width: $break-small) {
      position: absolute !important;
      top: 5%;
      right: 0;
    }
  }
  ul,
  ol {
    columns: 1;
    margin: 0 0 20px 0;
  }
  p,
  code,
  ul li,
  ol li {
    font-family: $primary-text;
    color: $primary-text-color;
    text-align: left;
    font-size: $standard-font-size;
    font-weight: 400;
    margin: 0 0 10px 0;
    padding: 0 5% 0 0;
  }
  img {
    filter: brightness(65%);
    height: 100%;
    width: 100%;
    min-height: 33vh;
    object-fit: cover;
    max-width: 33vw;
    justify-self: left;
    @media screen and (max-width: $break-small) {
      height: 20vh;
      max-width: initial;
    }
    @media not all and (min-resolution: 0.001dpcm) {
      // Safari specific
      height: auto;
      min-height: 50vh;
    }
  }
  p img {
    filter: none;

    @media screen and (max-width: $break-small) {
      height: auto;
    }
  }

  @media screen and (max-width: $break-small) {
    display: block;
    position: relative;
  }
}

.image_left {
  grid-template-columns: 1fr 70%;
  justify-items: end;
  img {
    grid-column-start: 1;
    grid-row-start: 1;
  }
  h1 {
    grid-column-start: 1;
    grid-row-start: 1;
    border-top: 3px solid $primary-text-color;
    border-left: 3px solid $primary-text-color;
    position: relative;
    &:before {
      content: '';
      height: 3px;
      width: 40%;
      background: #fff;
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }
}

.image_right {
  grid-template-columns: 70% 1fr;
  justify-items: end;
  img {
    grid-column-start: 2;
    grid-row-start: 1;
  }
  h1 {
    grid-column-start: 2;
    grid-row-start: 1;
    border-left: 3px solid $primary-text-color;
    border-bottom: 3px solid $primary-text-color;
    position: relative;
    &:before {
      content: '';
      height: 3px;
      width: 50%;
      background: #fff;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}

.white {
  background-color: $secondary-color;
  p,
  code,
  ul li {
    color: black;
  }
}

.blue {
  background-color: $primary-color;
  color: $primary-text-color;
  .textField table th {
    color: $primary-text-color;
  }
}

.textField {
  padding: 5%;
  position: relative;
  span {
    color: $green-color;
    font-weight: bold;
  }
  p:last-of-type {
    position: relative;
  }
  table {
    width: 80%;
    margin: 0 auto;
    text-align: center;
    th {
      color: $primary-color;
      font-family: $primary-text;
      font-weight: bold;
      font-size: $standard-font-size;
      border-bottom: 2px solid $green-color;
    }
    th:not(:last-of-type) {
      border-right: 2px solid $green-color;
    }
    tr:not(:last-of-type) td {
      padding: 5px;
      border-bottom: 2px solid $green-color;
    }
    tr td:not(:last-of-type) {
      border-right: 2px solid $green-color;
    }
  }
}

.expandButton {
  position: absolute;
  top: 15px;
  left: 15px;
  width: 25px;
  height: 25px;
  img {
    position: relative;
    top: 2px;
    filter: none;
    width: 100%;
    min-height: auto;
  }
  @media screen and (max-width: $break-small) {
    display: none;
  }
}

.expand_left {
  grid-column: 1;
  grid-row: 1;
}

.expand_right {
  grid-column: 2;
  grid-row: 1;
}
