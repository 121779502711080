@import '../../../variables.scss';

input::placeholder {
  margin-bottom: 5px;
}

.loginWrapper {
  height: 100vh;
  margin-top: 55px;
  .login {
    display: grid;
    grid-template-rows: 30% 1fr;
    padding: 0;
    border: 1px solid $grey-color;
    height: 40%;
    width: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @media (max-width: $break-small) {
      width: 95%;
      height: 80%;
    }
    img {
      position: absolute;
      right: 10px;
      top: 5px;
    }
    form {
      width: 80%;
      margin: 0 auto;
      display: grid;
      grid-template-rows: 15% 15% 5% 1fr;
      input {
        font-size: $standard-font-size;
        font-family: $primary-text;
        width: 80%;
        height: 20px;
        border-width: 0 0 4px 0;
        align-self: end;
        margin-bottom: 20px;
      }
      button {
        height: 50px;
        width: 40%;
        align-self: center;
        justify-self: center;
        background-color: $iechi-green;
        color: white;
        font-family: $primary-text;
        font-size: $standard-font-size;
      }
    }
    h3,
    a {
      font-family: $primary-text;
      font-size: $standard-font-size;
    }
    a {
      color: $primary-color;
    }
  }
  .registerText {
    width: 80%;
    justify-self: center;
    font-size: $standard-font-size;
    font-family: $primary-text;
  }
}

.typeSelect {
  height: 10%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 10px;
  text-align: center;
  border-collapse: collapse;
  padding: 10px;
  div {
    width: 100%;
    height: 100%;
    h2 {
      font-family: $primary-text;
      font-size: 18px;
      color: black;
    }
  }
  .selected {
    h2 {
      color: $iechi-green;
    }
  }
}
