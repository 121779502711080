@import url(https://fonts.googleapis.com/css?family=Alfa+Slab+One);
@import url(https://use.typekit.net/xrx4egm.css);
@import url(https://fonts.googleapis.com/css?family=Alfa+Slab+One);
@import url(https://use.typekit.net/xrx4egm.css);
@import url(https://fonts.googleapis.com/css?family=Alfa+Slab+One);
@import url(https://use.typekit.net/xrx4egm.css);
@import url(https://fonts.googleapis.com/css?family=Alfa+Slab+One);
@import url(https://use.typekit.net/xrx4egm.css);
@import url(https://fonts.googleapis.com/css?family=Alfa+Slab+One);
@import url(https://use.typekit.net/xrx4egm.css);
@import url(https://fonts.googleapis.com/css?family=Alfa+Slab+One);
@import url(https://use.typekit.net/xrx4egm.css);
@import url(https://fonts.googleapis.com/css?family=Alfa+Slab+One);
@import url(https://use.typekit.net/xrx4egm.css);
@import url(https://fonts.googleapis.com/css?family=Alfa+Slab+One);
@import url(https://use.typekit.net/xrx4egm.css);
@import url(https://fonts.googleapis.com/css?family=Alfa+Slab+One);
@import url(https://use.typekit.net/xrx4egm.css);
@import url(https://fonts.googleapis.com/css?family=Alfa+Slab+One);
@import url(https://use.typekit.net/xrx4egm.css);
@import url(https://fonts.googleapis.com/css?family=Alfa+Slab+One);
@import url(https://use.typekit.net/xrx4egm.css);
@import url(https://fonts.googleapis.com/css?family=Alfa+Slab+One);
@import url(https://use.typekit.net/xrx4egm.css);
@import url(https://fonts.googleapis.com/css?family=Alfa+Slab+One);
@import url(https://use.typekit.net/xrx4egm.css);
@import url(https://fonts.googleapis.com/css?family=Alfa+Slab+One);
@import url(https://use.typekit.net/xrx4egm.css);
@import url(https://fonts.googleapis.com/css?family=Alfa+Slab+One);
@import url(https://use.typekit.net/xrx4egm.css);
@import url(https://fonts.googleapis.com/css?family=Alfa+Slab+One);
@import url(https://use.typekit.net/xrx4egm.css);
@import url(https://fonts.googleapis.com/css?family=Alfa+Slab+One);
@import url(https://use.typekit.net/xrx4egm.css);
@import url(https://fonts.googleapis.com/css?family=Alfa+Slab+One);
@import url(https://use.typekit.net/xrx4egm.css);
@import url(https://fonts.googleapis.com/css?family=Alfa+Slab+One);
@import url(https://use.typekit.net/xrx4egm.css);
@import url(https://fonts.googleapis.com/css?family=Alfa+Slab+One);
@import url(https://use.typekit.net/xrx4egm.css);
@import url(https://fonts.googleapis.com/css?family=Alfa+Slab+One);
@import url(https://use.typekit.net/xrx4egm.css);
@import url(https://fonts.googleapis.com/css?family=Alfa+Slab+One);
@import url(https://use.typekit.net/xrx4egm.css);
@import url(https://fonts.googleapis.com/css?family=Alfa+Slab+One);
@import url(https://use.typekit.net/xrx4egm.css);
@import url(https://fonts.googleapis.com/css?family=Alfa+Slab+One);
@import url(https://use.typekit.net/xrx4egm.css);
@import url(https://fonts.googleapis.com/css?family=Alfa+Slab+One);
@import url(https://use.typekit.net/xrx4egm.css);
@import url(https://fonts.googleapis.com/css?family=Alfa+Slab+One);
@import url(https://use.typekit.net/xrx4egm.css);
@import url(https://fonts.googleapis.com/css?family=Alfa+Slab+One);
@import url(https://use.typekit.net/xrx4egm.css);
@import url(https://fonts.googleapis.com/css?family=Alfa+Slab+One);
@import url(https://use.typekit.net/xrx4egm.css);
@import url(https://fonts.googleapis.com/css?family=Alfa+Slab+One);
@import url(https://use.typekit.net/xrx4egm.css);
@import url(https://fonts.googleapis.com/css?family=Alfa+Slab+One);
@import url(https://use.typekit.net/xrx4egm.css);
@import url(https://fonts.googleapis.com/css?family=Alfa+Slab+One);
@import url(https://use.typekit.net/xrx4egm.css);
@import url(https://fonts.googleapis.com/css?family=Alfa+Slab+One);
@import url(https://use.typekit.net/xrx4egm.css);
@import url(https://fonts.googleapis.com/css?family=Alfa+Slab+One);
@import url(https://use.typekit.net/xrx4egm.css);
@import url(https://fonts.googleapis.com/css?family=Alfa+Slab+One);
@import url(https://use.typekit.net/xrx4egm.css);
@import url(https://fonts.googleapis.com/css?family=Alfa+Slab+One);
@import url(https://use.typekit.net/xrx4egm.css);
@import url(https://fonts.googleapis.com/css?family=Alfa+Slab+One);
@import url(https://use.typekit.net/xrx4egm.css);
@import url(https://fonts.googleapis.com/css?family=Alfa+Slab+One);
@import url(https://use.typekit.net/xrx4egm.css);
@import url(https://fonts.googleapis.com/css?family=Alfa+Slab+One);
@import url(https://use.typekit.net/xrx4egm.css);
@import url(https://fonts.googleapis.com/css?family=Alfa+Slab+One);
@import url(https://use.typekit.net/xrx4egm.css);
@import url(https://fonts.googleapis.com/css?family=Alfa+Slab+One);
@import url(https://use.typekit.net/xrx4egm.css);
@import url(https://fonts.googleapis.com/css?family=Alfa+Slab+One);
@import url(https://use.typekit.net/xrx4egm.css);
@import url(https://fonts.googleapis.com/css?family=Alfa+Slab+One);
@import url(https://use.typekit.net/xrx4egm.css);
@import url(https://fonts.googleapis.com/css?family=Alfa+Slab+One);
@import url(https://use.typekit.net/xrx4egm.css);
@import url(https://fonts.googleapis.com/css?family=Alfa+Slab+One);
@import url(https://use.typekit.net/xrx4egm.css);
@import url(https://fonts.googleapis.com/css?family=Alfa+Slab+One);
@import url(https://use.typekit.net/xrx4egm.css);
@import url(https://fonts.googleapis.com/css?family=Alfa+Slab+One);
@import url(https://use.typekit.net/xrx4egm.css);
@import url(https://fonts.googleapis.com/css?family=Alfa+Slab+One);
@import url(https://use.typekit.net/xrx4egm.css);
@import url(https://fonts.googleapis.com/css?family=Alfa+Slab+One);
@import url(https://use.typekit.net/xrx4egm.css);
@import url(https://fonts.googleapis.com/css?family=Alfa+Slab+One);
@import url(https://use.typekit.net/xrx4egm.css);
@import url(https://fonts.googleapis.com/css?family=Alfa+Slab+One);
@import url(https://use.typekit.net/xrx4egm.css);
@import url(https://fonts.googleapis.com/css?family=Alfa+Slab+One);
@import url(https://use.typekit.net/xrx4egm.css);
@import url(https://fonts.googleapis.com/css?family=Alfa+Slab+One);
@import url(https://use.typekit.net/xrx4egm.css);
@import url(https://fonts.googleapis.com/css?family=Alfa+Slab+One);
@import url(https://use.typekit.net/xrx4egm.css);
@import url(https://fonts.googleapis.com/css?family=Alfa+Slab+One);
@import url(https://use.typekit.net/xrx4egm.css);
@import url(https://fonts.googleapis.com/css?family=Alfa+Slab+One);
@import url(https://use.typekit.net/xrx4egm.css);
@import url(https://fonts.googleapis.com/css?family=Alfa+Slab+One);
@import url(https://use.typekit.net/xrx4egm.css);
@import url(https://fonts.googleapis.com/css?family=Alfa+Slab+One);
@import url(https://use.typekit.net/xrx4egm.css);
@import url(https://fonts.googleapis.com/css?family=Alfa+Slab+One);
@import url(https://use.typekit.net/xrx4egm.css);
@import url(https://fonts.googleapis.com/css?family=Alfa+Slab+One);
@import url(https://use.typekit.net/xrx4egm.css);
@import url(https://fonts.googleapis.com/css?family=Alfa+Slab+One);
@import url(https://use.typekit.net/xrx4egm.css);
@import url(https://fonts.googleapis.com/css?family=Alfa+Slab+One);
@import url(https://use.typekit.net/xrx4egm.css);
@import url(https://fonts.googleapis.com/css?family=Alfa+Slab+One);
@import url(https://use.typekit.net/xrx4egm.css);
@import url(https://fonts.googleapis.com/css?family=Alfa+Slab+One);
@import url(https://use.typekit.net/xrx4egm.css);
@import url(https://fonts.googleapis.com/css?family=Alfa+Slab+One);
@import url(https://use.typekit.net/xrx4egm.css);
@import url(https://fonts.googleapis.com/css?family=Alfa+Slab+One);
@import url(https://use.typekit.net/xrx4egm.css);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input::-webkit-input-placeholder {
  margin-bottom: 5px; }
input::-ms-input-placeholder {
  margin-bottom: 5px; }
input::placeholder {
  margin-bottom: 5px; }

.Login_loginWrapper__3n2Nw {
  height: 100vh;
  margin-top: 55px; }
  .Login_loginWrapper__3n2Nw .Login_login__2i1vo {
    display: grid;
    grid-template-rows: 30% 1fr;
    padding: 0;
    border: 1px solid #9b9b9b;
    height: 40%;
    width: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
    @media (max-width: 768px) {
      .Login_loginWrapper__3n2Nw .Login_login__2i1vo {
        width: 95%;
        height: 80%; } }
    .Login_loginWrapper__3n2Nw .Login_login__2i1vo img {
      position: absolute;
      right: 10px;
      top: 5px; }
    .Login_loginWrapper__3n2Nw .Login_login__2i1vo form {
      width: 80%;
      margin: 0 auto;
      display: grid;
      grid-template-rows: 15% 15% 5% 1fr; }
      .Login_loginWrapper__3n2Nw .Login_login__2i1vo form input {
        font-size: calc(16px + 0.3vw);
        font-family: futura-pt, sans-serif;
        width: 80%;
        height: 20px;
        border-width: 0 0 4px 0;
        -webkit-align-self: end;
                align-self: end;
        margin-bottom: 20px; }
      .Login_loginWrapper__3n2Nw .Login_login__2i1vo form button {
        height: 50px;
        width: 40%;
        -webkit-align-self: center;
                align-self: center;
        justify-self: center;
        background-color: #349800;
        color: white;
        font-family: futura-pt, sans-serif;
        font-size: calc(16px + 0.3vw); }
    .Login_loginWrapper__3n2Nw .Login_login__2i1vo h3,
    .Login_loginWrapper__3n2Nw .Login_login__2i1vo a {
      font-family: futura-pt, sans-serif;
      font-size: calc(16px + 0.3vw); }
    .Login_loginWrapper__3n2Nw .Login_login__2i1vo a {
      color: #112047; }
  .Login_loginWrapper__3n2Nw .Login_registerText__7WxLf {
    width: 80%;
    justify-self: center;
    font-size: calc(16px + 0.3vw);
    font-family: futura-pt, sans-serif; }

.Login_typeSelect__1XSNz {
  height: 10%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 10px;
  text-align: center;
  border-collapse: collapse;
  padding: 10px; }
  .Login_typeSelect__1XSNz div {
    width: 100%;
    height: 100%; }
    .Login_typeSelect__1XSNz div h2 {
      font-family: futura-pt, sans-serif;
      font-size: 18px;
      color: black; }
  .Login_typeSelect__1XSNz .Login_selected__3XcAx h2 {
    color: #349800; }

.HamburgerMenu_burger_menu__3strC,
.HamburgerMenu_burger_menu__3strC.HamburgerMenu_open__2SJuR {
  display: inline-block;
  cursor: pointer;
  padding: 10px 23px;
  grid-row: 1 / span 2; }

.HamburgerMenu_burger_menu__3strC:hover > div {
  background-color: #7ed321; }

.HamburgerMenu_burger_menu__3strC .HamburgerMenu_bar1__CSx1B,
.HamburgerMenu_bar2__3YCAB,
.HamburgerMenu_bar3__2FWS5 {
  width: 38px;
  height: 5px;
  background-color: #fff;
  margin: 4px 0;
  transition: 0.4s; }

.HamburgerMenu_burger_menu__3strC.HamburgerMenu_open__2SJuR .HamburgerMenu_bar1__CSx1B {
  -webkit-transform: rotate(-45deg) translate(-4px, 4px);
  transform: rotate(-45deg) translate(-6px, 6px); }

.HamburgerMenu_burger_menu__3strC.HamburgerMenu_open__2SJuR .HamburgerMenu_bar2__3YCAB {
  opacity: 0; }

.HamburgerMenu_burger_menu__3strC.HamburgerMenu_open__2SJuR .HamburgerMenu_bar3__2FWS5 {
  -webkit-transform: rotate(45deg) translate(-6px, -6px);
  transform: rotate(45deg) translate(-6px, -6px); }

/* Menu */
.HamburgerMenu_menu__3LRy6 {
  width: 100%;
  display: block;
  text-align: center;
  padding: 0px; }

.HamburgerMenu_menu__3LRy6 ul {
  position: relative;
  top: 0px;
  font-size: calc(16px + 1vw);
  padding: 0px; }

.HamburgerMenu_menu__3LRy6 li {
  list-style: outside none none;
  margin: 10px 0px;
  padding: 0;
  cursor: pointer; }

.HamburgerMenu_menu__3LRy6 li:hover {
  color: #ff0000; }

.menu_screenOverlay__2eYAk {
  z-index: 98;
  position: absolute;
  top: 55px;
  left: 0;
  height: 200vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.65); }

.menu_modalWrapper__2rdaW {
  position: absolute;
  background-color: white;
  left: 0;
  width: 100%;
  height: 300vh;
  z-index: 99; }
  @media (max-width: 768px) {
    .menu_modalWrapper__2rdaW {
      position: fixed;
      width: 100%; } }

.menu_menuContainer__3sYfx {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 50%;
  margin: 10vh auto;
  -webkit-align-items: center;
          align-items: center; }
  .menu_menuContainer__3sYfx a,
  .menu_menuContainer__3sYfx p {
    margin: 1vh 0px;
    text-decoration: none;
    font-family: "Alfa Slab One", cursive;
    font-size: calc(16px + 2vw);
    color: #112047;
    transition: 0.25s ease; }
    .menu_menuContainer__3sYfx a:hover,
    .menu_menuContainer__3sYfx p:hover {
      transition: 0.25s ease;
      color: #7ed321;
      letter-spacing: 1px; }
    @media (max-width: 768px) {
      .menu_menuContainer__3sYfx a,
      .menu_menuContainer__3sYfx p {
        font-size: calc(16px + 1vw);
        text-align: center;
        padding: 20px 0;
        margin: 0; } }
  @media (max-width: 768px) {
    .menu_menuContainer__3sYfx {
      width: 100%; } }

.Header_pageHeader__3y87o {
  background-color: #112047;
  color: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  height: 55px;
  width: 100%;
  display: grid;
  grid-template-columns: 40% 1fr;
  -webkit-align-items: center;
          align-items: center;
  z-index: 499;
  padding: 0;
  margin: 0; }
  .Header_pageHeader__3y87o .Header_contactLink__3wQzJ {
    text-align: center;
    font-size: calc(16px + 0.3vw);
    font-family: futura-pt, sans-serif;
    color: #ffffff;
    font-weight: bold;
    padding-left: 20px; }
    @media (max-width: 768px) {
      .Header_pageHeader__3y87o .Header_contactLink__3wQzJ {
        display: none;
        grid-row-start: 1; } }
  .Header_pageHeader__3y87o .Header_orderLink__3BEas {
    text-align: center;
    font-size: calc(16px + 0.3vw);
    font-family: futura-pt, sans-serif;
    color: #ffffff;
    font-weight: bold;
    padding-left: 20px; }
    @media (max-width: 768px) {
      .Header_pageHeader__3y87o .Header_orderLink__3BEas {
        font-size: 14px;
        text-align: left;
        padding-top: 4px;
        grid-row-start: 1; } }
    @media (max-width: 667px) {
      .Header_pageHeader__3y87o .Header_orderLink__3BEas {
        font-size: 12px;
        grid-row-start: 1; } }
  .Header_pageHeader__3y87o .Header_logo__3iMei {
    max-width: 100%;
    height: 55px;
    padding-left: 23px; }
    @media (max-width: 768px) {
      .Header_pageHeader__3y87o .Header_logo__3iMei {
        height: auto;
        max-height: 55px;
        padding-left: 10px; } }
  .Header_pageHeader__3y87o h1 {
    text-align: center;
    margin: 0;
    font-size: calc(16px + 0.3vw);
    font-family: futura-pt, sans-serif; }
  .Header_pageHeader__3y87o .Header_phoneLink__1KPwT {
    text-decoration: none;
    padding-left: 20px;
    color: #ffffff;
    font-size: calc(16px + 0.3vw);
    font-family: futura-pt, sans-serif;
    transition: ease 0.55s; }
    @media (max-width: 667px) {
      .Header_pageHeader__3y87o .Header_phoneLink__1KPwT {
        font-size: 14px; } }
    .Header_pageHeader__3y87o .Header_phoneLink__1KPwT:hover {
      color: #7ed321;
      transition: ease 0.55s; }

.Header_contactBlock__2Sxvg {
  padding-right: 10px;
  justify-self: right; }
  @media (max-width: 768px) {
    .Header_contactBlock__2Sxvg {
      display: grid;
      height: 55px;
      grid-template-columns: 75% 25%;
      grid-template-rows: 50% 50%;
      -webkit-align-items: center;
              align-items: center;
      justify-items: center; }
      .Header_contactBlock__2Sxvg h1 {
        grid-column-start: 1;
        grid-row-start: 1;
        width: 80%; }
      .Header_contactBlock__2Sxvg a {
        grid-column-start: 1;
        grid-row-start: 2;
        width: 80%; } }
  .Header_contactBlock__2Sxvg > * {
    display: inline-block;
    vertical-align: middle; }

footer {
  background-color: #112047;
  display: grid;
  grid-template-columns: 30vw 40vw 30vw;
  grid-template-rows: 325px 150px 150px -webkit-fit-content -webkit-fit-content;
  grid-template-rows: 325px 150px 150px fit-content fit-content;
  justify-items: center;
  color: #ffffff;
  min-height: 500px;
  padding-bottom: 2%; }
  @media (max-width: 768px) {
    footer {
      display: inherit; } }
  footer h1,
  footer h2,
  footer h3,
  footer h4,
  footer h5,
  footer h6 {
    font-family: "Alfa Slab One", cursive;
    letter-spacing: 0.1vw;
    font-weight: 250; }
  footer h3 {
    font-size: calc(16px + 1vw); }
    @media (max-width: 768px) {
      footer h3 {
        font-size: calc(16px + 0.3vw);
        margin: 10px; } }
  footer p {
    font-size: calc(16px + 0.3vw); }
  footer .footer_col__17XCn {
    padding: 50px; }
    footer .footer_col__17XCn a {
      text-decoration: none;
      color: #ffffff;
      font-family: futura-pt, sans-serif;
      font-weight: 400;
      display: block;
      font-size: calc(16px + 0.3vw);
      padding: 5px; }
    footer .footer_col__17XCn .footer_follow__2uevB {
      margin-bottom: 10px; }
    footer .footer_col__17XCn .footer_facebookLink__1dlWK,
    footer .footer_col__17XCn .footer_linkedinLink__6_or1 {
      display: grid;
      width: 100%;
      height: 70px;
      -webkit-align-items: center;
              align-items: center;
      grid-template-columns: 0.5fr 1fr; }
      footer .footer_col__17XCn .footer_facebookLink__1dlWK a,
      footer .footer_col__17XCn .footer_linkedinLink__6_or1 a {
        height: 100%; }
      footer .footer_col__17XCn .footer_facebookLink__1dlWK p,
      footer .footer_col__17XCn .footer_linkedinLink__6_or1 p {
        margin-left: -10px; }
    footer .footer_col__17XCn .footer_facebookIcon__2pJhn,
    footer .footer_col__17XCn .footer_linkedinIcon__RN0VQ {
      padding-right: 10px;
      height: 70%; }
    @media (max-width: 768px) {
      footer .footer_col__17XCn {
        text-align: center;
        padding: 3% 5%; }
        footer .footer_col__17XCn .footer_facebookIcon__2pJhn,
        footer .footer_col__17XCn .footer_linkedinIcon__RN0VQ {
          justify-self: right;
          height: 50%; }
        footer .footer_col__17XCn .footer_facebookLink__1dlWK,
        footer .footer_col__17XCn .footer_linkedinLink__6_or1 {
          grid-template-columns: 40% 60%; }
        footer .footer_col__17XCn .footer_facebookLink__1dlWK p,
        footer .footer_col__17XCn .footer_linkedinLink__6_or1 p {
          text-align: left;
          padding-left: 10px; } }
  footer .footer_sign__1NF8T {
    text-align: center; }
    footer .footer_sign__1NF8T button,
    footer .footer_sign__1NF8T input {
      display: block;
      margin: 10px auto 10px; }
    footer .footer_sign__1NF8T input {
      background: none;
      color: #ffffff;
      border: 1px solid #9b9b9b;
      width: 100%;
      box-sizing: border-box;
      border-radius: 0;
      -webkit-appearance: none;
      -webkit-tap-highlight-color: transparent;
      font-size: calc(16px + 0.3vw);
      min-height: 27px;
      padding: 12px 25px 12px 25px;
      text-align: center; }
    footer .footer_sign__1NF8T button {
      background: #d8d8d8;
      color: #112047;
      width: 100%;
      box-sizing: border-box;
      font-size: calc(16px + 0.3vw);
      font-weight: 800;
      text-transform: uppercase;
      min-height: 27px;
      padding: 12px 25px 12px 25px;
      text-align: center;
      cursor: pointer;
      border: 0; }
  footer .footer_links__2g9VN {
    text-align: center;
    grid-column-start: 1;
    grid-column-end: end;
    grid-row-start: 3; }
    footer .footer_links__2g9VN a {
      font-family: "Alfa Slab One", cursive;
      font-size: calc(16px + 0.3vw);
      font-weight: 250;
      letter-spacing: 0.05vw;
      padding: 20px;
      display: inline-block;
      color: #ffffff;
      text-decoration: none; }
  footer .footer_bottomLogos__ARtMo {
    grid-column-start: 2;
    grid-row-start: 2;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    -webkit-align-items: center;
            align-items: center; }
    footer .footer_bottomLogos__ARtMo img {
      height: 100%;
      margin: 0 2vw;
      max-height: 40px; }
    @media (max-width: 1200px) {
      footer .footer_bottomLogos__ARtMo {
        grid-column: 1 / 4; } }
    @media (max-width: 768px) {
      footer .footer_bottomLogos__ARtMo {
        width: 80%;
        margin: 0 auto;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: space-evenly;
                justify-content: space-evenly; } }
  footer .footer_copyright__1uyVP {
    grid-column: 1 / span 3;
    grid-row-start: 5;
    padding-top: 7px;
    margin: 0;
    text-align: center;
    justify-self: center;
    font-size: 16px; }
    @media (max-width: 768px) {
      footer .footer_copyright__1uyVP {
        font-size: 12px;
        text-align: center; } }

.grecaptcha-badge {
  visibility: collapse !important; }

.footer_policy__3DUi6 {
  width: 100%;
  grid-column: 1 / span 3;
  grid-row-start: 4;
  font-size: 12px;
  text-align: center;
  justify-self: center; }
  .footer_policy__3DUi6 a {
    color: #7ed321;
    padding-left: 3px;
    padding-right: 3px; }
  @media (max-width: 768px) {
    .footer_policy__3DUi6 {
      font-size: 10px; } }

.selectionButton_selectionButton__2CcBx {
  width: 80%;
  margin: 0.5vw auto;
  height: 15em;
  color: #ffffff;
  display: grid;
  grid-template-columns: 20% 25% 1fr;
  -webkit-align-items: center;
          align-items: center;
  justify-items: center;
  box-shadow: none;
  transition: 0.75s ease;
  background-size: cover; }
  .selectionButton_selectionButton__2CcBx:hover {
    box-shadow: inset 0 0 0 1000px rgba(126, 211, 33, 0.8);
    transition: 0.75s ease;
    cursor: pointer; }
  .selectionButton_selectionButton__2CcBx img {
    max-width: 10vw; }
  .selectionButton_selectionButton__2CcBx h1 {
    font-family: futura-pt, sans-serif;
    font-weight: bold;
    font-size: calc(16px + 1vw);
    text-align: right;
    width: 100%; }
    @media (max-width: 768px) {
      .selectionButton_selectionButton__2CcBx h1 {
        justify-self: left;
        text-align: center; } }
  .selectionButton_selectionButton__2CcBx p {
    font-family: futura-pt, sans-serif;
    font-size: calc(16px + 1vw);
    text-align: left;
    padding: 0 5vw;
    justify-self: left; }
    @media (max-width: 768px) {
      .selectionButton_selectionButton__2CcBx p {
        display: none; } }
  @media (max-width: 1200px) {
    .selectionButton_selectionButton__2CcBx {
      grid-template-columns: 10% 35% 1fr; } }
  @media (max-width: 768px) {
    .selectionButton_selectionButton__2CcBx {
      width: 90%;
      height: 80px;
      grid-template-columns: 25% 1fr; } }

.selectionButton_buttonLogo__3Ky9Z {
  padding-left: 20px; }
  @media (max-width: 768px) {
    .selectionButton_buttonLogo__3Ky9Z {
      padding-left: 0; } }

.selection_sectionCard__ovGGf {
  display: grid;
  grid-template-columns: 38% 62%;
  background-color: transparent;
  min-height: 35vh; }
  @media (max-width: 768px) {
    .selection_sectionCard__ovGGf {
      display: block; } }

.selection_leftField__U9yhG {
  display: grid;
  background-color: rgba(0, 0, 0, 0.35);
  color: white;
  grid-column-start: 1;
  grid-column-end: 2;
  -webkit-align-content: center;
          align-content: center;
  -webkit-justify-content: left;
          justify-content: left;
  padding-left: 40px;
  padding-right: 40px; }
  .selection_leftField__U9yhG h1 {
    font-family: "Alfa Slab One", cursive;
    font-size: calc(16px + 2vw);
    font-weight: 250;
    letter-spacing: 0.2vw;
    width: 90%; }
    @media (max-width: 768px) {
      .selection_leftField__U9yhG h1 {
        font-size: calc(16px + 2vw); } }
  .selection_leftField__U9yhG p {
    font-family: futura-pt, sans-serif;
    font-weight: 400;
    width: 80%;
    font-size: calc(16px + 0.3vw); }
  @media (max-width: 768px) {
    .selection_leftField__U9yhG {
      display: block;
      padding-top: 5%;
      padding-bottom: 5%; } }

.selection_rightField__3RO6r {
  grid-column-start: 2;
  grid-column-end: 3;
  display: -webkit-flex;
  display: flex;
  background-color: whitesmoke; }

.selection_selectionContainer__2lxyt {
  background-color: lightgrey;
  width: 100%;
  padding-top: 100px;
  padding-bottom: 100px; }
  @media (max-width: 768px) {
    .selection_selectionContainer__2lxyt {
      padding-top: 20px;
      padding-bottom: 20px; } }

@media (max-width: 768px) {
  .selection_firstLeft__2bJF1 {
    padding-top: 55px; }
    .selection_firstLeft__2bJF1 p {
      width: 100%; } }

.selection_buttonContainer__3Lau9 {
  margin-top: 60px;
  margin-bottom: 100px;
  width: 100%; }
  @media (max-width: 768px) {
    .selection_buttonContainer__3Lau9 {
      margin-top: 5%;
      margin-bottom: 5%; } }

.phone_sectionCard__1iTh6 {
  display: grid;
  grid-template-columns: 38% 62%;
  background-color: transparent;
  min-height: 35vh; }
  @media (max-width: 768px) {
    .phone_sectionCard__1iTh6 {
      display: block; } }

.phone_leftField__1yTb8 {
  display: grid;
  background-color: rgba(0, 0, 0, 0.35);
  color: white;
  grid-column-start: 1;
  grid-column-end: 2;
  -webkit-align-content: center;
          align-content: center;
  -webkit-justify-content: left;
          justify-content: left;
  padding-left: 40px;
  padding-right: 40px; }
  .phone_leftField__1yTb8 h1 {
    font-family: "Alfa Slab One", cursive;
    font-size: calc(16px + 2vw);
    font-weight: 250;
    letter-spacing: 0.2vw;
    width: 90%; }
    @media (max-width: 768px) {
      .phone_leftField__1yTb8 h1 {
        font-size: calc(16px + 2vw); } }
  .phone_leftField__1yTb8 p {
    font-family: futura-pt, sans-serif;
    font-weight: 400;
    width: 80%;
    font-size: calc(16px + 0.3vw); }
  @media (max-width: 768px) {
    .phone_leftField__1yTb8 {
      display: block;
      padding-top: 5%;
      padding-bottom: 5%; } }

.phone_rightField___wNDq {
  grid-column-start: 2;
  grid-column-end: 3;
  display: -webkit-flex;
  display: flex;
  background-color: whitesmoke; }

.phone_phoneContainer__3MbL4 {
  background: transparent;
  position: relative;
  width: 100%;
  padding-top: 200px;
  padding-bottom: 200px;
  overflow-x: hidden; }
  @media (max-width: 768px) {
    .phone_phoneContainer__3MbL4 {
      padding-top: 20px;
      padding-bottom: 20px; } }

.phone_phoneDetail__1oinL {
  width: 100%;
  padding-left: 10%;
  margin: 0 auto;
  position: relative;
  display: grid;
  grid-template-columns: 70% 30%;
  grid-template-rows: 200px -webkit-fit-content;
  grid-template-rows: 200px fit-content; }
  @media (max-width: 768px) {
    .phone_phoneDetail__1oinL {
      padding-left: 0; } }
  .phone_phoneDetail__1oinL .phone_phoneTopper__2wLmt {
    width: 100%;
    grid-column: 1 / 2; }
    @media (max-width: 768px) {
      .phone_phoneDetail__1oinL .phone_phoneTopper__2wLmt {
        grid-column: 1 / span 2; } }
  .phone_phoneDetail__1oinL .phone_phoneText__ltVJ0 {
    grid-column-start: 1;
    grid-row-start: 2;
    padding-top: 50px;
    margin-left: -50px; }
    @media (max-width: 768px) {
      .phone_phoneDetail__1oinL .phone_phoneText__ltVJ0 {
        grid-column: 1 / span 2;
        margin-left: 0; } }
  .phone_phoneDetail__1oinL p {
    grid-column-start: 1;
    grid-row-start: 1;
    width: 80%;
    margin: 10px auto 20px 5vw;
    font-family: futura-pt, sans-serif;
    font-weight: 400;
    font-size: calc(16px + 1vw);
    text-align: center; }
    @media (max-width: 768px) {
      .phone_phoneDetail__1oinL p {
        margin: 0 auto 20px auto; } }

.phone_phoneImage___mXOv {
  grid-column-start: 2;
  grid-row: 1 / span 2;
  height: 110%;
  z-index: 10;
  position: relative;
  top: -5%;
  left: -25px; }
  @media (max-width: 768px) {
    .phone_phoneImage___mXOv {
      display: none; } }

.phone_socialIcons__3cUqH {
  width: 80%;
  justify-self: start;
  display: grid;
  grid-template-columns: 70px 1fr 1fr; }
  .phone_socialIcons__3cUqH .phone_social1__jAhId {
    width: 50px; }
  .phone_socialIcons__3cUqH a {
    display: grid; }
    .phone_socialIcons__3cUqH a img {
      width: 90%; }

.seals_sectionCard__2Zgh_ {
  display: grid;
  grid-template-columns: 38% 62%;
  background-color: transparent;
  min-height: 35vh; }
  @media (max-width: 768px) {
    .seals_sectionCard__2Zgh_ {
      display: block; } }

.seals_leftField__1219F {
  display: grid;
  background-color: rgba(0, 0, 0, 0.35);
  color: white;
  grid-column-start: 1;
  grid-column-end: 2;
  -webkit-align-content: center;
          align-content: center;
  -webkit-justify-content: left;
          justify-content: left;
  padding-left: 40px;
  padding-right: 40px; }
  .seals_leftField__1219F h1 {
    font-family: "Alfa Slab One", cursive;
    font-size: calc(16px + 2vw);
    font-weight: 250;
    letter-spacing: 0.2vw;
    width: 90%; }
    @media (max-width: 768px) {
      .seals_leftField__1219F h1 {
        font-size: calc(16px + 2vw); } }
  .seals_leftField__1219F p {
    font-family: futura-pt, sans-serif;
    font-weight: 400;
    width: 80%;
    font-size: calc(16px + 0.3vw); }
  @media (max-width: 768px) {
    .seals_leftField__1219F {
      display: block;
      padding-top: 5%;
      padding-bottom: 5%; } }

.seals_rightField__34Kll {
  grid-column-start: 2;
  grid-column-end: 3;
  display: -webkit-flex;
  display: flex;
  background-color: whitesmoke; }

.seals_sealsContainer__RVNru {
  display: grid;
  position: relative;
  width: 100%;
  color: white;
  height: 40vh;
  padding-top: 100px;
  padding-bottom: 100px;
  -webkit-align-content: center;
          align-content: center; }
  @media (max-width: 768px) {
    .seals_sealsContainer__RVNru {
      padding-top: 0;
      padding-bottom: 0;
      margin-top: -10%; } }
  .seals_sealsContainer__RVNru p {
    width: 80%;
    margin: 0 auto;
    font-family: futura-pt, sans-serif;
    font-weight: bold;
    font-size: calc(16px + 2vw);
    text-align: center; }
  .seals_sealsContainer__RVNru .seals_sectionCard__2Zgh_ {
    min-height: 30vh; }

.molding_sectionCard__7jtSs {
  display: grid;
  grid-template-columns: 38% 62%;
  background-color: transparent;
  min-height: 35vh; }
  @media (max-width: 768px) {
    .molding_sectionCard__7jtSs {
      display: block; } }

.molding_leftField__3dW_q {
  display: grid;
  background-color: rgba(0, 0, 0, 0.35);
  color: white;
  grid-column-start: 1;
  grid-column-end: 2;
  -webkit-align-content: center;
          align-content: center;
  -webkit-justify-content: left;
          justify-content: left;
  padding-left: 40px;
  padding-right: 40px; }
  .molding_leftField__3dW_q h1 {
    font-family: "Alfa Slab One", cursive;
    font-size: calc(16px + 2vw);
    font-weight: 250;
    letter-spacing: 0.2vw;
    width: 90%; }
    @media (max-width: 768px) {
      .molding_leftField__3dW_q h1 {
        font-size: calc(16px + 2vw); } }
  .molding_leftField__3dW_q p {
    font-family: futura-pt, sans-serif;
    font-weight: 400;
    width: 80%;
    font-size: calc(16px + 0.3vw); }
  @media (max-width: 768px) {
    .molding_leftField__3dW_q {
      display: block;
      padding-top: 5%;
      padding-bottom: 5%; } }

.molding_rightField__1v2Ql {
  grid-column-start: 2;
  grid-column-end: 3;
  display: -webkit-flex;
  display: flex;
  background-color: whitesmoke; }

.molding_moldingContainer__1mXc- {
  display: grid;
  background: transparent;
  position: relative;
  width: 100%;
  color: white;
  padding-top: 150px;
  padding-bottom: 150px;
  -webkit-align-content: center;
          align-content: center; }
  @media (max-width: 768px) {
    .molding_moldingContainer__1mXc- {
      padding-top: 20px;
      padding-bottom: 20px; } }
  .molding_moldingContainer__1mXc- p {
    width: 80%;
    margin: 20px 0 20px 10%;
    font-family: futura-pt, sans-serif;
    font-weight: 400;
    font-size: calc(16px + 1vw); }

.Home_mainWindow__3q64- {
  padding-top: 55px;
  margin: 0;
  box-sizing: border-box; }

.Home_sectionWrapper__3I8yj {
  background-color: transparent; }

.Home_tempBanner__Bie1N {
  width: 100%;
  display: grid;
  -webkit-align-items: center;
          align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 55px;
  z-index: 98;
  display: grid;
  grid-template-columns: 1fr 20px; }
  .Home_tempBanner__Bie1N h3,
  .Home_tempBanner__Bie1N img {
    font-family: futura-pt, sans-serif;
    font-size: calc(16px + 0.3vw);
    color: #7ed321;
    font-weight: bold;
    margin: 0; }
  .Home_tempBanner__Bie1N img {
    justify-self: right;
    grid-row: 1;
    margin-right: 30px;
    height: 60%; }
    .Home_tempBanner__Bie1N img:hover {
      cursor: pointer; }
    @media (max-width: 768px) {
      .Home_tempBanner__Bie1N img {
        width: 30px;
        height: auto; } }
  .Home_tempBanner__Bie1N h3 {
    grid-row: 1;
    grid-column: 1 / span end;
    justify-self: center; }
    @media (max-width: 768px) {
      .Home_tempBanner__Bie1N h3 {
        font-size: 14px; } }
  @media (max-width: 768px) {
    .Home_tempBanner__Bie1N {
      height: auto;
      padding: 3%; } }

.topper_topper__JDttD {
  min-height: 40vh;
  display: grid;
  -webkit-align-items: center;
          align-items: center;
  border-bottom: 4px solid white; }
  @media (max-width: 768px) {
    .topper_topper__JDttD {
      min-height: 30vh; } }

.topper_textBlock__3cVGE {
  width: 70%;
  margin: 0 auto;
  display: grid;
  justify-items: center; }
  .topper_textBlock__3cVGE h1,
  .topper_textBlock__3cVGE p {
    color: #ffffff;
    text-align: center; }
  .topper_textBlock__3cVGE h1 {
    font-family: "Alfa Slab One", cursive;
    font-size: calc(16px + 2vw);
    font-weight: 250;
    letter-spacing: 0.2vw;
    margin: 0; }
  .topper_textBlock__3cVGE p {
    font-family: futura-pt, sans-serif;
    font-weight: 400;
    width: 60%;
    font-size: calc(16px + 0.3vw);
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5); }
    @media (max-width: 768px) {
      .topper_textBlock__3cVGE p {
        width: 90%; } }
  @media (max-width: 768px) {
    .topper_textBlock__3cVGE {
      width: 90%; } }

.count_countWrapper__1JU2y {
  min-height: 35vh;
  display: grid;
  grid-template-columns: 25% 1fr;
  -webkit-align-items: center;
          align-items: center;
  justify-items: center;
  color: #ffffff;
  background-color: #112047; }
  @media (max-width: 768px) {
    .count_countWrapper__1JU2y {
      display: block; } }

.count_leftImage__3JTBl {
  display: grid;
  -webkit-align-items: center;
          align-items: center;
  justify-items: center;
  height: 100%; }
  .count_leftImage__3JTBl h1 {
    font-family: "Alfa Slab One", cursive;
    font-size: calc(16px + 2vw);
    font-weight: 250;
    letter-spacing: 0.2vw;
    margin: 0;
    margin-left: 20px; }
  @media (max-width: 768px) {
    .count_leftImage__3JTBl {
      height: 10vh; } }

.count_rightList__2kQ6p {
  width: 80%;
  margin: 0 auto; }
  .count_rightList__2kQ6p ul {
    -webkit-align-self: top;
            align-self: top;
    justify-self: center;
    -webkit-columns: 2;
            columns: 2; }
    @media (max-width: 768px) {
      .count_rightList__2kQ6p ul {
        -webkit-columns: 2;
                columns: 2;
        padding: 10%;
        margin: 0; } }
  .count_rightList__2kQ6p li {
    font-family: futura-pt, sans-serif;
    font-weight: 400;
    font-size: calc(16px + 0.3vw);
    list-style: none;
    position: relative;
    box-sizing: border-box;
    padding-right: 20px; }
    .count_rightList__2kQ6p li:before {
      position: absolute;
      top: 0em;
      left: -20px;
      color: #7ed321;
      content: '\2022';
      font-size: calc(16px + 0.3vw);
      padding-right: 0.5em; }
    .count_rightList__2kQ6p li a {
      color: white; }
      .count_rightList__2kQ6p li a:visited {
        color: white; }
      .count_rightList__2kQ6p li a:hover {
        color: #7ed321; }
    @media (max-width: 768px) {
      .count_rightList__2kQ6p li {
        float: none;
        width: 100%; } }
  @media (max-width: 768px) {
    .count_rightList__2kQ6p {
      width: 90%; } }

.product_option_cardWrapper__1ZFj1 {
  width: 100%;
  height: 30vh;
  display: grid;
  -webkit-align-items: center;
          align-items: center;
  justify-items: center;
  border: 1px solid white; }
  .product_option_cardWrapper__1ZFj1:hover {
    box-shadow: inset 0 0 0 1000px rgba(126, 211, 33, 0.8);
    transition: 0.75s ease;
    cursor: pointer; }
  .product_option_cardWrapper__1ZFj1 p {
    font-family: futura-pt, sans-serif;
    width: 75%;
    color: #ffffff;
    text-align: center;
    font-size: calc(16px + 1.5vh);
    font-weight: bold;
    line-height: 95%; }
  @media (max-width: 768px) {
    .product_option_cardWrapper__1ZFj1 {
      height: 15vh; } }

.elevator_animation_elevatorAnimation__1ciot {
  height: 50vh;
  width: 80%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 30% 15% 1fr;
  -webkit-align-items: center;
          align-items: center;
  justify-items: center;
  position: relative; }
  .elevator_animation_elevatorAnimation__1ciot h1 {
    color: #112047;
    font-family: "Alfa Slab One", cursive;
    font-size: calc(16px + 2vw);
    font-weight: 250;
    letter-spacing: 0.2vw;
    grid-column: 1 / 2; }
  .elevator_animation_elevatorAnimation__1ciot p {
    font-family: futura-pt, sans-serif;
    font-weight: 400;
    font-size: calc(16px + 0.3vw);
    text-align: left; }
  .elevator_animation_elevatorAnimation__1ciot .elevator_animation_shaft__IoFNK {
    width: 2px;
    height: 70%;
    position: absolute;
    top: 13%;
    background-color: #979797;
    grid-column: 2 / 3; }
    .elevator_animation_elevatorAnimation__1ciot .elevator_animation_shaft__IoFNK .elevator_animation_elevator__oyibu {
      position: sticky;
      position: -webkit-sticky;
      top: 40%;
      margin-left: -18px;
      margin-bottom: -10px;
      transition: ease 3s; }
    @media (max-width: 768px) {
      .elevator_animation_elevatorAnimation__1ciot .elevator_animation_shaft__IoFNK {
        position: static;
        margin-right: 15%;
        float: left; } }
  .elevator_animation_elevatorAnimation__1ciot div:last-of-type {
    grid-column: 3 / 4; }
  @media (max-width: 768px) {
    .elevator_animation_elevatorAnimation__1ciot {
      display: block; } }

.product_choice_pcWrapper__1L70B h1 {
  color: #112047;
  font-family: "Alfa Slab One", cursive;
  font-size: calc(16px + 2vw);
  font-weight: 250;
  letter-spacing: 0.2vw;
  margin: 0; }
  @media (max-width: 768px) {
    .product_choice_pcWrapper__1L70B h1 {
      margin: 10% 0; } }

.product_choice_productsBanner__rOjdv {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  height: 2.5vw;
  width: 20%;
  margin: 80px auto; }
  .product_choice_productsBanner__rOjdv h1 {
    line-height: 2.5vw;
    margin-left: 20px; }
  .product_choice_productsBanner__rOjdv img {
    height: auto;
    width: auto;
    max-width: 100%;
    width: 6vw; }
  @media (max-width: 768px) {
    .product_choice_productsBanner__rOjdv {
      width: auto; } }

.product_choice_productBlock__1R6P1 {
  width: 100%;
  height: 30vh;
  display: -webkit-flex;
  display: flex; }
  .product_choice_productBlock__1R6P1 > * {
    width: 100%; }
  .product_choice_productBlock__1R6P1 > *:first-child:nth-last-child(2),
  .product_choice_productBlock__1R6P1 > *:first-child:nth-last-child(2) ~ a {
    width: 50%; }
  .product_choice_productBlock__1R6P1 > *:first-child:nth-last-child(3),
  .product_choice_productBlock__1R6P1 > *:first-child:nth-last-child(3) ~ a {
    width: 33.33%; }
  .product_choice_productBlock__1R6P1 > *:first-child:nth-last-child(4),
  .product_choice_productBlock__1R6P1 > *:first-child:nth-last-child(4) ~ a {
    width: 25%; }
  @media (max-width: 768px) {
    .product_choice_productBlock__1R6P1 {
      display: block;
      height: auto; } }

.product_choice_elevatorAnimation__2HXVt {
  height: 50vh;
  width: 80%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 30% 15% 1fr;
  -webkit-align-items: center;
          align-items: center;
  justify-items: center;
  position: relative; }
  .product_choice_elevatorAnimation__2HXVt h1 {
    color: #112047;
    font-family: "Alfa Slab One", cursive;
    font-size: calc(16px + 2vw);
    font-weight: 250;
    letter-spacing: 0.2vw; }
  .product_choice_elevatorAnimation__2HXVt p {
    font-family: futura-pt, sans-serif;
    font-weight: 400;
    font-size: calc(16px + 0.3vw);
    text-align: left; }

.product_choice_shaft__d9SRU {
  width: 2px;
  height: 70%;
  position: absolute;
  top: 13%;
  background-color: #979797; }
  .product_choice_shaft__d9SRU .product_choice_elevator__1SD2_ {
    position: sticky;
    position: -webkit-sticky;
    top: 40%;
    margin-left: -18px;
    margin-bottom: -10px;
    transition: 0.4s; }

a {
  text-decoration: none; }

.video_card_videoCard__33dz0 {
  height: 297px;
  display: grid;
  -webkit-align-items: center;
          align-items: center;
  justify-items: center;
  transition: 0.25s ease; }
  .video_card_videoCard__33dz0:hover {
    box-shadow: inset 0 0 0 1000px rgba(126, 211, 33, 0.8);
    transition: 0.75s ease;
    cursor: pointer; }
  .video_card_videoCard__33dz0 p {
    font-family: futura-pt, sans-serif;
    width: 50%;
    color: #ffffff;
    text-align: center;
    font-size: calc(16px + 1.5vw);
    font-weight: bold; }
    @media screen and (max-width: 768px) {
      .video_card_videoCard__33dz0 p {
        font-size: 10px; } }
  @media screen and (max-width: 768px) {
    .video_card_videoCard__33dz0 {
      height: 15vh; } }

.scroller_scrollerBar__2-W4V {
  margin: 100px 0 0 0;
  position: relative; }
  .scroller_scrollerBar__2-W4V .scroller_button__2dHie {
    position: absolute;
    background: transparent;
    z-index: 20;
    color: #ffffff;
    height: 100%;
    width: 10%;
    border: none;
    font-size: calc(16px + 0.3vw);
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }
    .scroller_scrollerBar__2-W4V .scroller_button__2dHie:first-child {
      top: 50%;
      left: 0; }
      .scroller_scrollerBar__2-W4V .scroller_button__2dHie:first-child img {
        position: absolute;
        top: 50%;
        left: 10px;
        -webkit-transform: translatey(-50%);
                transform: translatey(-50%); }
        @media (max-width: 768px) {
          .scroller_scrollerBar__2-W4V .scroller_button__2dHie:first-child img {
            display: none; } }
    .scroller_scrollerBar__2-W4V .scroller_button__2dHie:last-child {
      top: 50%;
      right: 0; }
      .scroller_scrollerBar__2-W4V .scroller_button__2dHie:last-child img {
        position: absolute;
        top: 50%;
        right: 10px;
        -webkit-transform: translatey(-50%);
                transform: translatey(-50%); }
        @media (max-width: 768px) {
          .scroller_scrollerBar__2-W4V .scroller_button__2dHie:last-child img {
            display: none; } }
    @media (max-width: 768px) {
      .scroller_scrollerBar__2-W4V .scroller_button__2dHie {
        background-color: rgba(255, 255, 255, 0.3);
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        width: 6%; }
        .scroller_scrollerBar__2-W4V .scroller_button__2dHie:after {
          text-align: center;
          color: #fff;
          display: block;
          font-size: 1.5em; }
        .scroller_scrollerBar__2-W4V .scroller_button__2dHie:first-child:after {
          content: '<'; }
        .scroller_scrollerBar__2-W4V .scroller_button__2dHie:last-child:after {
          content: '>'; } }
  @media (max-width: 768px) {
    .scroller_scrollerBar__2-W4V {
      margin: 10% 0; } }

.videos_section_videosWrapper__1U6i2 h1 {
  color: #112047;
  font-family: "Alfa Slab One", cursive;
  font-size: calc(16px + 2vw);
  font-weight: 250;
  letter-spacing: 0.2vw;
  margin: 0; }

.videos_section_videosBanner__hEraM {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  width: 80%;
  height: 2.5vw;
  margin: 50px auto;
  -webkit-align-items: center;
          align-items: center; }
  .videos_section_videosBanner__hEraM h1 {
    text-align: left;
    margin-left: 20px; }
  .videos_section_videosBanner__hEraM img {
    height: auto;
    max-width: 100%;
    width: 4vw;
    justify-self: right; }
  @media screen and (max-width: 768px) {
    .videos_section_videosBanner__hEraM {
      height: auto; } }

.custradio_radiotext__v2QqC {
  margin-left: 8px; }

[type='radio']:checked,
[type='radio']:not(:checked) {
  position: absolute;
  left: -9999px; }

[type='radio']:checked + label,
[type='radio']:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 24px;
  display: inline-block;
  color: #666; }

[type='radio']:checked + label:before,
[type='radio']:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 24px;
  height: 24px;
  border: 1px solid #9b9b9b;
  border-radius: 100%;
  background: #fff; }

[type='radio']:checked + label:after,
[type='radio']:not(:checked) + label:after {
  content: '';
  width: 18px;
  height: 18px;
  background: #7ed321;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  transition: all 0.2s ease; }

[type='radio']:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0); }

[type='radio']:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1); }

::-webkit-input-placeholder {
  color: #4a4a4a;
  font-style: italic; }
::-ms-input-placeholder {
  color: #4a4a4a;
  font-style: italic; }
::placeholder {
  color: #4a4a4a;
  font-style: italic; }

input[name='part_1']::-webkit-input-placeholder,
input[name='part_1_qty']::-webkit-input-placeholder,
input[name='part_2']::-webkit-input-placeholder,
input[name='part_2_qty']::-webkit-input-placeholder,
input[name='part_3']::-webkit-input-placeholder,
input[name='part_3_qty']::-webkit-input-placeholder,
input[name='part_4']::-webkit-input-placeholder,
input[name='part_4_qty']::-webkit-input-placeholder,
input[name='part_5']::-webkit-input-placeholder,
input[name='part_5_qty']::-webkit-input-placeholder {
  opacity: 0; }

input[name='part_1']::-ms-input-placeholder,
input[name='part_1_qty']::-ms-input-placeholder,
input[name='part_2']::-ms-input-placeholder,
input[name='part_2_qty']::-ms-input-placeholder,
input[name='part_3']::-ms-input-placeholder,
input[name='part_3_qty']::-ms-input-placeholder,
input[name='part_4']::-ms-input-placeholder,
input[name='part_4_qty']::-ms-input-placeholder,
input[name='part_5']::-ms-input-placeholder,
input[name='part_5_qty']::-ms-input-placeholder {
  opacity: 0; }

input[name='part_1']::placeholder,
input[name='part_1_qty']::placeholder,
input[name='part_2']::placeholder,
input[name='part_2_qty']::placeholder,
input[name='part_3']::placeholder,
input[name='part_3_qty']::placeholder,
input[name='part_4']::placeholder,
input[name='part_4_qty']::placeholder,
input[name='part_5']::placeholder,
input[name='part_5_qty']::placeholder {
  opacity: 0; }
  @media (max-width: 667px) {
    input[name='part_1']::-webkit-input-placeholder,
    input[name='part_1_qty']::-webkit-input-placeholder,
    input[name='part_2']::-webkit-input-placeholder,
    input[name='part_2_qty']::-webkit-input-placeholder,
    input[name='part_3']::-webkit-input-placeholder,
    input[name='part_3_qty']::-webkit-input-placeholder,
    input[name='part_4']::-webkit-input-placeholder,
    input[name='part_4_qty']::-webkit-input-placeholder,
    input[name='part_5']::-webkit-input-placeholder,
    input[name='part_5_qty']::-webkit-input-placeholder {
      opacity: 0.5; }
    input[name='part_1']::-ms-input-placeholder,
    input[name='part_1_qty']::-ms-input-placeholder,
    input[name='part_2']::-ms-input-placeholder,
    input[name='part_2_qty']::-ms-input-placeholder,
    input[name='part_3']::-ms-input-placeholder,
    input[name='part_3_qty']::-ms-input-placeholder,
    input[name='part_4']::-ms-input-placeholder,
    input[name='part_4_qty']::-ms-input-placeholder,
    input[name='part_5']::-ms-input-placeholder,
    input[name='part_5_qty']::-ms-input-placeholder {
      opacity: 0.5; }
    input[name='part_1']::placeholder,
    input[name='part_1_qty']::placeholder,
    input[name='part_2']::placeholder,
    input[name='part_2_qty']::placeholder,
    input[name='part_3']::placeholder,
    input[name='part_3_qty']::placeholder,
    input[name='part_4']::placeholder,
    input[name='part_4_qty']::placeholder,
    input[name='part_5']::placeholder,
    input[name='part_5_qty']::placeholder {
      opacity: 0.5; } }

.form_orderformcontainer__WM737 {
  padding: 100px 0 100px 0;
  position: relative; }
  .form_orderformcontainer__WM737 h1 {
    color: #112047;
    font-family: "Alfa Slab One", cursive;
    font-size: calc(16px + 2vw);
    font-weight: 250;
    letter-spacing: 0.2vw;
    margin: 0 auto;
    padding-bottom: 20px;
    text-align: center; }

.form_orderformwrapper__1jnfj {
  background-color: #ffffff;
  color: #4a4a4a;
  min-height: 200px;
  padding-bottom: 2%;
  font-size: calc(12px + 0.2vw); }
  .form_orderformwrapper__1jnfj label {
    display: inline-block; }
  .form_orderformwrapper__1jnfj .form_columnone__2fMTJ {
    padding: 20px 40px 20px 20px;
    display: grid;
    grid-column-start: 1;
    grid-template-columns: 25% 1fr 15% 1fr;
    -webkit-align-items: center;
            align-items: center;
    text-align: right; }
    @media (max-width: 1200px) {
      .form_orderformwrapper__1jnfj .form_columnone__2fMTJ {
        grid-template-rows: auto; } }
    .form_orderformwrapper__1jnfj .form_columnone__2fMTJ .form_col1__qO16q {
      grid-column-start: 1;
      text-align: right; }
    .form_orderformwrapper__1jnfj .form_columnone__2fMTJ .form_col2__2XL3h {
      grid-column-start: 2; }
    .form_orderformwrapper__1jnfj .form_columnone__2fMTJ .form_col3__tndsK {
      grid-column-start: 3;
      justify-self: right; }
    .form_orderformwrapper__1jnfj .form_columnone__2fMTJ .form_col4__1s77h {
      grid-column-start: 4; }
    .form_orderformwrapper__1jnfj .form_columnone__2fMTJ .form_long__3z2hX {
      grid-column: span 3; }
    .form_orderformwrapper__1jnfj .form_columnone__2fMTJ .form_bottompara__2fVXB {
      grid-column: span 4; }
    .form_orderformwrapper__1jnfj .form_columnone__2fMTJ label {
      margin-right: 16px; }
    @media (max-width: 667px) {
      .form_orderformwrapper__1jnfj .form_columnone__2fMTJ {
        display: block;
        text-align: left;
        padding: 20px; }
        .form_orderformwrapper__1jnfj .form_columnone__2fMTJ .form_selectorlong__3VUO_ {
          margin: 0 0 15px 0; } }
  .form_orderformwrapper__1jnfj .form_columntwo__3Rh79 {
    display: grid;
    height: 50%;
    grid-column-start: 2;
    padding: 20px 20px 20px 40px;
    text-align: center; }
    .form_orderformwrapper__1jnfj .form_columntwo__3Rh79 .form_partform__2W5n1 {
      display: grid;
      grid-template-columns: 40% 100px 1fr;
      grid-row: 1 / span 11;
      grid-gap: 8px; }
      @media (max-width: 667px) {
        .form_orderformwrapper__1jnfj .form_columntwo__3Rh79 .form_partform__2W5n1 input[type='text'] {
          width: 48%;
          margin-right: 10px; } }
      @media (max-width: 667px) {
        .form_orderformwrapper__1jnfj .form_columntwo__3Rh79 .form_partform__2W5n1 input[type='number'] {
          width: 48%; } }
      .form_orderformwrapper__1jnfj .form_columntwo__3Rh79 .form_partform__2W5n1 input[type='date'] {
        text-align: center;
        border: 1px solid #9b9b9b; }
        @media (max-width: 667px) {
          .form_orderformwrapper__1jnfj .form_columntwo__3Rh79 .form_partform__2W5n1 input[type='date'] {
            border: 1px solid #9b9b9b;
            width: 100%;
            box-sizing: border-box;
            text-align: left;
            margin-bottom: 30px; } }
      @media (max-width: 667px) {
        .form_orderformwrapper__1jnfj .form_columntwo__3Rh79 .form_partform__2W5n1 {
          display: block;
          text-align: left; }
          .form_orderformwrapper__1jnfj .form_columntwo__3Rh79 .form_partform__2W5n1 label {
            display: none; } }
    .form_orderformwrapper__1jnfj .form_columntwo__3Rh79 .form_commentfield__2ccE2 {
      height: 200px;
      font-size: calc(12px + 0.2vw);
      border: 1px solid #9b9b9b; }
    @media (max-width: 1200px) {
      .form_orderformwrapper__1jnfj .form_columntwo__3Rh79 {
        grid-column-start: 1; } }
    @media (max-width: 667px) {
      .form_orderformwrapper__1jnfj .form_columntwo__3Rh79 {
        padding: 0 20px 20px 20px; } }

.form_orderform__VPORz {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 80%;
  min-width: 300px;
  margin: 0 auto; }
  @media (max-width: 1200px) {
    .form_orderform__VPORz {
      grid-template-columns: 1fr; } }
  .form_orderform__VPORz button,
  .form_orderform__VPORz input {
    margin: 10px 0; }
  .form_orderform__VPORz input[type='text'],
  .form_orderform__VPORz input[type='email'],
  .form_orderform__VPORz input[type='tel'],
  .form_orderform__VPORz input[type='number'] {
    background: none;
    color: #4a4a4a;
    border: 1px solid #9b9b9b;
    width: 100%;
    box-sizing: border-box;
    border-radius: 0;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
    font-size: calc(12px + 0.2vw);
    min-height: 27px;
    padding: 8px;
    text-align: left; }
  .form_orderform__VPORz input[type='date'] {
    padding: 8px; }
  .form_orderform__VPORz .form_short__3Ldxs {
    grid-column-start: 2;
    grid-column-end: 3; }
  .form_orderform__VPORz .form_long__3z2hX {
    grid-column-start: 2;
    grid-column-end: end; }
  .form_orderform__VPORz .form_selectorlong__3VUO_ {
    display: -webkit-flex;
    display: flex; }
  .form_orderform__VPORz input[type='submit'] {
    margin: 10px;
    display: inline-block;
    background: #d8d8d8;
    color: #112047;
    width: 40%;
    box-sizing: border-box;
    font-size: calc(16px + 0.3vw);
    font-weight: 800;
    text-transform: uppercase;
    min-height: 27px;
    padding: 12px 25px 12px 25px;
    text-align: center;
    cursor: pointer;
    border: 0; }

.form_clearbutton__6tGl0 {
  margin: 10px;
  display: inline-block;
  background: #d8d8d8;
  color: #112047;
  width: 40%;
  box-sizing: border-box;
  font-size: calc(16px + 0.3vw);
  font-weight: 800;
  text-transform: uppercase;
  min-height: 27px;
  padding: 12px 25px 12px 25px;
  text-align: center;
  cursor: pointer;
  border: 0; }

.form_errorfield__1wPB6 {
  background: purple !important; }

.form_formmodal__3alJs {
  display: none;
  position: absolute;
  width: 90%;
  height: 100%;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  border-radius: 12px;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.8); }
  .form_formmodal__3alJs h3 {
    position: absolute;
    text-align: center;
    width: 100%;
    top: 20%;
    color: #7ed321;
    font-size: calc(16px + 1vw); }

.form_show__1aKv6 {
  display: inline !important; }

.Products_mainWindow__1S836 {
  padding: 0;
  margin: 0;
  padding-top: 55px;
  box-sizing: border-box; }

.Products_sectionWrapper__24-7K {
  background-color: transparent; }

.about_contentWrapper__12rWb {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 80%;
  margin: 25px auto 100px auto;
  -webkit-align-content: center;
          align-content: center; }
  .about_contentWrapper__12rWb * {
    margin: 0 10px; }
    @media (max-width: 768px) {
      .about_contentWrapper__12rWb * {
        margin: 0; } }
  .about_contentWrapper__12rWb p {
    font-family: futura-pt, sans-serif;
    font-weight: 400;
    font-size: calc(16px + 0.3vw);
    margin-top: 25px; }
  .about_contentWrapper__12rWb img {
    justify-self: middle;
    -webkit-align-self: middle;
            align-self: middle;
    max-width: 100%;
    height: auto;
    display: block; }
    @media (max-width: 768px) {
      .about_contentWrapper__12rWb img {
        margin: 0;
        margin: auto; } }
  .about_contentWrapper__12rWb h1 {
    font-size: calc(16px + 1vw);
    margin-top: 35px;
    font-family: futura-pt, sans-serif;
    font-weight: bold;
    color: #112047; }
  @media (max-width: 768px) {
    .about_contentWrapper__12rWb {
      display: block;
      width: 85%;
      margin: 0 auto 10% auto; } }

.newspost_postWrapper__1IV5e {
  display: grid;
  width: 80%;
  margin-top: 25px;
  margin-left: 10%;
  border-bottom: 3px solid #7ed321;
  margin-bottom: 35px; }
  .newspost_postWrapper__1IV5e h1 {
    font-family: "Alfa Slab One", cursive;
    font-weight: 400;
    color: #112047;
    font-size: calc(16px + 1vw);
    letter-spacing: 0.05em; }
  .newspost_postWrapper__1IV5e p {
    font-family: futura-pt, sans-serif;
    color: #4a4a4a;
    font-size: calc(16px + 0.3vw); }
  .newspost_postWrapper__1IV5e span {
    font-weight: bold; }
  .newspost_postWrapper__1IV5e a {
    text-decoration: none;
    color: #4a4a4a;
    font-family: futura-pt, sans-serif;
    font-size: calc(16px + 0.3vw);
    font-weight: bold;
    transition: 0.1s ease; }
    .newspost_postWrapper__1IV5e a:hover {
      color: #7ed321;
      transition: 0.1s ease; }
  .newspost_postWrapper__1IV5e:last-child {
    border-bottom: 0; }
  @media (max-width: 768px) {
    .newspost_postWrapper__1IV5e {
      width: 85%;
      margin-top: 0;
      margin-left: auto;
      margin-right: auto; } }

.newspost_postDate__1ZAru {
  font-family: futura-pt, sans-serif;
  font-size: calc(16px + 0.3vw);
  font-weight: 400;
  justify-self: right; }
  .newspost_postDate__1ZAru p:nth-of-type(2) {
    color: #112047;
    margin-top: -16px; }

.newspost_newsInnerDiv__24SPH {
  display: grid; }
  .newspost_newsInnerDiv__24SPH h1 {
    width: 90%;
    margin-top: 0; }
    @media (max-width: 768px) {
      .newspost_newsInnerDiv__24SPH h1 {
        width: 100%;
        margin-top: 25px; } }
  .newspost_newsInnerDiv__24SPH p {
    margin-top: 25px; }
  .newspost_newsInnerDiv__24SPH ul li {
    font-family: futura-pt, sans-serif;
    font-size: calc(16px + 0.3vw);
    font-weight: 400;
    color: #4a4a4a; }
  .newspost_newsInnerDiv__24SPH img {
    max-width: 100%;
    height: auto;
    display: block; }
    @media (max-width: 768px) {
      .newspost_newsInnerDiv__24SPH img {
        margin: auto; } }
  .newspost_newsInnerDiv__24SPH div:first-of-type {
    grid-column: 1 / span 2;
    margin-top: 30px; }
  .newspost_newsInnerDiv__24SPH.newspost_imageRight__1cKGY {
    grid-template-columns: 1fr 40%; }
    .newspost_newsInnerDiv__24SPH.newspost_imageRight__1cKGY img {
      grid-column: 2 / end;
      justify-self: right; }
    .newspost_newsInnerDiv__24SPH.newspost_imageRight__1cKGY h1 {
      grid-column: 1 / 2;
      grid-row: 1; }
    @media (max-width: 768px) {
      .newspost_newsInnerDiv__24SPH.newspost_imageRight__1cKGY {
        display: block; } }
  .newspost_newsInnerDiv__24SPH.newspost_imageLeft__1ElxW {
    grid-template-columns: 40% 1fr; }
    .newspost_newsInnerDiv__24SPH.newspost_imageLeft__1ElxW img {
      grid-column: 1 / 2; }
    .newspost_newsInnerDiv__24SPH.newspost_imageLeft__1ElxW h1 {
      justify-self: right; }
    @media (max-width: 768px) {
      .newspost_newsInnerDiv__24SPH.newspost_imageLeft__1ElxW {
        display: block; } }

@media (max-width: 768px) {
  .news_sectionCard__1dB1B {
    margin: 0 auto 10% auto; } }

.history_contentWrapper__10To7 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 80%;
  margin: 25px auto 100px auto;
  -webkit-align-content: center;
          align-content: center; }
  .history_contentWrapper__10To7 * {
    margin: 0 10px; }
    @media (max-width: 768px) {
      .history_contentWrapper__10To7 * {
        margin: 0; } }
  .history_contentWrapper__10To7 p {
    font-family: futura-pt, sans-serif;
    font-weight: 400;
    font-size: calc(16px + 0.3vw);
    margin-top: 25px; }
  .history_contentWrapper__10To7 img {
    justify-self: middle;
    -webkit-align-self: middle;
            align-self: middle;
    max-width: 100%;
    height: auto;
    display: block; }
    @media (max-width: 768px) {
      .history_contentWrapper__10To7 img {
        margin: auto; } }
  .history_contentWrapper__10To7 h1 {
    font-size: calc(16px + 1vw);
    margin-top: 35px;
    margin-bottom: 25px;
    font-family: futura-pt, sans-serif;
    font-weight: bold;
    color: #112047; }
  @media (max-width: 768px) {
    .history_contentWrapper__10To7 {
      display: block;
      width: 85%;
      margin: 0 auto 10% auto; } }

.team_contentWrapper__3GqCV {
  display: grid;
  grid-template-columns: 1fr;
  width: 80%;
  margin: 25px auto 100px auto;
  -webkit-align-content: center;
          align-content: center; }
  .team_contentWrapper__3GqCV * {
    margin: 0 10px; }
    @media (max-width: 768px) {
      .team_contentWrapper__3GqCV * {
        margin: 0; } }
  .team_contentWrapper__3GqCV p {
    font-family: futura-pt, sans-serif;
    font-weight: 400;
    font-size: calc(16px + 0.3vw);
    text-align: center;
    margin-top: 25px; }
  .team_contentWrapper__3GqCV img {
    justify-self: center;
    -webkit-align-self: center;
            align-self: center;
    max-width: 100%;
    height: auto;
    display: block; }
  @media (max-width: 768px) {
    .team_contentWrapper__3GqCV {
      display: block;
      width: 85%;
      margin: 0 auto 10% auto; } }

@-webkit-keyframes AboutUs_elevatorLeft__j-8wL {
  to {
    -webkit-transform: translateX(-54%);
            transform: translateX(-54%); } }
@keyframes AboutUs_elevatorLeft__j-8wL {
  to {
    -webkit-transform: translateX(-54%);
            transform: translateX(-54%); } }

@-webkit-keyframes AboutUs_elevatorLeftMobile__3WOU4 {
  to {
    -webkit-transform: translateX(-75%);
            transform: translateX(-75%); } }

@keyframes AboutUs_elevatorLeftMobile__3WOU4 {
  to {
    -webkit-transform: translateX(-75%);
            transform: translateX(-75%); } }

@-webkit-keyframes AboutUs_elevatorRight__2-cNQ {
  to {
    -webkit-transform: translateX(54%);
            transform: translateX(54%); } }

@keyframes AboutUs_elevatorRight__2-cNQ {
  to {
    -webkit-transform: translateX(54%);
            transform: translateX(54%); } }

@-webkit-keyframes AboutUs_elevatorRightMobile__22V8o {
  to {
    -webkit-transform: translateX(75%);
            transform: translateX(75%); } }

@keyframes AboutUs_elevatorRightMobile__22V8o {
  to {
    -webkit-transform: translateX(75%);
            transform: translateX(75%); } }

@-webkit-keyframes AboutUs_textZoom__1-0Dn {
  to { } }

@keyframes AboutUs_textZoom__1-0Dn {
  to { } }

.AboutUs_selected__5gcqM {
  color: #7ed321;
  letter-spacing: 1px; }

.AboutUs_mainWindow__fkH1D ul li {
  font-family: futura-pt, sans-serif;
  font-weight: 400;
  color: #4a4a4a;
  font-size: calc(16px + 0.3vw);
  padding: 3px 0px; }

.AboutUs_fullElevator__2d1v9 {
  margin-top: 110px;
  width: 100%;
  height: 150px;
  overflow: hidden;
  background-color: white; }
  .AboutUs_fullElevator__2d1v9 .AboutUs_menuWrapper__3dsBu {
    width: 100%;
    height: 100%; }
  @media (max-width: 768px) {
    .AboutUs_fullElevator__2d1v9 {
      height: 8vh;
      padding-bottom: 30px; } }

.AboutUs_leftDoor__3WMi7,
.AboutUs_rightDoor__2hz6T {
  width: 50%;
  height: 8vh;
  float: left;
  position: relative;
  z-index: 2; }

.AboutUs_leftDoor__3WMi7 {
  background-color: white;
  -webkit-animation-name: AboutUs_elevatorLeft__j-8wL;
          animation-name: AboutUs_elevatorLeft__j-8wL;
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
  -webkit-animation-timing-function: ease;
          animation-timing-function: ease;
  -webkit-animation-delay: 1.5s;
          animation-delay: 1.5s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-direction: normal;
          animation-direction: normal;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-play-state: running;
          animation-play-state: running; }
  .AboutUs_leftDoor__3WMi7:after {
    content: ' ';
    background-color: transparent;
    width: 5vw;
    height: 98%;
    border-top: 1px solid black;
    border-left: 1px solid black;
    border-bottom: 1px solid black;
    z-index: 4;
    position: absolute;
    right: 0;
    top: 0; }
  @media (max-width: 768px) {
    .AboutUs_leftDoor__3WMi7 {
      -webkit-animation-name: AboutUs_elevatorLeftMobile__3WOU4;
              animation-name: AboutUs_elevatorLeftMobile__3WOU4; } }

.AboutUs_rightDoor__2hz6T {
  background-color: white;
  -webkit-animation-name: AboutUs_elevatorRight__2-cNQ;
          animation-name: AboutUs_elevatorRight__2-cNQ;
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
  -webkit-animation-timing-function: ease;
          animation-timing-function: ease;
  -webkit-animation-delay: 1.5s;
          animation-delay: 1.5s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-direction: normal;
          animation-direction: normal;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-play-state: running;
          animation-play-state: running; }
  .AboutUs_rightDoor__2hz6T:before {
    content: ' ';
    background-color: transparent;
    width: 5vw;
    height: 98%;
    border-top: 1px solid black;
    border-right: 1px solid black;
    border-bottom: 1px solid black;
    z-index: 4;
    position: absolute;
    left: 0;
    top: 0; }
  @media (max-width: 768px) {
    .AboutUs_rightDoor__2hz6T {
      -webkit-animation-name: AboutUs_elevatorRightMobile__22V8o;
              animation-name: AboutUs_elevatorRightMobile__22V8o; } }

.AboutUs_menuBar__2Dxqf {
  position: absolute;
  z-index: 1;
  width: 60%;
  height: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-rows: 8vh;
  -webkit-align-items: center;
          align-items: center;
  justify-items: center;
  -webkit-animation-name: AboutUs_textZoom__1-0Dn;
          animation-name: AboutUs_textZoom__1-0Dn;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-timing-function: ease;
          animation-timing-function: ease;
  -webkit-animation-delay: 5.5s;
          animation-delay: 5.5s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-direction: normal;
          animation-direction: normal;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-play-state: running;
          animation-play-state: running; }
  .AboutUs_menuBar__2Dxqf .AboutUs_menuItem__bz5mG {
    font-family: "Alfa Slab One", cursive;
    color: #112047;
    font-size: calc(16px + 1vw);
    transition: ease 0.25s; }
    .AboutUs_menuBar__2Dxqf .AboutUs_menuItem__bz5mG:hover {
      transition: ease 0.25s;
      color: #7ed321;
      letter-spacing: 1px; }
    @media (max-width: 768px) {
      .AboutUs_menuBar__2Dxqf .AboutUs_menuItem__bz5mG {
        font-size: 0.9em; } }
  @media (max-width: 768px) {
    .AboutUs_menuBar__2Dxqf {
      width: 80%; } }

.tool_topper_topper__2JQWE {
  min-height: 35vh;
  display: grid;
  -webkit-align-items: center;
          align-items: center;
  border-bottom: 4px solid white; }

.tool_topper_textBlock__FHwmt {
  width: 70%;
  margin: 0 auto;
  display: grid;
  justify-items: center; }
  .tool_topper_textBlock__FHwmt h1,
  .tool_topper_textBlock__FHwmt p {
    color: #ffffff;
    text-align: center; }
  .tool_topper_textBlock__FHwmt h1 {
    font-family: "Alfa Slab One", cursive;
    font-size: calc(16px + 2vw);
    font-weight: 250;
    letter-spacing: 0.2vw;
    margin: 0; }
  .tool_topper_textBlock__FHwmt p {
    font-family: futura-pt, sans-serif;
    font-weight: 400;
    width: 60%;
    font-size: calc(16px + 0.3vw); }

.multi_image_modal_imageWrapper__eJOyQ {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }
  .multi_image_modal_imageWrapper__eJOyQ .multi_image_modal_innerWrapper__dSica {
    position: relative;
    background-color: white;
    max-width: 80vw;
    max-height: 80vh;
    overflow: hidden;
    padding: 20px;
    box-sizing: border-box; }
    .multi_image_modal_imageWrapper__eJOyQ .multi_image_modal_innerWrapper__dSica .multi_image_modal_modalSelect__2Si1v {
      display: -webkit-flex;
      display: flex;
      padding-top: 20px; }
      .multi_image_modal_imageWrapper__eJOyQ .multi_image_modal_innerWrapper__dSica .multi_image_modal_modalSelect__2Si1v img {
        max-width: 200px;
        min-height: auto;
        height: auto;
        display: block;
        -webkit-filter: none;
                filter: none; }
        .multi_image_modal_imageWrapper__eJOyQ .multi_image_modal_innerWrapper__dSica .multi_image_modal_modalSelect__2Si1v img:hover {
          opacity: 0.8; }
      .multi_image_modal_imageWrapper__eJOyQ .multi_image_modal_innerWrapper__dSica .multi_image_modal_modalSelect__2Si1v div {
        padding-right: 20px; }
        .multi_image_modal_imageWrapper__eJOyQ .multi_image_modal_innerWrapper__dSica .multi_image_modal_modalSelect__2Si1v div:last-child {
          padding-right: 0; }
    .multi_image_modal_imageWrapper__eJOyQ .multi_image_modal_innerWrapper__dSica .multi_image_modal_modalImg__tsgzZ {
      height: auto;
      max-height: 50vh;
      width: auto;
      max-width: 100%;
      margin: auto;
      display: -webkit-flex;
      display: flex;
      justify-self: center;
      -webkit-align-self: center;
              align-self: center;
      -webkit-filter: none;
              filter: none; }
    .multi_image_modal_imageWrapper__eJOyQ .multi_image_modal_innerWrapper__dSica .multi_image_modal_selectImage__1qZyA {
      z-index: 500; }

.multi_image_modal_backButton__hzLcb {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 25px;
  height: 25px;
  color: #112047;
  background: #fff; }
  .multi_image_modal_backButton__hzLcb img {
    width: 100%;
    height: auto !important;
    min-height: auto !important; }
  @media screen and (max-width: 768px) {
    .multi_image_modal_backButton__hzLcb {
      display: none; } }

.tool_card_cardLayout__2cWab {
  overflow-x: hidden;
  position: relative;
  display: grid;
  -webkit-align-items: center;
          align-items: center;
  height: 100%; }
  .tool_card_cardLayout__2cWab h1 {
    color: #ffffff;
    font-family: "Alfa Slab One", cursive;
    font-size: calc(16px + 2vw);
    font-weight: 250;
    letter-spacing: 0.2vw;
    z-index: 10;
    width: 80%;
    margin: 0;
    padding: 5%;
    word-wrap: break-word; }
    @media screen and (max-width: 768px) {
      .tool_card_cardLayout__2cWab h1 {
        position: absolute !important;
        top: 5%;
        right: 0; } }
  .tool_card_cardLayout__2cWab ul,
  .tool_card_cardLayout__2cWab ol {
    -webkit-columns: 1;
            columns: 1;
    margin: 0 0 20px 0; }
  .tool_card_cardLayout__2cWab p,
  .tool_card_cardLayout__2cWab code,
  .tool_card_cardLayout__2cWab ul li,
  .tool_card_cardLayout__2cWab ol li {
    font-family: futura-pt, sans-serif;
    color: #ffffff;
    text-align: left;
    font-size: calc(16px + 0.3vw);
    font-weight: 400;
    margin: 0 0 10px 0;
    padding: 0 5% 0 0; }
  .tool_card_cardLayout__2cWab img {
    -webkit-filter: brightness(65%);
            filter: brightness(65%);
    height: 100%;
    width: 100%;
    min-height: 33vh;
    object-fit: cover;
    max-width: 33vw;
    justify-self: left; }
    @media screen and (max-width: 768px) {
      .tool_card_cardLayout__2cWab img {
        height: 20vh;
        max-width: none;
        max-width: initial; } }
    @media not all and (min-resolution: 0.001dpcm) {
      .tool_card_cardLayout__2cWab img {
        height: auto;
        min-height: 50vh; } }
  .tool_card_cardLayout__2cWab p img {
    -webkit-filter: none;
            filter: none; }
    @media screen and (max-width: 768px) {
      .tool_card_cardLayout__2cWab p img {
        height: auto; } }
  @media screen and (max-width: 768px) {
    .tool_card_cardLayout__2cWab {
      display: block;
      position: relative; } }

.tool_card_image_left__2lXAQ {
  grid-template-columns: 1fr 70%;
  justify-items: end; }
  .tool_card_image_left__2lXAQ img {
    grid-column-start: 1;
    grid-row-start: 1; }
  .tool_card_image_left__2lXAQ h1 {
    grid-column-start: 1;
    grid-row-start: 1;
    border-top: 3px solid #ffffff;
    border-left: 3px solid #ffffff;
    position: relative; }
    .tool_card_image_left__2lXAQ h1:before {
      content: '';
      height: 3px;
      width: 40%;
      background: #fff;
      position: absolute;
      left: 0;
      bottom: 0; }

.tool_card_image_right__Hp6Uj {
  grid-template-columns: 70% 1fr;
  justify-items: end; }
  .tool_card_image_right__Hp6Uj img {
    grid-column-start: 2;
    grid-row-start: 1; }
  .tool_card_image_right__Hp6Uj h1 {
    grid-column-start: 2;
    grid-row-start: 1;
    border-left: 3px solid #ffffff;
    border-bottom: 3px solid #ffffff;
    position: relative; }
    .tool_card_image_right__Hp6Uj h1:before {
      content: '';
      height: 3px;
      width: 50%;
      background: #fff;
      position: absolute;
      left: 0;
      top: 0; }

.tool_card_white__1QI_f {
  background-color: #ffffff; }
  .tool_card_white__1QI_f p,
  .tool_card_white__1QI_f code,
  .tool_card_white__1QI_f ul li {
    color: black; }

.tool_card_blue__1hwS5 {
  background-color: #112047;
  color: #ffffff; }
  .tool_card_blue__1hwS5 .tool_card_textField__3wptm table th {
    color: #ffffff; }

.tool_card_textField__3wptm {
  padding: 5%;
  position: relative; }
  .tool_card_textField__3wptm span {
    color: #7ed321;
    font-weight: bold; }
  .tool_card_textField__3wptm p:last-of-type {
    position: relative; }
  .tool_card_textField__3wptm table {
    width: 80%;
    margin: 0 auto;
    text-align: center; }
    .tool_card_textField__3wptm table th {
      color: #112047;
      font-family: futura-pt, sans-serif;
      font-weight: bold;
      font-size: calc(16px + 0.3vw);
      border-bottom: 2px solid #7ed321; }
    .tool_card_textField__3wptm table th:not(:last-of-type) {
      border-right: 2px solid #7ed321; }
    .tool_card_textField__3wptm table tr:not(:last-of-type) td {
      padding: 5px;
      border-bottom: 2px solid #7ed321; }
    .tool_card_textField__3wptm table tr td:not(:last-of-type) {
      border-right: 2px solid #7ed321; }

.tool_card_expandButton__2-4PC {
  position: absolute;
  top: 15px;
  left: 15px;
  width: 25px;
  height: 25px; }
  .tool_card_expandButton__2-4PC img {
    position: relative;
    top: 2px;
    -webkit-filter: none;
            filter: none;
    width: 100%;
    min-height: auto; }
  @media screen and (max-width: 768px) {
    .tool_card_expandButton__2-4PC {
      display: none; } }

.tool_card_expand_left__1j1gP {
  grid-column: 1;
  grid-row: 1; }

.tool_card_expand_right__nAH7t {
  grid-column: 2;
  grid-row: 1; }

.tool_selection_toolMenu__36Csb {
  background-color: #112047; }
  .tool_selection_toolMenu__36Csb ul {
    height: 15vh;
    width: 100%;
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: -webkit-flex;
    display: flex;
    justify-items: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly; }
    .tool_selection_toolMenu__36Csb ul li {
      box-sizing: border-box;
      font-family: futura-pt, sans-serif;
      font-size: calc(16px + 0.3vw);
      font-weight: bold;
      text-align: center;
      padding: 0 1%;
      box-sizing: border-box;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content; }
      .tool_selection_toolMenu__36Csb ul li a {
        text-decoration: none;
        color: #ffffff; }
        .tool_selection_toolMenu__36Csb ul li a:hover {
          color: #7ed321;
          transition: ease 0s; }
      @media screen and (max-width: 1200px) {
        .tool_selection_toolMenu__36Csb ul li {
          width: 33%;
          display: inline-block;
          vertical-align: top;
          padding: 1%; } }
      @media screen and (max-width: 768px) {
        .tool_selection_toolMenu__36Csb ul li {
          width: 50%;
          padding: 2%; } }
    @media screen and (max-width: 1200px) {
      .tool_selection_toolMenu__36Csb ul {
        display: block;
        height: auto;
        padding: 5%; } }

html {
  scroll-behavior: smooth; }

.Tools_mainWindow__29v5R {
  margin-top: 55px; }

.Policy_contactWrapper__2x1Eg {
  height: 100vh;
  padding-top: 55px;
  overflow: scroll; }
  .Policy_contactWrapper__2x1Eg h1 {
    color: #ffffff;
    font-family: "Alfa Slab One", cursive;
    font-size: calc(16px + 2vw);
    font-weight: 250;
    letter-spacing: 0.05em;
    margin-left: 100px;
    margin-top: 125px; }
  @media (max-width: 768px) {
    .Policy_contactWrapper__2x1Eg {
      display: block;
      position: relative;
      height: auto;
      margin: 0; } }

.Policy_internalText__1_cOX {
  width: 80%;
  margin: 100px auto;
  font-family: futura-pt, sans-serif;
  font-size: calc(16px + 0.3vw); }
  .Policy_internalText__1_cOX a {
    margin-left: 8px;
    color: #7ed321;
    font-weight: bold; }

.topper_topper__1dND0 {
  min-height: 25vh;
  display: grid;
  -webkit-align-items: center;
          align-items: center;
  border-bottom: 4px solid white;
  grid-column: 1 / span 2; }
  @media (max-width: 768px) {
    .topper_topper__1dND0 {
      min-height: 30vh; } }

.topper_textBlock__1cxTu {
  width: 70%;
  margin: 0 auto;
  display: grid;
  justify-items: center; }
  .topper_textBlock__1cxTu h1,
  .topper_textBlock__1cxTu p {
    color: #ffffff;
    text-align: center; }
  .topper_textBlock__1cxTu h1 {
    font-family: "Alfa Slab One", cursive;
    font-size: calc(16px + 2vw);
    font-weight: 250;
    letter-spacing: 0.2vw;
    margin: 0; }
  .topper_textBlock__1cxTu p {
    font-family: futura-pt, sans-serif;
    font-weight: 400;
    width: 60%;
    font-size: calc(16px + 1vw);
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5); }
    @media (max-width: 768px) {
      .topper_textBlock__1cxTu p {
        width: 90%; } }
  @media (max-width: 768px) {
    .topper_textBlock__1cxTu {
      width: 90%; } }

.cylnotes_collapseData__31638 {
  padding: 0;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: grid;
  grid-template-rows: 35px 1fr;
  -webkit-align-items: center;
          align-items: center;
  justify-items: center;
  border-top: 1px solid grey; }
  .cylnotes_collapseData__31638:first-of-type {
    margin-top: 0; }
  .cylnotes_collapseData__31638 h3 {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    text-align: center; }
    .cylnotes_collapseData__31638 h3:hover {
      color: white;
      background-color: #349800; }

.cylnotes_dataDiv__HSB5C {
  width: 90%; }
  .cylnotes_dataDiv__HSB5C table,
  .cylnotes_dataDiv__HSB5C ul {
    width: 90%;
    margin-top: 20px; }
  .cylnotes_dataDiv__HSB5C p,
  .cylnotes_dataDiv__HSB5C ul li,
  .cylnotes_dataDiv__HSB5C td {
    font-family: futura-pt, sans-serif;
    font-weight: 400;
    font-size: calc(16px + 0.3vw);
    margin: 0; }
    .cylnotes_dataDiv__HSB5C p img,
    .cylnotes_dataDiv__HSB5C ul li img,
    .cylnotes_dataDiv__HSB5C td img {
      display: block;
      margin-left: auto;
      margin-right: auto; }
  .cylnotes_dataDiv__HSB5C th {
    font-family: futura-pt, sans-serif;
    font-weight: bold;
    font-size: calc(16px + 0.3vw);
    padding: 0;
    margin: 0;
    text-align: left; }
  .cylnotes_dataDiv__HSB5C .cylnotes_contentImages__1u5lq {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    margin-top: 20px;
    -webkit-align-items: center;
            align-items: center;
    justify-items: center; }
    .cylnotes_dataDiv__HSB5C .cylnotes_contentImages__1u5lq img {
      width: 150px; }
    .cylnotes_dataDiv__HSB5C .cylnotes_contentImages__1u5lq p {
      text-align: center; }

.cylnotes_arrowOpen__3t1yE:after {
  content: '\25B2';
  font-size: 12px;
  padding-left: 20px; }

.cylnotes_arrowClose__3_wFT:after {
  content: '\25BC';
  font-size: 12px;
  padding-left: 20px; }

.packinfo_collapseData__1fYZX {
  padding: 0;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: grid;
  grid-template-rows: 35px 1fr;
  -webkit-align-items: center;
          align-items: center;
  justify-items: center;
  border-top: 1px solid grey; }
  .packinfo_collapseData__1fYZX:first-of-type {
    margin-top: 0; }
  .packinfo_collapseData__1fYZX h3 {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    text-align: center; }
    .packinfo_collapseData__1fYZX h3:hover {
      color: white;
      background-color: #349800; }

.packinfo_dataDiv__fGgJ- {
  width: 90%; }
  .packinfo_dataDiv__fGgJ- table,
  .packinfo_dataDiv__fGgJ- ul {
    width: 90%;
    margin: 20px;
    text-align: left;
    border: 1px solid grey;
    border-collapse: collapse; }
  .packinfo_dataDiv__fGgJ- p,
  .packinfo_dataDiv__fGgJ- ul li,
  .packinfo_dataDiv__fGgJ- td {
    font-family: futura-pt, sans-serif;
    font-weight: 400;
    font-size: calc(16px + 0.3vw);
    padding: 0;
    border: 1px solid grey;
    text-align: center;
    border-spacing: 0; }
    .packinfo_dataDiv__fGgJ- p img,
    .packinfo_dataDiv__fGgJ- ul li img,
    .packinfo_dataDiv__fGgJ- td img {
      display: block;
      margin-left: auto;
      margin-right: auto; }
  .packinfo_dataDiv__fGgJ- th {
    font-family: futura-pt, sans-serif;
    font-weight: bold;
    font-size: calc(16px + 0.3vw);
    padding: 0;
    padding-bottom: 5px;
    margin: 0;
    text-align: center; }
  .packinfo_dataDiv__fGgJ- .packinfo_contentImages__26n_F {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    margin-top: 20px;
    -webkit-align-items: center;
            align-items: center;
    justify-items: center; }
    .packinfo_dataDiv__fGgJ- .packinfo_contentImages__26n_F img {
      width: 150px; }
    .packinfo_dataDiv__fGgJ- .packinfo_contentImages__26n_F p {
      text-align: center; }

.packinfo_arrowOpen__39Ho2:after {
  content: '\25B2';
  font-size: 12px;
  padding-left: 20px; }

.packinfo_arrowClose__3iRhZ:after {
  content: '\25BC';
  font-size: 12px;
  padding-left: 20px; }

.packnotes_collapseData__2XD3L {
  padding: 0;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: grid;
  grid-template-rows: 35px 1fr;
  -webkit-align-items: center;
          align-items: center;
  justify-items: center;
  border-top: 1px solid grey; }
  .packnotes_collapseData__2XD3L:first-of-type {
    margin-top: 0; }
  .packnotes_collapseData__2XD3L h3 {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    text-align: center; }
    .packnotes_collapseData__2XD3L h3:hover {
      color: white;
      background-color: #349800; }

.packnotes_collapsed__tJS6h {
  grid-template-rows: 35px !important; }

.packnotes_dataDiv__1PdUS {
  width: 90%; }
  .packnotes_dataDiv__1PdUS table,
  .packnotes_dataDiv__1PdUS ul {
    width: 90%;
    margin: 20px; }
  .packnotes_dataDiv__1PdUS p,
  .packnotes_dataDiv__1PdUS ul li,
  .packnotes_dataDiv__1PdUS td {
    font-family: futura-pt, sans-serif;
    font-weight: 400;
    font-size: calc(16px + 0.3vw);
    margin: 0; }
    .packnotes_dataDiv__1PdUS p img,
    .packnotes_dataDiv__1PdUS ul li img,
    .packnotes_dataDiv__1PdUS td img {
      display: block;
      margin-left: auto;
      margin-right: auto; }
  .packnotes_dataDiv__1PdUS th {
    font-family: futura-pt, sans-serif;
    font-weight: bold;
    font-size: calc(16px + 0.3vw);
    padding: 0;
    margin: 0;
    text-align: left; }
  .packnotes_dataDiv__1PdUS .packnotes_contentImages__2P54A {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    margin-top: 20px;
    -webkit-align-items: center;
            align-items: center;
    justify-items: center; }
    .packnotes_dataDiv__1PdUS .packnotes_contentImages__2P54A img {
      width: 150px; }
    .packnotes_dataDiv__1PdUS .packnotes_contentImages__2P54A p {
      text-align: center; }

.packnotes_arrowOpen__yhfXb:after {
  content: '\25B2';
  font-size: 12px;
  padding-left: 20px; }

.packnotes_arrowClose__2hjfG:after {
  content: '\25BC';
  font-size: 12px;
  padding-left: 20px; }

.headnotes_collapseData__2sLry {
  padding: 0;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: grid;
  grid-template-rows: 35px 1fr;
  -webkit-align-items: center;
          align-items: center;
  justify-items: center;
  border-top: 1px solid grey; }
  .headnotes_collapseData__2sLry:first-of-type {
    margin-top: 0; }
  .headnotes_collapseData__2sLry h3 {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    text-align: center; }
    .headnotes_collapseData__2sLry h3:hover {
      color: white;
      background-color: #349800; }

.headnotes_dataDiv__2-idv {
  width: 90%; }
  .headnotes_dataDiv__2-idv table,
  .headnotes_dataDiv__2-idv ul {
    width: 90%;
    margin-top: 20px; }
  .headnotes_dataDiv__2-idv p,
  .headnotes_dataDiv__2-idv ul li,
  .headnotes_dataDiv__2-idv td {
    font-family: futura-pt, sans-serif;
    font-weight: 400;
    font-size: calc(16px + 0.3vw);
    margin-left: 20px; }
    .headnotes_dataDiv__2-idv p img,
    .headnotes_dataDiv__2-idv ul li img,
    .headnotes_dataDiv__2-idv td img {
      display: block;
      margin-left: auto;
      margin-right: auto; }
  .headnotes_dataDiv__2-idv th {
    font-family: futura-pt, sans-serif;
    font-weight: bold;
    font-size: calc(16px + 0.3vw);
    padding: 0;
    margin: 0;
    text-align: left; }
  .headnotes_dataDiv__2-idv .headnotes_contentImages__3ryzB {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    margin-top: 20px;
    -webkit-align-items: center;
            align-items: center;
    justify-items: center; }
    .headnotes_dataDiv__2-idv .headnotes_contentImages__3ryzB img {
      width: 150px; }
    .headnotes_dataDiv__2-idv .headnotes_contentImages__3ryzB p {
      text-align: center; }

.headnotes_arrowOpen__2KBtz:after {
  content: '\25B2';
  font-size: 12px;
  padding-left: 20px; }

.headnotes_arrowClose__2dKdn:after {
  content: '\25BC';
  font-size: 12px;
  padding-left: 20px; }

.packcont_collapseData__2XsRB {
  padding: 0;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: grid;
  grid-template-rows: 35px 1fr;
  -webkit-align-items: center;
          align-items: center;
  justify-items: center;
  border-top: 1px solid grey; }
  .packcont_collapseData__2XsRB:first-of-type {
    margin-top: 0; }
  .packcont_collapseData__2XsRB h3 {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    text-align: center; }
    .packcont_collapseData__2XsRB h3:hover {
      color: white;
      background-color: #349800; }

.packcont_dataDiv__3gybz {
  width: 55%; }
  .packcont_dataDiv__3gybz table,
  .packcont_dataDiv__3gybz ul {
    width: 90%;
    margin: 20px; }
  .packcont_dataDiv__3gybz p,
  .packcont_dataDiv__3gybz ul li,
  .packcont_dataDiv__3gybz td {
    font-family: futura-pt, sans-serif;
    font-weight: 400;
    font-size: calc(16px + 0.3vw);
    margin: 0; }
    .packcont_dataDiv__3gybz p img,
    .packcont_dataDiv__3gybz ul li img,
    .packcont_dataDiv__3gybz td img {
      display: block;
      margin-left: auto;
      margin-right: auto; }
  .packcont_dataDiv__3gybz th {
    font-family: futura-pt, sans-serif;
    font-weight: bold;
    font-size: calc(16px + 0.3vw);
    padding: 0;
    margin: 0;
    text-align: left; }
  .packcont_dataDiv__3gybz .packcont_contentImages__qI1Y- {
    width: 100%;
    margin-top: 20px;
    -webkit-align-items: center;
            align-items: center;
    justify-items: center; }
    .packcont_dataDiv__3gybz .packcont_contentImages__qI1Y- div {
      display: grid;
      width: 30%;
      margin: 0 auto;
      -webkit-align-items: center;
              align-items: center;
      justify-items: center; }
    .packcont_dataDiv__3gybz .packcont_contentImages__qI1Y- img {
      justify-self: center;
      width: 100%;
      margin-bottom: 15px; }
    .packcont_dataDiv__3gybz .packcont_contentImages__qI1Y- p {
      justify-self: center;
      text-align: center;
      padding-bottom: 20px; }

.packcont_arrowOpen__1EVbR:after {
  content: '\25B2';
  font-size: 12px;
  padding-left: 20px; }

.packcont_arrowClose__owt-z:after {
  content: '\25BC';
  font-size: 12px;
  padding-left: 20px; }

.packalt_collapseData__3npgH {
  padding: 0;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: grid;
  grid-template-rows: 35px 1fr;
  -webkit-align-items: center;
          align-items: center;
  justify-items: center;
  border-top: 1px solid grey; }
  .packalt_collapseData__3npgH:first-of-type {
    margin-top: 0; }
  .packalt_collapseData__3npgH h3 {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    text-align: center; }
    .packalt_collapseData__3npgH h3:hover {
      color: white;
      background-color: #349800; }

.packalt_dataDiv__2VdtU {
  width: 90%; }
  .packalt_dataDiv__2VdtU table,
  .packalt_dataDiv__2VdtU ul {
    width: 90%;
    margin-top: 20px;
    margin: 20px; }
  .packalt_dataDiv__2VdtU p,
  .packalt_dataDiv__2VdtU ul li,
  .packalt_dataDiv__2VdtU td {
    font-family: futura-pt, sans-serif;
    font-weight: 400;
    font-size: calc(16px + 0.3vw);
    margin: 0; }
    .packalt_dataDiv__2VdtU p img,
    .packalt_dataDiv__2VdtU ul li img,
    .packalt_dataDiv__2VdtU td img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      padding-right: 40px; }
  .packalt_dataDiv__2VdtU th {
    font-family: futura-pt, sans-serif;
    font-weight: bold;
    font-size: calc(16px + 0.3vw);
    padding: 0;
    margin: 0;
    text-align: left; }
  .packalt_dataDiv__2VdtU .packalt_contentImages__1Y3ju {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    margin-top: 20px;
    -webkit-align-items: center;
            align-items: center;
    justify-items: center; }
    .packalt_dataDiv__2VdtU .packalt_contentImages__1Y3ju img {
      width: 150px; }
    .packalt_dataDiv__2VdtU .packalt_contentImages__1Y3ju p {
      text-align: center; }

.packalt_arrowOpen__3-hbx:after {
  content: '\25B2';
  font-size: 12px;
  padding-left: 20px; }

.packalt_arrowClose__2Nn4Y:after {
  content: '\25BC';
  font-size: 12px;
  padding-left: 20px; }

.locations_collapseData__vJd0- {
  padding: 0;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: grid;
  grid-template-rows: 35px 1fr;
  -webkit-align-items: center;
          align-items: center;
  justify-items: center;
  border-top: 1px solid grey; }
  .locations_collapseData__vJd0-:first-of-type {
    margin-top: 0; }
  .locations_collapseData__vJd0- h3 {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    text-align: center; }
    .locations_collapseData__vJd0- h3:hover {
      color: white;
      background-color: #349800; }

.locations_dataDiv__JyfF2 {
  width: 90%;
  display: grid;
  justify-items: center;
  padding: 15px; }
  .locations_dataDiv__JyfF2 table,
  .locations_dataDiv__JyfF2 ul {
    width: 90%;
    margin: 20px; }
  .locations_dataDiv__JyfF2 p,
  .locations_dataDiv__JyfF2 ul li,
  .locations_dataDiv__JyfF2 td {
    font-family: futura-pt, sans-serif;
    font-weight: 400;
    font-size: calc(16px + 0.3vw);
    margin: 0; }
    .locations_dataDiv__JyfF2 p img,
    .locations_dataDiv__JyfF2 ul li img,
    .locations_dataDiv__JyfF2 td img {
      display: block;
      margin-left: auto;
      margin-right: auto; }
  .locations_dataDiv__JyfF2 th {
    font-family: futura-pt, sans-serif;
    font-weight: bold;
    font-size: calc(16px + 0.3vw);
    padding: 0;
    margin: 0;
    text-align: left; }
  .locations_dataDiv__JyfF2 .locations_contentImages__25FZz {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    margin-top: 20px;
    -webkit-align-items: center;
            align-items: center;
    justify-items: center; }
    .locations_dataDiv__JyfF2 .locations_contentImages__25FZz img {
      width: 150px; }
    .locations_dataDiv__JyfF2 .locations_contentImages__25FZz p {
      text-align: center; }

.locations_arrowOpen__1VK3L:after {
  content: '\25B2';
  font-size: 12px;
  padding-left: 20px; }

.locations_arrowClose__1h3Ge:after {
  content: '\25BC';
  font-size: 12px;
  padding-left: 20px; }

.locations_locMap__3WFPK {
  width: 300px; }

.other_collapseData__TY6QH {
  padding: 0;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: grid;
  grid-template-rows: 35px 1fr;
  -webkit-align-items: center;
          align-items: center;
  justify-items: center;
  border-top: 1px solid grey; }
  .other_collapseData__TY6QH:first-of-type {
    margin-top: 0; }
  .other_collapseData__TY6QH h3 {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    text-align: center; }
    .other_collapseData__TY6QH h3:hover {
      color: white;
      background-color: #349800; }

.other_dataDiv__3psNT {
  width: 90%; }
  .other_dataDiv__3psNT table,
  .other_dataDiv__3psNT ul {
    width: 90%;
    margin: 20px; }
  .other_dataDiv__3psNT p,
  .other_dataDiv__3psNT ul li,
  .other_dataDiv__3psNT td {
    font-family: futura-pt, sans-serif;
    font-weight: 400;
    font-size: calc(16px + 0.3vw);
    margin: 0; }
    .other_dataDiv__3psNT p img,
    .other_dataDiv__3psNT ul li img,
    .other_dataDiv__3psNT td img {
      display: block;
      margin-left: auto;
      margin-right: auto; }
  .other_dataDiv__3psNT th {
    font-family: futura-pt, sans-serif;
    font-weight: bold;
    font-size: calc(16px + 0.3vw);
    padding: 0;
    margin: 0;
    text-align: left; }
  .other_dataDiv__3psNT .other_contentImages__58gAl {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    margin-top: 20px;
    -webkit-align-items: center;
            align-items: center;
    justify-items: center; }
    .other_dataDiv__3psNT .other_contentImages__58gAl img {
      width: 150px; }
    .other_dataDiv__3psNT .other_contentImages__58gAl p {
      text-align: center; }

.other_arrowOpen__rc5FS:after {
  content: '\25B2';
  font-size: 12px;
  padding-left: 20px; }

.other_arrowClose__Zd6e7:after {
  content: '\25BC';
  font-size: 12px;
  padding-left: 20px; }

.headdata_titleName__2QX8j {
  grid-column: 1 / span 2;
  justify-self: center;
  border-bottom: 1px solid grey;
  font-family: futura-pt, sans-serif;
  font-weight: bold;
  font-size: calc(16px + 1vw); }

.headdata_filterBack__2vTcA {
  grid-column: 1 / span 2;
  justify-self: left;
  background-color: #349800;
  margin-left: 20px;
  color: white;
  font-family: futura-pt, sans-serif;
  font-size: calc(16px + 0.3vw);
  font-weight: bold;
  width: 10%;
  min-width: 150px;
  text-align: center; }

.headdata_headImageWrapper__2GVSL {
  grid-column-start: 1;
  margin: 20px; }
  @media (max-width: 768px) {
    .headdata_headImageWrapper__2GVSL {
      grid-column: 1 / span 2;
      width: 80%;
      overflow-x: hidden;
      justify-self: center; } }
  .headdata_headImageWrapper__2GVSL .headdata_images__quioE {
    display: grid;
    grid-template-rows: 45px 1fr -webkit-fit-content;
    grid-template-rows: 45px 1fr fit-content;
    -webkit-align-items: center;
            align-items: center;
    justify-items: center;
    border: 1px solid grey; }
    .headdata_headImageWrapper__2GVSL .headdata_images__quioE .headdata_heroImage__1N6hB {
      width: 70%;
      margin: 20px; }
    .headdata_headImageWrapper__2GVSL .headdata_images__quioE h3 {
      margin: 0;
      height: 100%;
      text-align: center;
      width: 100%;
      background-color: #349800;
      color: white;
      font-family: futura-pt, sans-serif;
      font-size: calc(16px + 1vw); }
    .headdata_headImageWrapper__2GVSL .headdata_images__quioE .headdata_caption__1CNw1 {
      height: 30px; }
      .headdata_headImageWrapper__2GVSL .headdata_images__quioE .headdata_caption__1CNw1 p {
        font-family: futura-pt, sans-serif;
        font-weight: 400;
        font-size: calc(16px + 0.3vw);
        padding: 0;
        margin: 0; }
    .headdata_headImageWrapper__2GVSL .headdata_images__quioE .headdata_otherImages__1rAAa {
      margin: 20px;
      display: grid;
      width: 90%;
      grid-template-columns: repeat(4, 1fr);
      grid-column-gap: 20px;
      grid-row-gap: 20px; }
      .headdata_headImageWrapper__2GVSL .headdata_images__quioE .headdata_otherImages__1rAAa .headdata_smallImg__1_RGO {
        width: 80%;
        border: 1px solid grey;
        cursor: pointer; }
        .headdata_headImageWrapper__2GVSL .headdata_images__quioE .headdata_otherImages__1rAAa .headdata_smallImg__1_RGO:hover {
          border: 3px solid #349800; }

.headdata_headDataWrapper__3zAP7 {
  grid-column-start: 2;
  margin: 20px;
  display: grid;
  grid-auto-rows: minmax(-webkit-min-content, -webkit-max-content);
  grid-auto-rows: minmax(min-content, max-content);
  width: 95%; }
  @media (max-width: 768px) {
    .headdata_headDataWrapper__3zAP7 {
      grid-column: 1 / span 2;
      width: 95%;
      overflow-x: hidden;
      justify-self: center; } }
  .headdata_headDataWrapper__3zAP7 h1 {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    font-family: futura-pt, sans-serif;
    font-size: calc(16px + 1vw);
    border-bottom: 1px solid grey;
    text-align: center;
    width: 20%;
    justify-self: center; }
  .headdata_headDataWrapper__3zAP7 .headdata_wrapperTitle___9c46 {
    margin: 0;
    padding: 0;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    justify-self: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    color: white;
    background-color: #349800;
    text-align: center;
    padding: 5px 0 5px 0; }

.headdata_unLoggedDataWrapper__10Efw {
  grid-column: 1 / span end;
  margin: 20px;
  display: grid;
  width: 100%;
  justify-items: center; }
  @media (max-width: 768px) {
    .headdata_unLoggedDataWrapper__10Efw {
      grid-column: 1 / span 2;
      width: 95%;
      overflow-x: hidden;
      justify-self: center; } }
  .headdata_unLoggedDataWrapper__10Efw h1 {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    font-family: futura-pt, sans-serif;
    font-size: calc(16px + 1vw);
    border-bottom: 1px solid grey;
    text-align: center;
    width: 20%;
    justify-self: center; }
  .headdata_unLoggedDataWrapper__10Efw h3,
  .headdata_unLoggedDataWrapper__10Efw p {
    text-align: center; }
  .headdata_unLoggedDataWrapper__10Efw .headdata_unLogged__1PrVu {
    grid-column: 1 / span end;
    width: 100%;
    width: 80%; }
    .headdata_unLoggedDataWrapper__10Efw .headdata_unLogged__1PrVu h1 {
      width: 100%; }
    .headdata_unLoggedDataWrapper__10Efw .headdata_unLogged__1PrVu a {
      color: #349800; }
    .headdata_unLoggedDataWrapper__10Efw .headdata_unLogged__1PrVu .headdata_filterBack__2vTcA {
      justify-self: left; }

.headdata_backButton__3lm-D {
  margin: 5px auto;
  cursor: pointer; }
  .headdata_backButton__3lm-D::before {
    position: relative;
    /* top: 3pt; Uncomment this to lower the icons as requested in comments*/
    content: '';
    display: inline-block;
    /* By using an em scale, the arrows will size with the font */
    width: 0.4em;
    height: 0.4em;
    border-right: 0.2em solid white;
    border-top: 0.2em solid white;
    -webkit-transform: rotate(-135deg);
            transform: rotate(-135deg);
    margin-right: 0.5em; }

.head_headCell__2IiL0 {
  padding: 5px;
  border-style: solid;
  border-width: 5px;
  border-color: rgba(255, 255, 255, 0);
  transition: 500ms ease-out;
  display: grid;
  -webkit-align-items: center;
          align-items: center;
  justify-items: left; }
  .head_headCell__2IiL0:hover {
    border-style: solid;
    border-color: #349800;
    transition: 500ms ease-in;
    cursor: pointer; }
    .head_headCell__2IiL0:hover .head_pTag__21u-L {
      border-bottom: 1px solid rgba(255, 255, 255, 0); }
  @media (max-width: 768px) {
    .head_headCell__2IiL0 {
      -webkit-align-items: start;
              align-items: start; } }
  .head_headCell__2IiL0 img {
    width: 95%;
    justify-self: center;
    border: 1px solid rgba(0, 0, 0, 0.2); }
  .head_headCell__2IiL0 .head_pTag__21u-L {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    width: 100%; }
    .head_headCell__2IiL0 .head_pTag__21u-L p {
      font-family: futura-pt, sans-serif;
      font-size: 16px;
      text-align: left;
      font-weight: 400;
      width: 70%;
      margin-top: 4px;
      margin-bottom: 9px;
      padding-left: 5px;
      color: #112047; }
    @media (max-width: 768px) {
      .head_headCell__2IiL0 .head_pTag__21u-L {
        -webkit-align-self: end;
                align-self: end; } }

.filtersection_filterSection__3_4gP {
  border-style: solid;
  border-color: #9b9b9b;
  border-width: 0px 1px; }
  .filtersection_filterSection__3_4gP h1 {
    margin: 0;
    margin-left: 25px;
    padding: 10px;
    font-family: futura-pt, sans-serif;
    font-size: 16px;
    text-align: left; }
  .filtersection_filterSection__3_4gP label {
    font-family: futura-pt, sans-serif;
    font-size: 1em;
    margin-left: 25px; }
  .filtersection_filterSection__3_4gP:not(:last-child):after {
    content: '';
    display: block;
    height: 1px;
    width: 82%;
    margin: 0 auto;
    border-bottom: 1px solid #9b9b9b; }

.filtersection_filterBoxes__343m8 {
  height: 0;
  overflow: hidden;
  -webkit-transform: scaleY(0);
          transform: scaleY(0);
  display: grid;
  grid-template-rows: repeat(3, 50px);
  grid-auto-flow: column;
  -webkit-align-items: center;
          align-items: center; }

.filtersection_open__3o4Cp {
  padding: 10px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  -webkit-transform-origin: top;
          transform-origin: top;
  -webkit-transform: scaleY(1);
          transform: scaleY(1); }

.filtersection_arrowOpen__3pXnl:after {
  content: '\25B2';
  font-size: 12px;
  padding-left: 20px; }

.filtersection_arrowClose__14LqK:after {
  content: '\25BC';
  font-size: 12px;
  padding-left: 20px; }

.checkbox_labelText__16xrP {
  font-family: futura-pt, sans-serif;
  text-align: left;
  width: 50%; }

.checkbox_control__3nQh4 {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 15px;
  cursor: pointer;
  font-size: calc(16px + 0.1vw); }

.checkbox_control__3nQh4 input {
  position: absolute;
  z-index: -1;
  opacity: 0; }

.checkbox_control__indicator__29enp {
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  height: calc(12px + 0.09vw);
  width: calc(12px + 0.09vw);
  background: #9b9b9b;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition: 500ms ease-out; }

.checkbox_control--radio__2K4yi .checkbox_control__indicator__29enp {
  border-radius: 50%; }

.checkbox_control--checkbox__2hiTK .checkbox_control__indicator__29enp {
  border-radius: 50%; }

.checkbox_control__3nQh4:hover input ~ .checkbox_control__indicator__29enp,
.checkbox_control__3nQh4 input:focus ~ .checkbox_control__indicator__29enp {
  background: #9b9b9b; }

.checkbox_control__3nQh4 input:checked ~ .checkbox_control__indicator__29enp {
  background: #349800; }

.checkbox_control__3nQh4:hover input:not([disabled]):checked ~ .checkbox_control__indicator__29enp,
.checkbox_control__3nQh4 input:checked:focus ~ .checkbox_control__indicator__29enp {
  background: #349800;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition: 500ms ease-in; }

.checkbox_control__3nQh4 input:disabled ~ .checkbox_control__indicator__29enp {
  background: #9b9b9b;
  opacity: 0.6;
  pointer-events: none; }

.checkbox_control__indicator__29enp:after {
  content: '';
  position: absolute;
  display: none; }

.checkbox_control__3nQh4 input:checked ~ .checkbox_control__indicator__29enp:after {
  display: block; }

.checkbox_control--checkbox__2hiTK input:disabled ~ .checkbox_control__indicator__29enp:after {
  border-color: #9b9b9b; }

.checkbox_control--radio__2K4yi .checkbox_control__indicator__29enp:after {
  left: 7px;
  top: 7px;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background: #fff; }

.checkbox_control--radio__2K4yi input:disabled ~ .checkbox_control__indicator__29enp:after {
  background: #9b9b9b; }

.IECHI_pageWrapper__MJQeY {
  width: 100%;
  display: grid;
  grid-template-columns: 30% 1fr;
  margin-top: 55px; }
  @media (max-width: 768px) {
    .IECHI_pageWrapper__MJQeY {
      grid-template-columns: 50% 50%; } }

.IECHI_selectionWrapper__2ApFN {
  grid-column: 1 / 2;
  width: 90%;
  text-align: center;
  margin: 25px auto; }
  .IECHI_selectionWrapper__2ApFN div:nth-of-type(3) {
    border-top: 1px solid #9b9b9b; }
  .IECHI_selectionWrapper__2ApFN div:nth-of-type(11) {
    border-bottom: 1px solid #9b9b9b; }
  .IECHI_selectionWrapper__2ApFN .IECHI_typeSelect__3rzZy {
    display: grid;
    grid-template-columns: 1fr 1fr;
    -webkit-align-items: center;
            align-items: center;
    justify-items: center;
    margin-bottom: 10px; }
    .IECHI_selectionWrapper__2ApFN .IECHI_typeSelect__3rzZy div {
      width: 100%;
      height: 100%;
      background-color: white;
      border: 1px solid #9b9b9b; }
      .IECHI_selectionWrapper__2ApFN .IECHI_typeSelect__3rzZy div h2 {
        font-family: futura-pt, sans-serif;
        font-size: 18px;
        color: black; }
    .IECHI_selectionWrapper__2ApFN .IECHI_typeSelect__3rzZy .IECHI_selected__298ld {
      background-color: #349800;
      border: 1px solid white; }
      .IECHI_selectionWrapper__2ApFN .IECHI_typeSelect__3rzZy .IECHI_selected__298ld h2 {
        color: white; }
  @media (max-width: 768px) {
    .IECHI_selectionWrapper__2ApFN {
      grid-column: 1 / span 2;
      width: 90%;
      justify-self: center; } }

.IECHI_headWrapper__OLqIP {
  grid-column: 2 / 3;
  width: 95%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 200px));
  grid-auto-rows: -webkit-min-content;
  grid-auto-rows: min-content;
  grid-column-gap: 25px;
  grid-row-gap: 15px;
  margin: 25px auto; }
  @media (max-width: 768px) {
    .IECHI_headWrapper__OLqIP {
      grid-column: 1 / span 2;
      width: 100%;
      overflow-x: hidden;
      grid-template-columns: repeat(auto-fit, minmax(50px, 100px));
      justify-self: center; } }

.IECHI_mobileFilters__3gEd7 {
  display: none;
  grid-column: 1 / span end; }
  @media (max-width: 768px) {
    .IECHI_mobileFilters__3gEd7 {
      display: none;
      width: 90%;
      margin: 0 auto;
      grid-template-columns: 30% 30% 30%;
      grid-column-gap: 5px; }
      .IECHI_mobileFilters__3gEd7 label {
        font-size: 12px;
        color: #349800;
        font-weight: bold;
        -webkit-align-self: center;
                align-self: center; } }

.IECHI_mobiledCheck__2TRWz {
  border: 1px solid #9b9b9b;
  width: 100%;
  display: grid;
  justify-items: center;
  margin: 5px;
  background-color: #112047; }

.IECHI_chooseSelect__H7Dcg {
  margin-top: 25px; }

.IECHI_open__IPkng {
  display: block; }

.IECHI_closed__1cSUm {
  display: none; }

.IECHI_loggedOut__1yWL5 {
  grid-column: 1 / 2;
  width: 80%;
  font-size: calc(16px + 1vw);
  font-family: futura-pt, sans-serif;
  padding: 30px;
  justify-self: center;
  text-align: center; }
  .IECHI_loggedOut__1yWL5 a {
    color: #349800; }

.IECHI_manuList__1MDJx {
  margin-top: 20px;
  width: 100%; }
  .IECHI_manuList__1MDJx select {
    width: 80%;
    height: 30px; }

.IECHI_appSuggestion__2KUw_ {
  display: none;
  grid-column-start: 1;
  grid-column-end: 3;
  padding: 0 10%;
  text-align: center; }
  .IECHI_appSuggestion__2KUw_ a {
    display: inline-block;
    width: 49%;
    padding: 10px 0 0 0; }
  @media (max-width: 768px) {
    .IECHI_appSuggestion__2KUw_ {
      display: block; } }

.IECHI_appLinks__2rlMd {
  display: none;
  grid-column-start: 1;
  grid-column-end: 3;
  padding: 0 10%;
  text-align: center;
  margin-top: 0; }
  .IECHI_appLinks__2rlMd a {
    display: inline-block;
    width: 49%;
    padding: 10px 0 0 0; }
  @media (max-width: 768px) {
    .IECHI_appLinks__2rlMd {
      display: block; } }

.IECHI_titleDiv__nym4f {
  grid-column: 1 / span 2;
  width: 97%;
  height: 50px;
  margin-top: 20px;
  justify-self: center;
  display: grid;
  grid-template-columns: 30% 1fr 150px 150px;
  -webkit-align-items: center;
          align-items: center; }
  @media (max-width: 768px) {
    .IECHI_titleDiv__nym4f {
      width: 80%;
      grid-template-columns: 50% 50%; } }
  .IECHI_titleDiv__nym4f .IECHI_filterTitle__3EIm8 {
    justify-self: left;
    font-family: futura-pt, sans-serif;
    font-weight: bold;
    color: #9b9b9b;
    font-size: calc(16px + 0.3vw); }
  .IECHI_titleDiv__nym4f .IECHI_headerTitle__3f45Z {
    font-family: futura-pt, sans-serif;
    font-weight: bold;
    font-size: calc(16px + 0.3vw);
    justify-self: center; }
  @media (max-width: 768px) {
    .IECHI_titleDiv__nym4f .IECHI_filterTitle__3EIm8,
    .IECHI_titleDiv__nym4f .IECHI_headerTitle__3f45Z {
      display: none; } }
  .IECHI_titleDiv__nym4f .IECHI_numberButton__1dovG {
    justify-self: right;
    padding-right: 10px;
    font-family: futura-pt, sans-serif;
    font-weight: bold;
    font-size: calc(16px + 0.3vw);
    color: #9b9b9b; }
    @media (max-width: 768px) {
      .IECHI_titleDiv__nym4f .IECHI_numberButton__1dovG {
        justify-self: left; } }
  .IECHI_titleDiv__nym4f input {
    width: 80%;
    font-family: futura-pt, sans-serif;
    font-size: calc(16px + 0.3vw); }
    @media (max-width: 768px) {
      .IECHI_titleDiv__nym4f input {
        width: 100%;
        grid-template-columns: 50% 50%;
        border: 1px solid #9b9b9b; } }

.IECHI_clearFilter__7E1-F {
  width: 50%;
  border: 1px solid #9b9b9b;
  margin: 20px 0;
  display: grid; }
  .IECHI_clearFilter__7E1-F p {
    color: #9b9b9b;
    font-family: futura-pt, sans-serif; }

.Contact_contactWrapper__3fMVW {
  display: grid;
  grid-template-columns: 45vw 55vw;
  height: 100vh;
  padding-top: 55px; }
  .Contact_contactWrapper__3fMVW h1 {
    color: #ffffff;
    font-family: "Alfa Slab One", cursive;
    font-size: calc(16px + 2vw);
    font-weight: 250;
    letter-spacing: 0.05em;
    margin-left: 100px;
    margin-top: 125px; }
  @media (max-width: 768px) {
    .Contact_contactWrapper__3fMVW {
      display: block;
      position: relative;
      height: auto;
      margin: 0; } }

.Contact_textBlock__e1LZZ {
  display: grid;
  margin-top: 50px;
  grid-template-columns: 100px 1fr;
  -webkit-align-items: center;
          align-items: center;
  grid-row-start: 2; }
  @media (max-width: 768px) {
    .Contact_textBlock__e1LZZ {
      margin-top: 10%; } }

.Contact_rightSide__5DKQs {
  position: relative;
  display: -webkit-flex;
  display: flex; }
  @media (max-width: 768px) {
    .Contact_rightSide__5DKQs {
      width: 100%; } }

@media (max-width: 768px) {
  .Contact_leftSide__2VF65 {
    display: none; } }

.Contact_rightSection__2Prh3 {
  width: 80%;
  margin: auto; }
  @media (max-width: 768px) {
    .Contact_rightSection__2Prh3 {
      position: relative;
      top: 0;
      left: 0;
      -webkit-transform: none;
              transform: none;
      margin: 50px auto;
      width: 85%; } }
  .Contact_rightSection__2Prh3 img {
    width: 50%;
    max-width: 100%;
    min-width: 100%;
    justify-self: center;
    -webkit-align-self: center;
            align-self: center;
    margin-top: 10px; }
  .Contact_rightSection__2Prh3 div:first-child p {
    font-weight: bold; }
  .Contact_rightSection__2Prh3 p,
  .Contact_rightSection__2Prh3 a {
    font-family: futura-pt, sans-serif;
    font-weight: 250;
    font-size: calc(16px + 0.3vw);
    padding: 2px 25px;
    margin: 0;
    color: #4a4a4a; }
  .Contact_rightSection__2Prh3 a {
    text-decoration: none; }
  .Contact_rightSection__2Prh3 button {
    background-color: #509500;
    box-shadow: 3px 3px 6px #509500;
    font-family: "Alfa Slab One", cursive;
    font-size: calc(16px + 0.3vw);
    color: #ffffff;
    letter-spacing: 0.1vw;
    font-weight: 250;
    margin: 10px 0px 10px 25px;
    width: 40%;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    text-align: left;
    outline: none;
    border: 0; }
    @media (max-width: 768px) {
      .Contact_rightSection__2Prh3 button {
        width: 90%;
        margin: 0 auto; } }
    .Contact_rightSection__2Prh3 button span {
      width: 100%;
      text-align: center;
      display: inline-block; }

.Contact_innerInfo__1iXmp a {
  display: block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content; }

.hyd_topper_topper__2mZP4 {
  min-height: 35vh;
  display: grid;
  -webkit-align-items: center;
          align-items: center;
  border-bottom: 4px solid white; }

.hyd_topper_textBlock__NovTE {
  width: 70%;
  margin: 0 auto;
  display: grid;
  justify-items: center; }
  .hyd_topper_textBlock__NovTE h1,
  .hyd_topper_textBlock__NovTE p {
    color: #ffffff;
    text-align: center; }
  .hyd_topper_textBlock__NovTE h1 {
    font-family: "Alfa Slab One", cursive;
    font-size: calc(16px + 2vw);
    font-weight: 250;
    letter-spacing: 0.2vw;
    margin: 0; }
  .hyd_topper_textBlock__NovTE p {
    font-family: futura-pt, sans-serif;
    font-weight: 400;
    width: 60%;
    font-size: calc(16px + 0.3vw); }

.hyd_card_cardLayout__2sG76 {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: auto;
  overflow-x: hidden;
  position: relative;
  display: grid;
  -webkit-align-items: center;
          align-items: center; }
  .hyd_card_cardLayout__2sG76 h1 {
    color: #ffffff;
    font-family: "Alfa Slab One", cursive;
    font-size: calc(16px + 2vw);
    font-weight: 250;
    letter-spacing: 0.2vw;
    z-index: 10;
    width: 80%;
    margin: 0;
    padding: 5%; }
    @media screen and (max-width: 768px) {
      .hyd_card_cardLayout__2sG76 h1 {
        position: absolute !important;
        top: 5%;
        right: 0; } }
  .hyd_card_cardLayout__2sG76 ul,
  .hyd_card_cardLayout__2sG76 ol {
    -webkit-columns: 1;
            columns: 1;
    margin: 0 0 20px 0; }
  .hyd_card_cardLayout__2sG76 p,
  .hyd_card_cardLayout__2sG76 code,
  .hyd_card_cardLayout__2sG76 ul li,
  .hyd_card_cardLayout__2sG76 ol li {
    font-family: futura-pt, sans-serif;
    color: #ffffff;
    text-align: left;
    font-size: calc(16px + 0.3vw);
    font-weight: 400;
    margin: 0 0 10px 0;
    padding: 0 5% 0 0; }
  .hyd_card_cardLayout__2sG76 img {
    -webkit-filter: brightness(65%);
            filter: brightness(65%);
    height: 100%;
    width: 100%;
    min-height: 33vh;
    object-fit: cover;
    max-width: 33vw;
    justify-self: left; }
    @media screen and (max-width: 768px) {
      .hyd_card_cardLayout__2sG76 img {
        height: 20vh;
        max-width: none;
        max-width: initial;
        object-fit: cover; } }
    @media screen and (min-color-index: 0) and (-webkit-min-device-pixel-ratio: 0) {
      .hyd_card_cardLayout__2sG76 img {
        height: auto; } }
  .hyd_card_cardLayout__2sG76 p img {
    -webkit-filter: none;
            filter: none; }
    @media screen and (max-width: 768px) {
      .hyd_card_cardLayout__2sG76 p img {
        height: auto; } }
  @media screen and (max-width: 768px) {
    .hyd_card_cardLayout__2sG76 {
      display: block;
      position: relative; } }

.hyd_card_image_left__1JGAn {
  grid-template-columns: 1fr 70%;
  justify-items: end; }
  .hyd_card_image_left__1JGAn img {
    grid-column-start: 1;
    grid-row-start: 1; }
  .hyd_card_image_left__1JGAn h1 {
    grid-column-start: 1;
    grid-row-start: 1;
    border-top: 3px solid #ffffff;
    border-left: 3px solid #ffffff;
    position: relative; }
    .hyd_card_image_left__1JGAn h1:before {
      content: '';
      height: 3px;
      width: 40%;
      background: #fff;
      position: absolute;
      left: 0;
      bottom: 0; }

.hyd_card_image_right__21RC5 {
  grid-template-columns: 70% 1fr;
  justify-items: end; }
  .hyd_card_image_right__21RC5 img {
    grid-column-start: 2;
    grid-row-start: 1; }
  .hyd_card_image_right__21RC5 h1 {
    grid-column-start: 2;
    grid-row-start: 1;
    border-left: 3px solid #ffffff;
    border-bottom: 3px solid #ffffff;
    position: relative; }
    .hyd_card_image_right__21RC5 h1:before {
      content: '';
      height: 3px;
      width: 50%;
      background: #fff;
      position: absolute;
      left: 0;
      top: 0; }

.hyd_card_white__WAZbC {
  background-color: #ffffff; }
  .hyd_card_white__WAZbC p,
  .hyd_card_white__WAZbC code,
  .hyd_card_white__WAZbC ul li {
    color: black; }

.hyd_card_blue__3DdXU {
  background-color: #112047;
  color: #ffffff; }
  .hyd_card_blue__3DdXU .hyd_card_textField__2hnEi table th {
    color: #ffffff; }

.hyd_card_textField__2hnEi {
  padding: 5%;
  position: relative; }
  .hyd_card_textField__2hnEi span {
    color: #7ed321;
    font-weight: bold; }
  .hyd_card_textField__2hnEi .hyd_card_partNumber__1ivvb {
    position: absolute;
    bottom: 0;
    right: 0; }
  .hyd_card_textField__2hnEi table {
    width: 80%;
    margin: 0 auto;
    text-align: center; }
    .hyd_card_textField__2hnEi table th {
      color: #112047;
      font-family: futura-pt, sans-serif;
      font-weight: bold;
      font-size: calc(16px + 0.3vw);
      border-bottom: 2px solid #7ed321; }
    .hyd_card_textField__2hnEi table th:not(:last-of-type) {
      border-right: 2px solid #7ed321; }
    .hyd_card_textField__2hnEi table tr:not(:last-of-type) td {
      padding: 5px;
      border-bottom: 2px solid #7ed321; }
    .hyd_card_textField__2hnEi table tr td:not(:last-of-type) {
      border-right: 2px solid #7ed321; }

.hyd_card_expandButton__3RMeY {
  position: absolute;
  top: 15px;
  left: 15px;
  width: 25px;
  height: 25px; }
  @media screen and (max-width: 768px) {
    .hyd_card_expandButton__3RMeY {
      display: none; } }
  .hyd_card_expandButton__3RMeY img {
    -webkit-filter: none;
            filter: none;
    width: 100%;
    min-height: auto; }

.hyd_card_expand_left__kkNOo {
  grid-column: 1;
  grid-row: 1; }

.hyd_card_expand_right__1wBbJ {
  grid-column: 2;
  grid-row: 1; }

.hyd_selection_toolMenu__1J2sD {
  background-color: #112047;
  display: grid;
  justify-items: center; }
  .hyd_selection_toolMenu__1J2sD ul {
    height: 15vh;
    width: 100%;
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: -webkit-flex;
    display: flex;
    justify-items: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly; }
    .hyd_selection_toolMenu__1J2sD ul li {
      box-sizing: border-box;
      font-family: futura-pt, sans-serif;
      font-size: calc(16px + 0.3vw);
      font-weight: bold;
      text-align: center;
      padding: 0 1%;
      box-sizing: border-box;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content; }
      .hyd_selection_toolMenu__1J2sD ul li a {
        text-decoration: none;
        color: #ffffff; }
        .hyd_selection_toolMenu__1J2sD ul li a:hover {
          color: #7ed321;
          transition: ease 0s; }
      @media screen and (max-width: 1200px) {
        .hyd_selection_toolMenu__1J2sD ul li {
          width: 33%;
          display: inline-block;
          vertical-align: top;
          padding: 1%; } }
      @media screen and (max-width: 768px) {
        .hyd_selection_toolMenu__1J2sD ul li {
          width: 50%;
          padding: 2%; } }
    @media screen and (max-width: 1200px) {
      .hyd_selection_toolMenu__1J2sD ul {
        display: block;
        height: auto;
        padding: 5%; } }

html {
  scroll-behavior: smooth; }

.Hydraulic_mainWindow__3hINo {
  margin-top: 55px; }

.ei_topper_topper__2WxCv {
  min-height: 35vh;
  display: grid;
  -webkit-align-items: center;
          align-items: center;
  border-bottom: 4px solid white; }

.ei_topper_textBlock__2tRpd {
  width: 70%;
  margin: 0 auto;
  display: grid;
  justify-items: center; }
  .ei_topper_textBlock__2tRpd h1,
  .ei_topper_textBlock__2tRpd p {
    color: #ffffff;
    text-align: center; }
  .ei_topper_textBlock__2tRpd h1 {
    font-family: "Alfa Slab One", cursive;
    font-size: calc(16px + 2vw);
    font-weight: 250;
    letter-spacing: 0.2vw;
    margin: 0; }
  .ei_topper_textBlock__2tRpd p {
    font-family: futura-pt, sans-serif;
    font-weight: 400;
    width: 60%;
    font-size: calc(16px + 0.3vw); }

.ei_card_cardLayout__1Nol3 {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: auto;
  overflow-x: hidden;
  position: relative;
  display: grid;
  -webkit-align-items: center;
          align-items: center; }
  .ei_card_cardLayout__1Nol3 h1 {
    color: #ffffff;
    font-family: "Alfa Slab One", cursive;
    font-size: calc(16px + 2vw);
    font-weight: 250;
    letter-spacing: 0.2vw;
    z-index: 10;
    width: 80%;
    margin: 0;
    padding: 5%; }
    @media screen and (max-width: 768px) {
      .ei_card_cardLayout__1Nol3 h1 {
        position: absolute !important;
        top: 5%;
        right: 0; } }
  .ei_card_cardLayout__1Nol3 ul,
  .ei_card_cardLayout__1Nol3 ol {
    -webkit-columns: 1;
            columns: 1;
    margin: 0 0 20px 0; }
  .ei_card_cardLayout__1Nol3 p,
  .ei_card_cardLayout__1Nol3 code,
  .ei_card_cardLayout__1Nol3 ul li,
  .ei_card_cardLayout__1Nol3 ol li {
    font-family: futura-pt, sans-serif;
    color: #ffffff;
    text-align: left;
    font-size: calc(16px + 0.3vw);
    font-weight: 400;
    margin: 0 0 10px 0;
    padding: 0 5% 0 0; }
  .ei_card_cardLayout__1Nol3 img {
    -webkit-filter: brightness(65%);
            filter: brightness(65%);
    height: 100%;
    width: 100%;
    min-height: 33vh;
    object-fit: cover;
    max-width: 33vw;
    justify-self: left; }
    @media screen and (max-width: 768px) {
      .ei_card_cardLayout__1Nol3 img {
        height: 20vh;
        max-width: none;
        max-width: initial;
        object-fit: cover; } }
    @media screen and (min-color-index: 0) and (-webkit-min-device-pixel-ratio: 0) {
      .ei_card_cardLayout__1Nol3 img {
        height: auto; } }
  .ei_card_cardLayout__1Nol3 p img {
    -webkit-filter: none;
            filter: none; }
    @media screen and (max-width: 768px) {
      .ei_card_cardLayout__1Nol3 p img {
        height: auto; } }
  @media screen and (max-width: 768px) {
    .ei_card_cardLayout__1Nol3 {
      display: block;
      position: relative; } }

.ei_card_image_left__2sN1V {
  grid-template-columns: 1fr 70%; }
  .ei_card_image_left__2sN1V img {
    grid-column-start: 1;
    grid-row-start: 1; }
  .ei_card_image_left__2sN1V h1 {
    justify-self: end;
    grid-column-start: 1;
    grid-row-start: 1;
    border-top: 3px solid #ffffff;
    border-left: 3px solid #ffffff;
    position: relative; }
    .ei_card_image_left__2sN1V h1:before {
      content: '';
      height: 3px;
      width: 40%;
      background: #fff;
      position: absolute;
      left: 0;
      bottom: 0; }

.ei_card_image_right__2YTt_ {
  grid-template-columns: 70% 1fr;
  justify-items: end; }
  .ei_card_image_right__2YTt_ img {
    grid-column-start: 2;
    grid-row-start: 1; }
  .ei_card_image_right__2YTt_ h1 {
    justify-self: end;
    grid-column-start: 2;
    grid-row-start: 1;
    border-left: 3px solid #ffffff;
    border-bottom: 3px solid #ffffff;
    position: relative; }
    .ei_card_image_right__2YTt_ h1:before {
      content: '';
      height: 3px;
      width: 50%;
      background: #fff;
      position: absolute;
      left: 0;
      top: 0; }

.ei_card_white__39k0H {
  background-color: #ffffff; }
  .ei_card_white__39k0H p,
  .ei_card_white__39k0H code,
  .ei_card_white__39k0H ul li {
    color: black; }

.ei_card_blue__2txj5 {
  background-color: #112047;
  color: #ffffff; }
  .ei_card_blue__2txj5 .ei_card_textField__135Yn table th {
    color: #ffffff; }

.ei_card_textField__135Yn {
  padding: 5%;
  position: relative; }
  .ei_card_textField__135Yn span {
    color: #7ed321;
    font-weight: bold; }
  .ei_card_textField__135Yn .ei_card_partNumber__2wSuW {
    position: absolute;
    bottom: 0;
    right: 0; }
  .ei_card_textField__135Yn table {
    width: 80%;
    margin: 0 auto;
    text-align: center; }
    .ei_card_textField__135Yn table th {
      color: #112047;
      font-family: futura-pt, sans-serif;
      font-weight: bold;
      font-size: calc(16px + 0.3vw);
      border-bottom: 2px solid #7ed321; }
    .ei_card_textField__135Yn table th:not(:last-of-type) {
      border-right: 2px solid #7ed321; }
    .ei_card_textField__135Yn table tr:not(:last-of-type) td {
      padding: 5px;
      border-bottom: 2px solid #7ed321; }
    .ei_card_textField__135Yn table tr td:not(:last-of-type) {
      border-right: 2px solid #7ed321; }

.ei_card_expandButton__2amnE {
  position: absolute;
  top: 15px;
  width: 25px;
  height: 25px; }
  @media screen and (max-width: 768px) {
    .ei_card_expandButton__2amnE {
      display: none; } }
  .ei_card_expandButton__2amnE img {
    -webkit-filter: none;
            filter: none;
    width: 100%;
    min-height: auto; }

.ei_card_expand_left__HL5GZ {
  grid-column: 1;
  grid-row: 1;
  left: 15px; }

.ei_card_expand_right__jTSIz {
  grid-column: 2;
  grid-row: 1;
  right: 15px; }

.ei_selection_toolMenu__1Zki6 {
  background-color: #112047; }
  .ei_selection_toolMenu__1Zki6 ul {
    height: 15vh;
    width: 100%;
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: -webkit-flex;
    display: flex;
    justify-items: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly; }
    .ei_selection_toolMenu__1Zki6 ul li {
      box-sizing: border-box;
      font-family: futura-pt, sans-serif;
      font-size: calc(16px + 0.3vw);
      font-weight: bold;
      text-align: center;
      padding: 0 1%;
      box-sizing: border-box;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content; }
      .ei_selection_toolMenu__1Zki6 ul li a {
        text-decoration: none;
        color: #ffffff; }
        .ei_selection_toolMenu__1Zki6 ul li a:hover {
          color: #7ed321;
          transition: ease 0s; }
      @media screen and (max-width: 1200px) {
        .ei_selection_toolMenu__1Zki6 ul li {
          width: 33%;
          display: inline-block;
          vertical-align: top;
          padding: 1%; } }
      @media screen and (max-width: 768px) {
        .ei_selection_toolMenu__1Zki6 ul li {
          width: 50%;
          padding: 2%; } }
    @media screen and (max-width: 1200px) {
      .ei_selection_toolMenu__1Zki6 ul {
        display: block;
        height: auto;
        padding: 5%; } }

html {
  scroll-behavior: smooth; }

.ElevatorIndustry_mainWindow__1K-hA {
  margin-top: 55px; }

.topper_videosWrapper__341sF {
  margin-top: -5px; }
  .topper_videosWrapper__341sF h1 {
    color: #ffffff;
    font-family: "Alfa Slab One", cursive;
    font-size: calc(16px + 2vw);
    font-weight: 250;
    letter-spacing: 0.2vw;
    margin: 0; }
  .topper_videosWrapper__341sF .topper_topText__2UyL- {
    text-align: center;
    margin: 5% 0 0 0; }
  .topper_videosWrapper__341sF .topper_scrollerBar__3qTU1 {
    margin: 0 !important; }
  .topper_videosWrapper__341sF p {
    text-align: center;
    padding: 0 5%;
    margin: 0; }
    @media (max-width: 768px) {
      .topper_videosWrapper__341sF p br {
        display: none; } }

.topper_videosBanner__3Hx2x {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  width: 100%;
  min-height: 15vh;
  margin: auto;
  padding: 50px 0;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column; }
  .topper_videosBanner__3Hx2x h1 {
    text-align: left;
    margin-left: 20px;
    margin-bottom: 20px; }
  .topper_videosBanner__3Hx2x p {
    font-family: futura-pt, sans-serif;
    font-weight: 400;
    width: 60%;
    font-size: calc(16px + 0.3vw);
    color: white; }
  .topper_videosBanner__3Hx2x img {
    height: auto;
    max-width: 100%;
    width: 4vw;
    justify-self: right; }

.downloads_downloadWrapper__3Bgya {
  width: 80%;
  min-height: 50vh;
  border-bottom: solid 3px #7ed321;
  margin: 0 auto; }
  .downloads_downloadWrapper__3Bgya h1 {
    text-align: center;
    color: #112047;
    font-family: "Alfa Slab One", cursive;
    font-size: calc(16px + 2vw);
    font-weight: 250;
    letter-spacing: 0.2vw;
    margin-bottom: 50px; }
  @media (max-width: 768px) {
    .downloads_downloadWrapper__3Bgya ul {
      padding: 0; } }
  .downloads_downloadWrapper__3Bgya ul li {
    list-style-type: none;
    padding: 20px; }
  .downloads_downloadWrapper__3Bgya a {
    color: #112047;
    text-decoration: none;
    font-family: futura-pt, sans-serif;
    font-weight: 400;
    font-size: calc(16px + 0.3vw);
    transition: ease 0.25s; }
    .downloads_downloadWrapper__3Bgya a:hover {
      transition: ease 0.25s;
      color: #7ed321; }

.Videos_mainWindow__1AQb8 {
  padding-top: 60px; }

.Videos_sectionWrapper__1ofCs {
  margin-bottom: 50px; }

.partTable_tableWrapper__3zinC p,
.partTable_tableWrapper__3zinC td {
  font-family: futura-pt, sans-serif;
  font-size: calc(16px + 0.3vw);
  padding-left: 5px; }

.partTable_tableWrapper__3zinC table,
.partTable_tableWrapper__3zinC th,
.partTable_tableWrapper__3zinC td {
  border: 1px solid black;
  border-bottom: none;
  border-collapse: collapse; }

.partTable_tableWrapper__3zinC table {
  width: 100%;
  empty-cells: show; }

.partTable_tableWrapper__3zinC .partTable_manuName__2bdhz {
  width: 100%;
  color: #ffffff;
  text-align: center;
  background-color: #112047;
  margin: 0;
  font-family: futura-pt, sans-serif;
  font-weight: 400;
  font-size: calc(16px + 1vw);
  padding-left: 0; }
  .partTable_tableWrapper__3zinC .partTable_manuName__2bdhz:before {
    background: white;
    content: '';
    display: block;
    height: 55px; }

.partTable_tableWrapper__3zinC .partTable_col__317vq {
  width: 25%; }

.partTable_notesWrapper__2CQCB {
  border: 1px solid black; }

.partTable_backTop__18wvA {
  width: 100%;
  text-align: right;
  margin: 0;
  padding: 0; }

.traction_parts_tmWrapper__B4y59 {
  margin-top: 55px;
  padding: 50px;
  font-family: futura-pt, sans-serif; }
  @media (max-width: 768px) {
    .traction_parts_tmWrapper__B4y59 {
      padding: 10%; } }

.traction_parts_selectionList__-Rnk8 a {
  display: block;
  text-decoration: none;
  color: black;
  padding: 5px;
  font-size: calc(16px + 0.3vw); }
  .traction_parts_selectionList__-Rnk8 a:hover {
    color: #7ed321;
    transition: ease 0s; }

.traction_parts_topWrapper__2-oyW {
  grid-column-start: 2;
  display: grid;
  grid-template-columns: 50% 50%;
  padding: 0 25px; }
  @media (max-width: 768px) {
    .traction_parts_topWrapper__2-oyW {
      display: block;
      padding: 0; } }

.traction_parts_tablesWrapper__BvuaZ {
  grid-column-start: 2;
  margin-top: 55px; }
  @media (max-width: 768px) {
    .traction_parts_tablesWrapper__BvuaZ {
      margin-top: 0; } }

.traction_parts_linkList__1mdUc {
  grid-column: 1;
  margin: 10px 10px 0 10px; }
  .traction_parts_linkList__1mdUc a {
    display: block;
    text-decoration: none;
    color: black;
    padding: 5px 0;
    font-size: calc(16px + 0.3vw);
    border-bottom: 1px solid black; }
    .traction_parts_linkList__1mdUc a:first-of-type {
      border-top: 1px solid black; }
    .traction_parts_linkList__1mdUc a:hover {
      color: #7ed321;
      transition: ease 0s; }

.traction_parts_descriptionImages__Y6kRT {
  height: 85%;
  width: 90%;
  -webkit-align-items: center;
          align-items: center;
  justify-items: center; }
  .traction_parts_descriptionImages__Y6kRT .traction_parts_item__3iKAI {
    margin-bottom: 20px;
    display: grid;
    grid-template-columns: 25% 1fr; }
    .traction_parts_descriptionImages__Y6kRT .traction_parts_item__3iKAI img {
      max-width: 100%;
      height: auto;
      margin-right: 5%; }
      @media (max-width: 768px) {
        .traction_parts_descriptionImages__Y6kRT .traction_parts_item__3iKAI img {
          margin-right: 0; } }
    .traction_parts_descriptionImages__Y6kRT .traction_parts_item__3iKAI .traction_parts_col__xxLkX {
      padding-left: 5%; }
      @media (max-width: 768px) {
        .traction_parts_descriptionImages__Y6kRT .traction_parts_item__3iKAI .traction_parts_col__xxLkX {
          padding-left: 0; } }
    @media (max-width: 768px) {
      .traction_parts_descriptionImages__Y6kRT .traction_parts_item__3iKAI {
        display: block; } }
  .traction_parts_descriptionImages__Y6kRT p {
    font-size: calc(16px + 0.3vw);
    justify-self: right;
    padding-right: 10px; }
  .traction_parts_descriptionImages__Y6kRT img {
    width: 100%; }
  @media (max-width: 768px) {
    .traction_parts_descriptionImages__Y6kRT {
      width: auto; } }

