@import '../../variables.scss';

a {
  text-decoration: none;
}

.videoCard {
  height: 297px;
  display: grid;
  align-items: center;
  justify-items: center;
  transition: 0.25s ease;
  &:hover {
    box-shadow: inset 0 0 0 1000px rgba(126, 211, 33, 0.8);
    transition: 0.75s ease;
    cursor: pointer;
  }
  p {
    font-family: $primary-text;
    width: 50%;
    color: $primary-text-color;
    text-align: center;
    font-size: calc(16px + 1.5vw);
    font-weight: bold;

    @media screen and (max-width: $break-small) {
      font-size: 10px;
    }
  }

  @media screen and (max-width: $break-small) {
    height: 15vh;
  }
}
