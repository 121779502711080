@import '../../../variables.scss';

.downloadWrapper {
  width: 80%;
  min-height: 50vh;
  border-bottom: solid 3px $green-color;
  margin: 0 auto;
  h1 {
    text-align: center;
    color: $primary-color;
    font-family: $primary-heading;
    font-size: $header-font-size;
    font-weight: 250;
    letter-spacing: 0.2vw;
    margin-bottom: 50px;
  }
  ul{
    @media (max-width: $break-small) {
      padding: 0;
    }
  }
  ul li {
    list-style-type: none;
    padding: 20px;
  }
  a {
    color: $primary-color;
    text-decoration: none;
    font-family: $primary-text;
    font-weight: 400;
    font-size: $standard-font-size;
    transition: ease 0.25s;
    &:hover {
      transition: ease 0.25s;
      color: $green-color;
    }
  }
}
