@import '../../variables.scss';

.pageWrapper {
  width: 100%;
  display: grid;
  grid-template-columns: 30% 1fr;
  margin-top: 55px;
  @media (max-width: $break-small) {
    grid-template-columns: 50% 50%;
  }
}

.selectionWrapper {
  grid-column: 1 / 2;
  width: 90%;
  text-align: center;
  margin: 25px auto;
  div:nth-of-type(3) {
    border-top: 1px solid $grey-color;
  }
  div:nth-of-type(11) {
    border-bottom: 1px solid $grey-color;
  }
  .typeSelect {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-items: center;
    margin-bottom: 10px;
    div {
      width: 100%;
      height: 100%;
      background-color: white;
      border: 1px solid $grey-color;
      h2 {
        font-family: $primary-text;
        font-size: 18px;
        color: black;
      }
    }
    .selected {
      background-color: $iechi-green;
      border: 1px solid white;
      h2 {
        color: white;
      }
    }
  }
  @media (max-width: $break-small) {
    grid-column: 1 / span 2;
    width: 90%;
    justify-self: center;
  }
}

.headWrapper {
  grid-column: 2 / 3;
  width: 95%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 200px));
  grid-auto-rows: min-content;
  grid-column-gap: 25px;
  grid-row-gap: 15px;
  margin: 25px auto;
  @media (max-width: $break-small) {
    grid-column: 1 / span 2;
    width: 100%;
    overflow-x: hidden;
    grid-template-columns: repeat(auto-fit, minmax(50px, 100px));
    justify-self: center;
  }
}

.mobileFilters {
  display: none;
  grid-column: 1 / span end;
  @media (max-width: $break-small) {
    display: none;
    // display: grid;
    width: 90%;
    margin: 0 auto;
    grid-template-columns: 30% 30% 30%;
    grid-column-gap: 5px;
    label {
      font-size: 12px;
      color: $iechi-green;
      font-weight: bold;
      align-self: center;
    }
  }
}

.mobiledCheck {
  border: 1px solid $grey-color;
  width: 100%;
  display: grid;
  justify-items: center;
  margin: 5px;
  background-color: $primary-color;
}

.chooseSelect {
  margin-top: 25px;
}

.open {
  display: block;
}

.closed {
  display: none;
}

.loggedOut {
  grid-column: 1 / 2;
  width: 80%;
  font-size: $smallheader-font-size;
  font-family: $primary-text;
  padding: 30px;
  justify-self: center;
  text-align: center;
  a {
    color: $iechi-green;
  }
}

.manuList {
  margin-top: 20px;
  width: 100%;
  select {
    width: 80%;
    height: 30px;
  }
}

.appSuggestion{
  display: none;
  grid-column-start: 1;
  grid-column-end: 3;
  padding: 0 10%;
  text-align: center;

  a{
    display: inline-block;
    width: 49%; 
    padding: 10px 0 0 0;
  }

  @media (max-width: $break-small) {
    display: block;
  }
}

.appLinks{
  display: none;
  grid-column-start: 1;
  grid-column-end: 3;
  padding: 0 10%;
  text-align: center;
  margin-top: 0;

  a{
    display: inline-block;
    width: 49%; 
    padding: 10px 0 0 0;
  }

  @media (max-width: $break-small) {
    display: block;
  }
}

.titleDiv {
  grid-column: 1 / span 2;
  width: 97%;
  height: 50px;
  margin-top: 20px;
  justify-self: center;
  display: grid;
  grid-template-columns: 30% 1fr 150px 150px;
  align-items: center;
  @media (max-width: $break-small) {
    width: 80%;
    grid-template-columns: 50% 50%;
  }
  .filterTitle {
    justify-self: left;
    font-family: $primary-text;
    font-weight: bold;
    color: $grey-color;
    font-size: $standard-font-size;
  }
  .headerTitle {
    font-family: $primary-text;
    font-weight: bold;
    font-size: $standard-font-size;
    justify-self: center;
  }
  .filterTitle,
  .headerTitle {
    @media (max-width: $break-small) {
      display: none;
    }
  }
  .numberButton {
    justify-self: right;
    padding-right: 10px;
    font-family: $primary-text;
    font-weight: bold;
    font-size: $standard-font-size;
    color: $grey-color;
    @media (max-width: $break-small) {
      justify-self: left;
    }
  }
  input {
    width: 80%;
    font-family: $primary-text;
    font-size: $standard-font-size;
    @media (max-width: $break-small) {
      width: 100%;
      grid-template-columns: 50% 50%;
      border: 1px solid $grey-color;
    }
  }
}
.clearFilter {
  width: 50%;
  border: 1px solid $grey-color;
  margin: 20px 0;
  display: grid;
  p {
    color: $grey-color;
    font-family: $primary-text;
  }
}
