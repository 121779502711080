@import '../../variables.scss';

footer {
  background-color: $primary-color;
  display: grid;
  grid-template-columns: 30vw 40vw 30vw;
  grid-template-rows: 325px 150px 150px fit-content fit-content;
  justify-items: center;
  color: $primary-text-color;
  min-height: 500px;
  padding-bottom: 2%;
  @media (max-width: $break-small) {
    display: inherit;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $primary-heading;
    letter-spacing: 0.1vw;
    font-weight: 250;
  }

  h3 {
    font-size: $smallheader-font-size;
    @media (max-width: $break-small) {
      font-size: $standard-font-size;
      margin: 10px;
    }
  }

  p {
    font-size: $standard-font-size;
  }

  .col {
    padding: 50px;
    a {
      text-decoration: none;
      color: $primary-text-color;
      font-family: $primary-text;
      font-weight: 400;
      display: block;
      font-size: $standard-font-size;
      padding: 5px;
    }
    .follow {
      margin-bottom: 10px;
    }
    .facebookLink,
    .linkedinLink {
      display: grid;
      width: 100%;
      height: 70px;
      align-items: center;
      grid-template-columns: 0.5fr 1fr;
      a {
        height: 100%;
      }
      p {
        margin-left: -10px;
      }
    }
    .facebookIcon,
    .linkedinIcon {
      padding-right: 10px;
      height: 70%;
    }

    @media (max-width: $break-small) {
      text-align: center;
      padding: 3% 5%;
      .facebookIcon,
      .linkedinIcon {
        justify-self: right;
        height: 50%;
      }
      .facebookLink,
      .linkedinLink {
        grid-template-columns: 40% 60%;
      }
      .facebookLink p,
      .linkedinLink p {
        text-align: left;
        padding-left: 10px;
      }
    }
  }

  .sign {
    text-align: center;
    button,
    input {
      display: block;
      margin: 10px auto 10px;
    }

    input {
      background: none;
      color: $primary-text-color;
      border: 1px solid #9b9b9b;
      width: 100%;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-border-radius: 0;
      border-radius: 0;
      -webkit-appearance: none;
      -webkit-tap-highlight-color: transparent;
      font-size: $standard-font-size;
      min-height: 27px;
      padding: 12px 25px 12px 25px;
      text-align: center;
    }

    button {
      background: #d8d8d8;
      color: $primary-color;
      width: 100%;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      font-size: $standard-font-size;
      font-weight: 800;
      text-transform: uppercase;
      min-height: 27px;
      padding: 12px 25px 12px 25px;
      text-align: center;
      cursor: pointer;
      border: 0;
    }
  }

  .links {
    text-align: center;
    grid-column-start: 1;
    grid-column-end: end;
    grid-row-start: 3;

    a {
      font-family: $primary-heading;
      font-size: $standard-font-size;
      font-weight: 250;
      letter-spacing: 0.05vw;
      padding: 20px;
      display: inline-block;
      color: $primary-text-color;
      text-decoration: none;
    }
  }

  .bottomLogos {
    grid-column-start: 2;
    grid-row-start: 2;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    img {
      height: 100%;
      margin: 0 2vw;
      max-height: 40px;
    }
    @media (max-width: $break-large) {
      grid-column: 1 / 4;
    }
    @media (max-width: $break-small) {
      width: 80%;
      margin: 0 auto;
      display: flex;
      justify-content: space-evenly;
    }
  }

  .copyright {
    grid-column: 1 / span 3;
    grid-row-start: 5;
    padding-top: 7px;
    margin: 0;
    text-align: center;
    justify-self: center;
    font-size: 16px;
    @media (max-width: $break-small) {
      font-size: 12px;
      text-align: center;
    }
  }
}

:global(.grecaptcha-badge) {
  visibility: collapse !important;
}

.policy {
  width: 100%;
  grid-column: 1 / span 3;
  grid-row-start: 4;
  font-size: 12px;
  text-align: center;
  justify-self: center;
  a {
    color: $green-color;
    padding-left: 3px;
    padding-right: 3px;
  }
  @media (max-width: $break-small) {
    font-size: 10px;
  }
}
