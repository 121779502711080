@import '../../../../variables.scss';
@import '../section.module.scss';

.selectionContainer {
  background-color: lightgrey;
  width: 100%;
  padding-top: 100px;
  padding-bottom: 100px;
  @media (max-width: $break-small) {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
.firstLeft {
  @media (max-width: $break-small) {
    padding-top: 55px;
    p {
      width: 100%;
    }
  }
}

.buttonContainer {
  margin-top: 60px;
  margin-bottom: 100px;
  width: 100%;

  @media (max-width: $break-small) {
    margin-top: 5%;
    margin-bottom: 5%;
  }
}
