@import '../../../../variables.scss';

.topper {
  min-height: 40vh;
  display: grid;
  align-items: center;
  border-bottom: 4px solid white;

  @media(max-width: $break-small){
    min-height: 30vh;
  }
}

.textBlock {
  width: 70%;
  margin: 0 auto;
  display: grid;
  justify-items: center;
  h1,
  p {
    color: $primary-text-color;
    text-align: center;
  }
  h1 {
    font-family: $primary-heading;
    font-size: $header-font-size;
    font-weight: 250;
    letter-spacing: 0.2vw;
    margin: 0;
  }
  p {
    font-family: $secondary-text;
    font-weight: 400;
    width: 60%;
    font-size: $standard-font-size;
    text-shadow: $primary-shadow;

    @media(max-width: $break-small){
      width: 90%;
    }
  }

  @media(max-width: $break-small){
    width: 90%;
  }
}
