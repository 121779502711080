@import '../../variables.scss';

.mainWindow {
  padding-top: 55px;
  margin: 0;
  box-sizing: border-box;
}

.sectionWrapper {
  background-color: transparent;
}

.tempBanner {
  width: 100%;
  display: grid;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 55px;
  z-index: 98;
  display: grid;
  grid-template-columns: 1fr 20px;
  h3,
  img {
    font-family: $primary-text;
    font-size: $standard-font-size;
    color: $green-color;
    font-weight: bold;
    margin: 0;
  }
  img {
    justify-self: right;
    grid-row: 1;
    margin-right: 30px;
    height: 60%;
    &:hover {
      cursor: pointer;
    }

    @media (max-width: $break-small) {
      width: 30px;
      height: auto;
    }
  }
  h3 {
    grid-row: 1;
    grid-column: 1 / span end;
    justify-self: center;

    @media (max-width: $break-small) {
      font-size: 14px;
    }
  }

  @media (max-width: $break-small) {
    height: auto;
    padding: 3%;
  }
}
