@import '../../../../variables.scss';

.videosWrapper {
  h1 {
    color: $primary-color;
    font-family: $primary-heading;
    font-size: $header-font-size;
    font-weight: 250;
    letter-spacing: 0.2vw;
    margin: 0;
  }
}

.videosBanner {
  display: flex;
  justify-content: center;
  width: 80%;
  height: 2.5vw;
  margin: 50px auto;
  align-items: center;
  h1 {
    text-align: left;
    margin-left: 20px;
  }
  img {
    height: auto;
    max-width: 100%;
    width: 4vw;
    justify-self: right;
  }

  @media screen and (max-width: $break-small) {
    height: auto;
  }
}
