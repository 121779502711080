@import '../../variables.scss';

.elevatorAnimation {
  height: 50vh;
  width: 80%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 30% 15% 1fr;
  align-items: center;
  justify-items: center;
  position: relative;
  h1 {
    color: $primary-color;
    font-family: $primary-heading;
    font-size: $header-font-size;
    font-weight: 250;
    letter-spacing: 0.2vw;
    grid-column: 1 / 2;
  }
  p {
    font-family: $secondary-text;
    font-weight: 400;
    font-size: $standard-font-size;
    text-align: left;
  }
  .shaft {
    width: 2px;
    height: 70%;
    position: absolute;
    top: 13%;
    background-color: #979797;
    grid-column: 2 / 3;

    .elevator {
      position: sticky;
      position: -webkit-sticky;
      top: 40%;
      margin-left: -18px;
      margin-bottom: -10px;
      transition: ease 3s;
    }

    @media (max-width: $break-small) {
      position: static;
      margin-right: 15%;
      float: left;
    }
  }
  div:last-of-type {
    grid-column: 3 / 4;
  }

  @media (max-width: $break-small) {
    display: block;
  }
}
