@import '../../../../variables.scss';

.videosWrapper {
  margin-top: -5px;
  h1 {
    color: $primary-text-color;
    font-family: $primary-heading;
    font-size: $header-font-size;
    font-weight: 250;
    letter-spacing: 0.2vw;
    margin: 0;
  }
  .topText {
    text-align: center;
    margin: 5% 0 0 0;
  }
  .scrollerBar {
    margin: 0 !important;
  }
  p {
    text-align: center;
    padding: 0 5%;
    margin: 0;

    @media (max-width: $break-small) {
      br {
        display: none;
      }
    }
  }
}

.videosBanner {
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 15vh;
  margin: auto;
  padding: 50px 0;
  align-items: center;
  flex-direction: column;
  h1 {
    text-align: left;
    margin-left: 20px;
    margin-bottom: 20px;
  }
  p {
    font-family: $secondary-text;
    font-weight: 400;
    width: 60%;
    font-size: $standard-font-size;
    color: white;
  }
  img {
    height: auto;
    max-width: 100%;
    width: 4vw;
    justify-self: right;
  }
}
