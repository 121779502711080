@import '../../variables.scss';

.contactWrapper {
  height: 100vh;
  padding-top: 55px;
  overflow: scroll;
  h1 {
    color: $primary-text-color;
    font-family: $primary-heading;
    font-size: $header-font-size;
    font-weight: 250;
    letter-spacing: 0.05em;
    margin-left: 100px;
    margin-top: 125px;
  }
  @media (max-width: $break-small) {
    display: block;
    position: relative;
    height: auto;
    margin: 0;
  }
}

.internalText {
  width: 80%;
  margin: 100px auto;
  font-family: $primary-text;
  font-size: $standard-font-size;
  a {
    margin-left: 8px;
    color: $green-color;
    font-weight: bold;
  }
}
