@import '../../../variables.scss';

.labelText {
  font-family: $primary-text;
  text-align: left;
  width: 50%;
}

// Custom Radio button css

.control {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 15px;
  cursor: pointer;
  font-size: calc(16px + 0.1vw);
}
.control input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.control__indicator {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: calc(12px + 0.09vw);
  width: calc(12px + 0.09vw);
  background: $grey-color;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition: 500ms ease-out;
  transition: 500ms ease-out;
}
.control--radio .control__indicator {
  border-radius: 50%;
}

// Using checkboxes in order to have multiple checked but styling like radio button
.control--checkbox .control__indicator {
  border-radius: 50%;
}
.control:hover input ~ .control__indicator,
.control input:focus ~ .control__indicator {
  background: $grey-color;
}
.control input:checked ~ .control__indicator {
  background: $iechi-green;
}
.control:hover input:not([disabled]):checked ~ .control__indicator,
.control input:checked:focus ~ .control__indicator {
  background: $iechi-green;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition: 500ms ease-in;
  transition: 500ms ease-in;
}
.control input:disabled ~ .control__indicator {
  background: $grey-color;
  opacity: 0.6;
  pointer-events: none;
}
.control__indicator:after {
  content: '';
  position: absolute;
  display: none;
}
.control input:checked ~ .control__indicator:after {
  display: block;
}
// Below code is to put check within box.

// .control--checkbox .control__indicator:after {
//   left: 8px;
//   top: 4px;
//   width: 3px;
//   height: 8px;
//   border: solid #fff;
//   border-width: 0 2px 2px 0;
//   transform: rotate(45deg);
// }

.control--checkbox input:disabled ~ .control__indicator:after {
  border-color: $grey-color;
}
.control--radio .control__indicator:after {
  left: 7px;
  top: 7px;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background: #fff;
}
.control--radio input:disabled ~ .control__indicator:after {
  background: $grey-color;
}
