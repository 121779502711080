@import '../../../../variables.scss';

.pcWrapper {
  h1 {
    color: $primary-color;
    font-family: $primary-heading;
    font-size: $header-font-size;
    font-weight: 250;
    letter-spacing: 0.2vw;
    margin: 0;

    @media (max-width: $break-small) {
      margin: 10% 0;
    }
  }
}

.productsBanner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.5vw;
  width: 20%;
  margin: 80px auto;
  h1 {
    line-height: 2.5vw;
    margin-left: 20px;
  }
  img {
    height: auto;
    width: auto;
    max-width: 100%;
    width: 6vw;
  }

  @media (max-width: $break-small) {
    width: auto;
  }
}

.productBlock {
  width: 100%;
  height: 30vh;
  display: flex;

  & > * {
    width: 100%;
  }
  & > *:first-child:nth-last-child(2),
  & > *:first-child:nth-last-child(2) ~ a{
      width: 50%;
  }
  & > *:first-child:nth-last-child(3),
  & > *:first-child:nth-last-child(3) ~ a{
      width: 33.33%;
  }
  & > *:first-child:nth-last-child(4),
  & > *:first-child:nth-last-child(4) ~ a{
      width: 25%;
  }

  @media (max-width: $break-small) {
    display: block;
    height: auto;
  }
}

.elevatorAnimation {
  height: 50vh;
  width: 80%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 30% 15% 1fr;
  align-items: center;
  justify-items: center;
  position: relative;
  h1 {
    color: $primary-color;
    font-family: $primary-heading;
    font-size: $header-font-size;
    font-weight: 250;
    letter-spacing: 0.2vw;
  }
  p {
    font-family: $secondary-text;
    font-weight: 400;
    font-size: $standard-font-size;
    text-align: left;
  }
}

.shaft {
  width: 2px;
  height: 70%;
  position: absolute;
  top: 13%;
  background-color: #979797;

  .elevator {
    position: sticky;
    position: -webkit-sticky;
    top: 40%;
    margin-left: -18px;
    margin-bottom: -10px;
    transition: 0.4s;
  }
}
